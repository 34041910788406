import {
  CssBaseline,
  FormControl,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom/dist";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import { l } from "../../../../util/languageConvertor";
import MenuAppBar from "../../../../components/Appbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "./postDisbursement.css";
import { fetchSchedule } from "../../../../api/agent";
import generateTablePDF from "./downloadPdf";
import moment from "moment";
import MixPanelAudit from "../../../../util/MixPanelAudit";
import { UserContext } from "../../../../Contexts/UserContext";
const Schedule = () => {
  const { langState } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const [lang, setLang] = langState;
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const navigate = useNavigate();
  const { application_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [collectionScheduleType, setCollectionSchemeType] = useState();
  const [loanSchedule, setSchedule] = useState({});
  // const [isNewSchedule, setIsNewSchedule] = useState(false);

  const getSchedule = async () => {
    setLoading(true);
    try {
      let { data } = await fetchSchedule(application_id);
      setSchedule(data?.data);
      setCollectionSchemeType(data?.defaultSelection)
      // if (data?.new_schedule) {
      //   setIsNewSchedule(true);
      // }
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "success",
      });
    }

    setLoading(false);
  };

  const generatePDF = () => {
    generateTablePDF(
      loanSchedule[collectionScheduleType + "Sheet"],
      {
        name: journeyData?.full_name,
        nbfc: journeyData?.nbfcData?.name,
        loanAmount: journeyData?.loan_amount.toString(),
        interest: journeyData?.interest_rate.toString(),
        tenure: journeyData?.tenure.toString(),
        disbursalDate: moment(journeyData?.disbursed_date).format("DD/MM/YYYY"),
        type: collectionScheduleType,
      },
      collectionScheduleType
    );
  };

  const handleChangeType = (e) => {
    if (e.target.value !== null) {
      setCollectionSchemeType(e.target.value);
    }
  };

  const { getApplicationDetails } = useApplication();

  useEffect(() => {
    getSchedule();
    getApplicationDetails(application_id);
  }, []);

  // useEffect(() => {
  //   if (isNewSchedule) {
  //     setCollectionSchemeType("efi");
  //   } else {
  //     setCollectionSchemeType("emi");
  //   }
  // }, [isNewSchedule]);

  return (
    <>
      <Box className="kyc-wrapper w-100" sx={{ pb: 7, pt: 7 }}>
        <MenuAppBar
          // text={l("Bank Documents", lang)}
          shadow={true}
          home={false}
          action={() => navigate("/create-loan/loan-status/" + application_id)}
          draft={true}
        />
        <CssBaseline />
        <div className="main-wrapper">
          <p className="fs-2 fw-bolder d-flex justify-content-center">
            {!isLoading && collectionScheduleType?.toLocaleUpperCase()} SCHEDULE
          </p>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="d-flex justify-content-end w-100">
                {/* <FormControl className="">
                    <Stack direction="row" spacing={4} className="mb-2">
                      <ToggleButtonGroup
                        value={collectionScheduleType}
                        exclusive
                        onChange={handleChangeType}
                        aria-label="text alignment"
                        // disabled={collectionData?.length === 0}
                        className="toggle-btn-holder"
                        size="small"
                      >
                        <ToggleButton value="emi" className="toggle-btn">
                          {isLoading ? <Skeleton /> : "EMI"}
                        </ToggleButton>
                        <ToggleButton
                          value={isNewSchedule ? "efi" : "edi"}
                          className="toggle-btn"
                        >
                          {isLoading ? (
                            <Skeleton />
                          ) : isNewSchedule ? (
                            "EFI"
                          ) : (
                            "EDI"
                          )}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                  </FormControl> */}
                <div></div>

                <Button
                  variant="outlined"
                  size="small"
                  sx={
                    {
                      // height: "10px"
                    }
                  }
                  onClick={() => {
                    // handleDownload();
                    MixPanelAudit.intercept("schedule_export_click",{agent_name: user?.name,application_id})
                    generatePDF();
                  }}
                >
                  {" "}
                  <FileDownloadIcon /> Export
                </Button>
              </div>
            </div>
            <div
              className="loan-table-container"
              // ref={scrollRef}
              // onScroll={handleScroll}
            >
              {!isLoading &&
              loanSchedule?.[collectionScheduleType + "Sheet"]?.length === 0 ? (
                <>
                  <div
                    className="w-100 d-flex justify-content-center align-items-center"
                    style={{ height: "60vh" }}
                  >
                    <div>
                      {/* <img src={noDataIco} style={{ width: "300px", opacity: 0.3 }} /> */}
                      <p
                        className="w-100 text-center mb-1"
                        style={{ fontSize: 25, fontWeight: 500 }}
                      >
                        Schedule not available
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <table className="loan-table">
                  <thead>
                    <tr className="loan-tr">
                      <th>Installment No</th>
                      <th>Due Date</th>
                      <th>
                        {!isLoading &&
                          collectionScheduleType?.toLocaleUpperCase()}{" "}
                        Amount
                      </th>
                      <th>Principal Component</th>
                      <th>Interest Component</th>
                      <th>Principal OS</th>
                    </tr>
                  </thead>
                  {!isLoading ? (
                    <tbody>
                      {loanSchedule?.[collectionScheduleType + "Sheet"]?.map(
                        (val, i) => {
                          return (
                            <tr className="loan-tr loan-body-tr" key={i}>
                              <td data-label="Installment No">
                                {val?.[`SL_NO`]}
                              </td>
                              <td data-label="Due Date">{val?.[`Due_Date`]}</td>
                              <td data-label="Installment Amount">
                                {val?.[collectionScheduleType.toUpperCase()]}
                              </td>
                              <td data-label="Principal Component">
                                {val?.Principal}
                              </td>
                              <td data-label="Interest Component">
                                {val?.Interest}
                              </td>
                              <td data-label="Principal OS">
                                {val?.["O/s_Principal"]}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      {[...Array(10)].map((_) => {
                        return (
                          <tr className="loan-tr loan-body-tr">
                            <td data-label="Installment No">
                              <Skeleton />
                            </td>
                            <td data-label="Date">
                              <Skeleton />
                            </td>
                            <td data-label="Installment Amount">
                              <Skeleton />
                            </td>
                            <td data-label="Collected Amount">
                              <Skeleton />
                            </td>
                            <td data-label="Account Collected Amount">
                              <Skeleton />
                            </td>
                            <td data-label="Account Collected Amount">
                              <Skeleton />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              )}
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Schedule;
