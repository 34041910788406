export const langDictionary = {
  "Enter your mobile number": {
    english: "Enter your mobile number",
    hinglish: "Apna mobile number daale",
    hindi: "अपना मोबाइल नंबर डालें",
  },
  Submit: {
    english: "Submit",
    hinglish: "Submit",
    hindi: "आगे बढ़ें",
  },
  "An otp is sent your Mobile Number": {
    english: "An otp is sent your Mobile Number",
    hinglish: "OTP aapke mobile no. par bheja gaya hai",
    hindi: "ओटीपी आपके मोबाइल नंबर पर भेजा गया है",
  },
  "Invalid OTP": {
    english: "Invalid OTP",
    hinglish: "OTP galat hai",
    hindi: "ओटीपी गलत है",
  },
  "This Session will End in": {
    english: "This Session will End in",
    hinglish: "ye session band ho raha hai",
    hindi: "ये सेशन बंद हो रहा है",
  },
  Hello: {
    english: "Hello",
    hinglish: "Namaste",
    hindi: "नमस्ते",
  },
  "Create E-Rick Loan Application": {
    english: "Create E-Rick Loan Application",
    hinglish: "Loan application shuru karen",
    hindi: "लोन आवेदन शुरू करे",
  },
  Create: {
    english: "Create",
    hinglish: "Banaye",
    hindi: "बनाये",
  },
  Overview: {
    english: "Overview",
    hinglish: "Avlokan Kare",
    hindi: "अवलोकन करे",
  },
  Dealership: {
    english: "Dealership",
    hinglish: "Dealership",
    hindi: "डीलर",
  },
  "Select a dealer": {
    english: "Select a dealer",
    hinglish: "Apni Dealership Chune",
    hindi: "अपनी डीलरशिप चुने",
  },
  "Personal Pan": {
    english: "Personal Pan",
    hinglish: "Cutomer ka Pan Card",
    hindi: "कस्टमर का पैन कार्ड",
  },
  "Max image size": {
    english: "Max image size",
    hinglish: "Max image size",
    hindi: "मैक्स इमेज साइज",
  },
  Upload: {
    english: "Upload",
    hinglish: "Upload",
    hindi: "डालें",
  },
  Camera: {
    english: "Camera",
    hinglish: "Camera",
    hindi: "खींचे",
  },
  "Aadhaar front": {
    english: "Aadhaar front",
    hinglish: "Aadhaar front",
    hindi: "आधार का आगे वाला पेज",
  },
  "Aadhaar Back": {
    english: "Aadhaar Back",
    hinglish: "Aadhaar Back",
    hindi: "आधार का पीछे वाला पेज",
  },
  Proceed: {
    english: "Proceed",
    hinglish: "Aage badhe",
    hindi: "आगे बढ़ें",
  },
  Name: {
    english: "Name",
    hinglish: "Name",
    hindi: "नाम",
  },
  "PAN Number": {
    english: "PAN Number",
    hinglish: "PAN Number",
    hindi: "पैन नंबर",
  },
  "Date of Birth": {
    english: "Date of Birth",
    hinglish: "Date of Birth",
    hindi: "जन्मतिथि",
  },
  Gender: {
    english: "Gender",
    hinglish: "Gender",
    hindi: "लिंग",
  },
  Male: {
    english: "Male",
    hinglish: "Male",
    hindi: "मेल",
  },
  Female: {
    english: "Female",
    hinglish: "Female",
    hindi: "फीमेल",
  },
  "Aadhaar Address": {
    english: "Aadhaar Address",
    hinglish: "Aadhaar Address",
    hindi: "आधार में पता",
  },
  "Aadhaar Pincode": {
    english: "Aadhaar Pincode",
    hinglish: "Aadhaar pincode",
    hindi: "आधार पिनकोड",
  },
  "Is this aadhaar property owned by customer?": {
    english: "Is this aadhaar property owned by customer?",
    hinglish: "kya customer Aadhaar me di hui property ka maalik hai?",
    hindi: "क्या कस्टमर आधार में दी हुई प्रॉपर्टी के मालिक है?",
  },
  "Is current address property owned by customer?": {
    english: "Is current address property owned by customer?",
    hinglish: "kya customer current address ki property ka maailk hai?",
    hindi: "क्या कस्टमर मौजूदा पता में दी हुई प्रॉपर्टी के मालिक है?",
  },
  Yes: {
    english: "Yes",
    hinglish: "Yes",
    hindi: "हाँ",
  },
  No: {
    english: "No",
    hinglish: "No",
    hindi: "ना",
  },
  "Same As Aadhaar Address": {
    english: "Same As Aadhaar Address",
    hinglish: "Aadhaar address ke Jaisa",
    hindi: "आधार पता के जैसा",
  },
  "Current Residential address": {
    english: "Current Residential address",
    hinglish: "Current Residential address",
    hindi: "वर्तमान आवासीय पता",
  },
  "Current Residential pincode": {
    english: "Current Residential pincode",
    hinglish: "Current Residential pincode",
    hindi: "वर्तमान आवासीय पिनकोड",
  },
  "Mobile Number": {
    english: "Mobile Number",
    hinglish: "Mobile Number",
    hindi: "मोबाइल नंबर",
  },
  "Email Id": {
    english: "Email Id",
    hinglish: "Email id",
    hindi: "ईमेल आईडी",
  },
  "I hereby authorize embifi to pull my credit report": {
    english:
      "I hereby authorize Embifi to pull my credit report in accordance with the Terms of Use and the Privacy Policy of the Company",
    hinglish:
      "I hereby authorize Embifi to pull my credit report in accordance with the Terms of Use and the Privacy Policy of the Company",
    hindi:
      "I hereby authorize Embifi to pull my credit report in accordance with the Terms of Use and the Privacy Policy of the Company ",
  },
  "Check Eligibility": {
    english: "Check Eligibility",
    hinglish: "Aage badhe",
    hindi: "आगे बढ़ें",
  },
  Congratulations: {
    english: "Congratulations",
    hinglish: "Badhai Ho",
    hindi: "बधाई हो",
  },
  "We can proceed with the loan application": {
    english: "We can proceed with the loan application",
    hinglish: "Hum aapki loan application ke saath aange badh rahe hai",
    hindi: "हम आपकी लोन आवेदन के साथ आगे बढ़ रहें है",
  },
  "Add Co-Borrower / Guarantor": {
    english: "Add Co-Customer / Guarantor",
    hinglish: "Co-Customer / Guarantor jode",
    hindi: "सह-उधारकर्ता  / गारंटर जोड़े",
  },
  "Skip & Proceed": {
    english: "Skip & Proceed",
    hinglish: "Chode aur Aage Badhe",
    hindi: "छोड़े और आगे बढे",
  },
  Verify: {
    english: "Verify",
    hinglish: "Verify",
    hindi: "वेरीफाई",
  },
  "Relation with customer": {
    english: "Relation with customer",
    hinglish: "Customer ke saath relation",
    hindi: "कस्टमर के साथ संबंध",
  },
  Husband: {
    english: "Husband",
    hinglish: "Husband",
    hindi: "पति",
  },
  Wife: {
    english: "Wife",
    hinglish: "Wife",
    hindi: "पत्नी",
  },
  Daughter: {
    english: "Daughter",
    hinglish: "Daughter",
    hindi: "बेटी",
  },
  Son: {
    english: "Son",
    hinglish: "Son",
    hindi: "बेटा",
  },
  Brother: {
    english: "Brother",
    hinglish: "Brother",
    hindi: "भाई",
  },
  Sister: {
    english: "Sister",
    hinglish: "Sister",
    hindi: "बहन",
  },
  Father: {
    english: "Father",
    hinglish: "Father",
    hindi: "पिता",
  },
  Mother: {
    english: "Mother",
    hinglish: "Mother",
    hindi: "मां",
  },
  Relative: {
    english: "Relative",
    hinglish: "Relative",
    hindi: "रिश्तेदार",
  },
  Friend: {
    english: "Friend",
    hinglish: "Friend",
    hindi: "दोस्त",
  },
  Neighbour: {
    english: "Neighbour",
    hinglish: "Neighbour",
    hindi: "पड़ोसी",
  },
  Other: {
    english: "Other",
    hinglish: "Aur koi",
    hindi: "अन्य",
  },
  Guarantor: {
    english: "Guarantor",
    hinglish: "Guarantor",
    hindi: "गारंटर",
  },
  "Co-Borrower": {
    english: "Co-Customer",
    hinglish: "Co-Customer",
    hindi: "सह-कस्टमर",
  },
  "Co-Applicant": {
    english: "Co-Applicant",
    hinglish: "Co-Customer",
    hindi: "सह-कस्टमर",
  },
  "Vehicle Model": {
    english: "Vehicle Model",
    hinglish: "Vehicle Model",
    hindi: "वाहन मॉडल",
  },
  "Battery Brand": {
    english: "Battery Brand",
    hinglish: "Battery Brand",
    hindi: "बैटरी ब्रांड",
  },
  "Battery AH": {
    english: "Battery AH",
    hinglish: "Battery AH",
    hindi: "बैटरी  एच",
  },
  "Vehicle Price On Road": {
    english: "Vehicle Price On Road",
    hinglish: "Wahan Ki On-Road keemat",
    hindi: "वाहन की ऑन रोड कीमत",
  },
  "Vehicle Price Ex(showroom)": {
    english: "Vehicle Price Ex(showroom)",
    hinglish: "Vehicle ki Ex(showroom) kimat",
    hindi: "वाहन की एक्स शोरूम कीमत",
  },
  "Loan Amount": {
    english: "Loan Amount",
    hinglish: "Loan Amount",
    hindi: "लोन अमाउंट",
  },
  "Down Payment": {
    english: "Down Payment",
    hinglish: "Down Payment",
    hindi: "डाउन पेमेंट",
  },
  "Interest Rate": {
    english: "Interest Rate",
    hinglish: "Interest Rate",
    hindi: "व्याज दर",
  },
  "Loan Tenure (In Months)": {
    english: "Loan Tenure (In Months)",
    hinglish: "Loan Karyakal (Mahine Me)",
    hindi: "लोन कार्यकाल (महीने में)",
  },
  "EMI Amount": {
    english: "EMI Amount",
    hinglish: "Monthly Kisht (EMI)",
    hindi: "मंथली किश्त (इ.एम्.आई )",
  },
  "EDI Amount": {
    english: "EDI Amount",
    hinglish: "Daily Kisht (EDI)",
    hindi: "डेली  किश्त (इ.डी .आई )",
  },
  "Interest Amount": {
    english: "Interest Amount",
    hinglish: "Interest Amount",
    hindi: "ब्याज राशि",
  },
  "File Charge Rate": {
    english: "Processing Rate",
    hinglish: "Processing Rate",
    hindi: "प्रोसेसिंग चार्ज दर",
  },
  "File Charge Amount": {
    english: "Processing Amount",
    hinglish: "Processing Amount",
    hindi: "प्रोसेसिंग शुल्क राशि",
  },
  "Bank Documents": {
    english: "Bank Documents",
    hinglish: "Bank Documents",
    hindi: "बैंक डाक्यूमेंट्स",
  },
  "Debit Card": {
    english: "Debit Card",
    hinglish: "Debit Card",
    hindi: "डेबिट कार्ड",
  },
  yes: {
    english: "yes",
    hinglish: "Yes",
    hindi: "हाँ",
  },
  Applied: {
    english: "Applied",
    hinglish: "Apply kiya hai",
    hindi: "अप्लाई किया है",
  },
  "Upload Cheques": {
    english: "Upload Cheques",
    hinglish: "Upload Cheques",
    hindi: "चेक डालें",
  },
  Now: {
    english: "Now",
    hinglish: "Abhi",
    hindi: "अभी",
  },
  Later: {
    english: "Later",
    hinglish: "Baad Me",
    hindi: "बाद में",
  },
  "Upload Cheque1": {
    english: "Upload Cheque1",
    hinglish: "Upload Cheque1",
    hindi: "पहला चेक डालें",
  },
  "Upload Cheque2": {
    english: "Upload Cheque2",
    hinglish: "Upload Cheque2",
    hindi: "दूसरा चेक डालें",
  },
  "Upload Cheque3": {
    english: "Upload Cheque3",
    hinglish: "Upload Cheque3",
    hindi: "तीसरा चेक डालें",
  },
  "Name in bank account": {
    english: "Name in bank account",
    hinglish: "Bank account me Naam",
    hindi: "बैंक खाता में नाम",
  },
  "Select Bank": {
    english: "Select Bank",
    hinglish: "Select Bank",
    hindi: "बैंक चुने",
  },
  "Account Number": {
    english: "Account Number",
    hinglish: "Account Number",
    hindi: "खाता नंबर",
  },
  "Confirm Account Number": {
    english: "Confirm Account Number",
    hinglish: "Confirm Account Number",
    hindi: "खाता नंबर कन्फर्म करे",
  },
  "Account Type": {
    english: "Account Type",
    hinglish: "Account Type",
    hindi: "खाता का टाइप चुने",
  },
  Savings: {
    english: "Savings",
    hinglish: "Savings",
    hindi: "सेविंग",
  },
  Current: {
    english: "Current",
    hinglish: "Current",
    hindi: "करेंट",
  },
  "IFSC Code": {
    english: "IFSC Code",
    hinglish: "IFSC Code",
    hindi: "आई.एफ.एस.सी कोड",
  },
  "Under review": {
    english: "Under review",
    hinglish: "Review ho raha hai",
    hindi: "रिव्यु हो रहा है",
  },
  "Loan application is being reviewed by the team.We will get back shortly": {
    english:
      "Loan application is being reviewed by the team.We will get back shortly",
    hinglish:
      "Aapki loan application review ho rahi hai. Hum jaldi aapko inform karenge",
    hindi: "आपकी लोन फाइल रिव्यु हो रही है हम जल्दी आपको इन्फॉर्म करेंगे",
  },
  Refresh: {
    english: "Refresh",
    hinglish: "Refresh",
    hindi: "रिफ्रेश",
  },
  "Vehicle Price On road": {
    english: "Vehicle Price On road",
    hinglish: "Vehicle ki On-Road kimat",
    hindi: "वाहन की ऑन रोड कीमत",
  },
  "Loan amount": {
    english: "Loan amount",
    hinglish: "Loan Amount",
    hindi: "लोन अमाउंट",
  },
  "File Fees": {
    english: "File Fees",
    hinglish: "File Fees",
    hindi: "फ़ाइल शुल्क",
  },
  Lender: {
    english: "Lender",
    hinglish: "Lender",
    hindi: "लेंडर",
  },
  "Payment Method": {
    english: "Payment Method",
    hinglish: "Payment Method",
    hindi: "भुगतान का जरिया",
  },
  "Field Investigation": {
    english: "Field Investigation",
    hinglish: "Field Investigation",
    hindi: "फील्ड जांच",
  },
  "Customer Documents": {
    english: "Customer Documents",
    hinglish: "Customer Documents",
    hindi: "कस्टमर डाक्यूमेंट्स",
  },
  "Co-Borrower documents": {
    english: "Co-Customer documents",
    hinglish: "Co-Customer documents",
    hindi: "सह-कस्टमर डाक्यूमेंट्स",
  },
  "Loan Documents": {
    english: "Loan Documents",
    hinglish: "Loan Documents",
    hindi: "लोन डाक्यूमेंट्स",
  },
  "Vehicle Documents": {
    english: "Vehicle Documents",
    hinglish: "Vehicle Documents",
    hindi: "वाहन डाक्यूमेंट्स",
  },
  "File Status": {
    english: "File Status",
    hinglish: "File Status",
    hindi: "फ़ाइल स्थिति",
  },
  "UPI Autopay": {
    english: "UPI Autopay",
    hinglish: "UPI Autopay",
    hindi: "UPI ऑटोपेमेंट",
  },
  "Registration Using VPA": {
    english: "Registration Using VPA",
    hinglish: "Registration Using VPA",
    hindi: "VPA पंजीकरण",
  },
  "Link Sent": {
    english: "Link Sent",
    hinglish: "Link Sent",
    hindi: "लिंक भेजी",
  },
  "E-Nach": {
    english: "E-Nach",
    hinglish: "E-Nach",
    hindi: "इ-नेच",
  },
  "Debit card/ Net Banking": {
    english: "Debit card/ Net Banking",
    hinglish: "Debit card/ Net Banking",
    hindi: "डेबिट कार्ड / नेट बैंकिंग",
  },
  Register: {
    english: "Register",
    hinglish: "Register",
    hindi: "पंजीकरण",
  },
  "Physical Nach": {
    english: "Physical Nach",
    hinglish: "Physical Nach",
    hindi: "फिजिकल नेच",
  },
  Download: {
    english: "Download",
    hinglish: "Download",
    hindi: "डाउनलोड",
  },
  "Payment via QR": {
    english: "Payment via QR",
    hinglish: "Payment via QR",
    hindi: "क्यू.र. द्वरा पेमेंट",
  },
  "View QR": {
    english: "View QR",
    hinglish: "QR dekhen",
    hindi: "क्यू.र. देखें",
  },
  "Customer Name": {
    english: "Customer Name",
    hinglish: "कस्टमर का नाम",
    hindi: "कस्टमर का नाम",
  },
  Address: {
    english: "Address",
    hinglish: "Address",
    hindi: "पता",
  },
  "Premises Ownership": {
    english: "Premises Ownership",
    hinglish: "Ghar ki ownership",
    hindi: "घर की मालिकी",
  },
  "Total Number Of family members": {
    english: "Total Number Of family members",
    hinglish: "Parivaar ke members ki kul sankhya",
    hindi: "परिवार के सदस्यों की कुल संख्या",
  },
  "Number of earning members in family": {
    english: "Number of earning members in family",
    hinglish: "Kamaane waale members ki sankhya",
    hindi: "परिवार में कमाने वाले सदस्यों की संख्या",
  },
  "Annual family income (In Rs)": {
    english: "Annual family income (In Rs)",
    hinglish: "Parivaar ki salana kamai",
    hindi: "वार्षिक पारिवारिक आय (रुपये में)",
  },
  Dealer: {
    english: "Dealer",
    hinglish: "Dealer",
    hindi: "डीलर",
  },
  "Dealer Name": {
    english: "Dealer Name",
    hinglish: "Dealer Name",
    hindi: "डीलर का नाम",
  },
  "Vehicle Details": {
    english: "Vehicle Details",
    hinglish: "Vehicle Details",
    hindi: "वाहन की सूचना",
  },
  "Vehicle Engine No.": {
    english: "Vehicle Engine No.",
    hinglish: "Vehicle Engine No.",
    hindi: "वाहन का इंजन नं.",
  },
  "Chassis No.": {
    english: "Chassis No.",
    hinglish: "Chassis No.",
    hindi: "चैसिस नंबर",
  },
  Photos: {
    english: "Photos",
    hinglish: "Photos",
    hindi: "फोटो",
  },
  "Customer House Inside": {
    english: "House Inside Photo",
    hinglish: "Ghar ke andar ki photo",
    hindi: "घर के अंदर की फोटो",
  },
  "Customer House Outside": {
    english: "House Outside Photo",
    hinglish: "Ghar ke bahar ki photo",
    hindi: "घर के बाहर की फोटो",
  },
  "Customer photo with electricity meter": {
    english: "Customer photo with electricity meter",
    hinglish: "Customer photo electricity meter ke saath",
    hindi: "बिजली मीटर के साथ कस्टमर की फोटो",
  },
  "Add More": {
    english: "Add More",
    hinglish: "Add More",
    hindi: "अधिक जोड़ें",
  },
  "Reference 1": {
    english: "Reference 1",
    hinglish: "Reference 1",
    hindi: "रिफरेन्स 1",
  },
  "Reference 2": {
    english: "Reference 2",
    hinglish: "Reference 2",
    hindi: "रिफरेन्स 2",
  },
  "Relation with borrower": {
    english: "Relation with borrower",
    hinglish: "Borrower ke sath rishta",
    hindi: "कर्जदार के साथ संबंध",
  },
  "Accessbility till premise": {
    english: "Accessbility till premise",
    hinglish: "Ghar tak pahuchna",
    hindi: "घर तक पहुंचना",
  },
  "Easy To reach": {
    english: "Easy To reach",
    hinglish: "Asaan Hai",
    hindi: "आसान है",
  },
  Diificult: {
    english: "Diificult",
    hinglish: "Muskil Hai",
    hindi: "मुश्किल है",
  },
  "Final Remark on the file": {
    english: "Final Remark on the file",
    hinglish: "File par final remark",
    hindi: "फ़ाइल पर अंतिम टिप्पणी",
  },
  Pass: {
    english: "Pass",
    hinglish: "Pass",
    hindi: "पास",
  },
  Fail: {
    english: "Fail",
    hinglish: "Fail",
    hindi: "फ़ैल",
  },
  "Agent Name": {
    english: "Agent Name",
    hinglish: "Agent Name",
    hindi: "एजेंट का नाम",
  },
  "Agent phone Number": {
    english: "Agent phone Number",
    hinglish: "Agent phone Number",
    hindi: "एजेंट का फ़ोन नंबर",
  },
  "I hereby declare that the details furnished above are true and correct to the best of my knowledge":
    {
      english:
        "I hereby declare that the details furnished above are true and correct to the best of my knowledge",
      hinglish:
        "Me declare karta hoon ki upar di hui saari details meri jaankari ke hisaab se sahi hai",
      hindi:
        "मैं घोषणा करता हूं कि ऊपर दिया गया विवरण मेरी जानकारी के अनुसार सत्य और सही है",
    },
  "Customer photo": {
    english: "Customer photo",
    hinglish: "Customer photo",
    hindi: "कस्टमर फोटो",
  },
  "Driving License": {
    english: "Driving License",
    hinglish: "Driving License",
    hindi: "ड्राइविंग लाइसेंस",
  },
  "House ownership proof(Electricity bill)": {
    english: "House ownership proof(Electricity bill)",
    hinglish: "House ownership proof(Electricity bill)",
    hindi: "घर के मालिक होने का प्रमाण (बिजली का बिल)",
  },
  "Co-Borrower Photo": {
    english: "Co-Customer Photo",
    hinglish: "Co-Customer Photo",
    hindi: "सह-कस्टमर फोटो",
  },
  "Loan Agreement": {
    english: "Loan Agreement",
    hinglish: "Loan Agreement",
    hindi: "लोन एग्रीमेंट",
  },
  "Stamp Paper": {
    english: "Stamp Paper",
    hinglish: "Stamp Paper",
    hindi: "स्टाम्प पेपर",
  },
  "Dual Form": {
    english: "Dual Form",
    hinglish: "Dual Form",
    hindi: "डुअल फॉर्म",
  },
  "Form 26-35": {
    english: "Form 26-35",
    hinglish: "Form 26-35",
    hindi: "फॉर्म 26-35",
  },
  "Sales Invoice": {
    english: "Sales Invoice",
    hinglish: "Sales Invoice",
    hindi: "सेल्स इनवॉइस",
  },
  Quaotation: {
    english: "Quaotation",
    hinglish: "Quaotation",
    hindi: "उद्धरण",
  },
  "Form 22": {
    english: "Form 22",
    hinglish: "Form 22",
    hindi: "फॉर्म 22",
  },
  "Vehicle Delivery photo": {
    english: "Vehicle Delivery photo",
    hinglish: "Vehicle Delivery photo",
    hindi: "वाहन वितरण फोटो",
  },
  "Chassis Number Photo": {
    english: "Chassis Number Photo",
    hinglish: "Chassis Number Photo",
    hindi: "चेसिस नंबर फोटो",
  },
  "Battery Warranty Card": {
    english: "Battery Warranty Card",
    hinglish: "Battery Warranty Card",
    hindi: "बैटरी वारंटी कार्ड",
  },
  Insurance: {
    english: "Insurance",
    hinglish: "Insurance",
    hindi: "बीमा",
  },
  "Form 20": {
    english: "Form 20",
    hinglish: "Form 20",
    hindi: "फॉर्म 20",
  },
  "Form 21": {
    english: "Form 21",
    hinglish: "Form 21",
    hindi: "फॉर्म 21",
  },
  TRC: {
    english: "TRC",
    hinglish: "TRC",
    hindi: "टी.आर.सी.",
  },
  Cheques: {
    english: "Cheques",
    hinglish: "Cheques",
    hindi: "चेक",
  },
  Cheque: {
    english: "Cheque",
    hinglish: "Cheque",
    hindi: "चेक",
  },
  BSV: {
    english: "BSV",
    hinglish: "BSV",
    hindi: "बी.एस.वी.",
  },
  "Bank Passbook": {
    english: "Bank Passbook",
    hinglish: "Bank Passbook",
    hindi: "बैंक पासबुक",
  },
  Pending: {
    english: "Pending",
    hinglish: "Pending",
    hindi: "रूका हुआ",
  },
  Approved: {
    english: "Approved",
    hinglish: "Approved",
    hindi: "अनुमत",
  },
  Rejected: {
    english: "Rejected",
    hinglish: "Rejected",
    hindi: "रद्द हुआ",
  },
  "Loan amount will be transferred within 24 Hrs": {
    english: "Loan amount will be transferred within 24 Hrs",
    hinglish: "Laon amount 24 hrs me transfer kar diya jayega",
    hindi: "लोन राशि 24 घंटे के भीतर ट्रान्सफर कर दी जाएगी",
  },

  "Sorry we could not proceed with your application": {
    english: "Sorry we could not proceed with your application",
    hinglish: "Sorry hum aapki application ko aange nahi bada sakte hai",
    hindi: "क्षमा करें हम आपके आवेदन पर आगे नहीं बढ़ सकते है",
  },
  "Application is being reviewed by the team. We’ll get back shortly": {
    english:
      "Application is being reviewed by the team. We’ll get back shortly",
    hinglish:
      "Team aapki application ko review kar rahi hai. Hum jald hi aapko inform karenge",
    hindi:
      "टीम द्वारा आवेदन की समीक्षा की जा रही है। हम जल्द ही आपको इन्फॉर्म करेंगे",
  },
  "Some fields are missing": {
    english: "Some fields are missing",
    hinglish: "Kuch fields khaali hai",
    hindi: "कुछ फ़ील्ड गायब हैं",
  },
  "Customer's crif_score_status is Declined, Rejected": {
    english: "Customer's crif_score_status Declined",
    hinglish: "Customer ka credit score kam hai",
    hindi: "कस्टमर का क्रेडिट स्कोर कम है",
  },
  "Customer's crif_status is Declined, Rejected": {
    english: "Customer's crif_status Declined",
    hinglish: "Customer ki credit history karab hai",
    hindi: "कस्टमर की क्रेडिट हिस्ट्री ख़राब है",
  },
  "Customer's distance_status is Declined, Rejected": {
    english: "Customer's distance_status Declined",
    hinglish: "Customer ka ghar humare working area se dur hai",
    hindi: "कस्टमर का घर हमारे कार्य क्षेत्र से दूर है",
  },
  "Customer's age_status is Declined, Rejected": {
    english: "Customer's age_status Declined",
    hinglish: "Customer ki age loan lene ke liye kam ya jayda hai",
    hindi: "कस्टमर की उम्र लोन लेने के लिया कम या ज्यादा है",
  },
  "Customer's mindistance_ownership_status is Declined, Rejected": {
    english: "Customer's mindistance_ownership_status Declined",
    hinglish: "Customer jis ghar me rahta hai uska owner nahi hai",
    hindi: "कस्टमर जिस घर में रहता है वो उसका मालिक नहीं है",
  },
  "Customer's NTC_status is Declined, Rejected": {
    english: "Customer's NTC_status Declined",
    hinglish: "Customer ki koi credit history nahi hai",
    hindi: "कस्टमर की कोई क्रेडिट हिस्ट्री नहीं है",
  },
  "Customer's Gender_status is Declined, Rejected": {
    english: "Customer's Gender_status Declined",
    hinglish: "Customer ka gender status fail ho gaya hai",
    hindi: "कस्टमर का लिंग स्टेटस फेल हो गया है",
  },
  "Co-Customer's crif_score_status is Declined, Rejected": {
    english: "Co-Customer's crif_score_status Declined",
    hinglish: "Co-Customer ka credit score kam hai",
    hindi: "सह-कस्टमर का क्रेडिट स्कोर कम है",
  },
  "Co-Customer's crif_status is Declined, Rejected": {
    english: "Co-Customer's crif_status Declined",
    hinglish: "Co-Customer ki credit history karab hai",
    hindi: "सह-कस्टमर की क्रेडिट हिस्ट्री ख़राब है",
  },
  "Co-Customer's distance_status is Declined, Rejected": {
    english: "Co-Customer's distance_status Declined",
    hinglish: "Co-Customer ka ghar humare working area se dur hai",
    hindi: "सह-कस्टमर का घर हमारे कार्य क्षेत्र से दूर है",
  },
  "Co-Customer's age_status is Declined, Rejected": {
    english: "Co-Customer's age_status Declined",
    hinglish: "Co-Customer ki age loan lene ke liye kam ya jayda hai",
    hindi: "सह-कस्टमर की उम्र लोन लेने के लिया कम या ज्यादा है",
  },
  "Co-Customer's property_ownership_status is Declined, Rejected": {
    english: "Co-Customer's property_ownership_status Declined",
    hinglish: "Co-Customer jis ghar me rahta hai uska owner nahi hai",
    hindi: "सह-कस्टमर जिस घर में रहता है वो उसका मालिक नहीं है",
  },
  "Draft Applications": {
    english: "Draft Applications",
    hinglish: "Draft applications",
    hindi: "ड्राफ्ट आवेदन",
  },
  "All Applications": {
    english: "All Applications",
    hinglish: "All Applications",
    hindi: "सभी आवेदन",
  },
  "Application Rejected": {
    english: "Application Rejected",
    hinglish: "Application Rejected",
    hindi: "आवेदन अस्वीकृत",
  },
  "Try Another Co-Customer/ Guarantor": {
    english: "Try Another Co-Customer/ Guarantor",
    hinglish: "Koi aur Co-Customer/ Guarantor ke Saath Koshish Karen",
    hindi: "अन्य सह-कस्टमर / गारंटर का प्रयास करें",
  },
  "Website Link": {
    english: "Website Link",
    hinglish: "Website Link",
    hindi: "वेबसाइट की लिंक",
  },
  "Product offered": {
    english: "Product offered",
    hinglish: "Product Offered",
    hindi: "प्रोडक्ट की जानकारी",
  },
  "Try Again": {
    english: "Try Again",
    hinglish: "Try Again",
    hindi: "पुनः प्रयास करें",
  },
  Uploaded: {
    english: "Uploaded",
    hinglish: "Uploaded",
    hindi: "अपलोड किया गया",
  },
  "Download Again": {
    english: "Download Again",
    hinglish: "Download Again",
    hindi: "दोबारा डाउनलोड करें",
  },
  Borrower: {
    english: "Customer",
    hinglish: "Customer",
    hindi: "कस्टमर",
  },
  Customer: {
    english: "Customer",
    hinglish: "Customer",
    hindi: "कस्टमर",
  },
  "Select an option": {
    english: "Select an option",
    hinglish: "Option Chune",
    hindi: "कोई विकल्प चुनें",
  },
  Difficult: {
    english: "Difficult",
    hinglish: "Difficult",
    hindi: "कठिन",
  },
  "Check Status": {
    english: "Check Status",
    hinglish: "Check Status",
    hindi: "चेक करें",
  },
  Close: {
    english: "Close",
    hinglish: "Close",
    hindi: "बंद करें",
  },
  "Waiting for NBFC Approval": {
    english: "Waiting for NBFC Approval",
    hinglish: "NBFC ki approval ki wait kar rahe hai",
    hindi: "एनबीएफसी की मंजूरी का इंतजार",
  },

  // 17/04/2023

  Home: {
    english: "Home",
    hinglish: "Home",
    hindi: "होम",
  },
  Loan: {
    english: "Loan",
    hinglish: "Loan",
    hindi: "लोन",
  },
  "Change language": {
    english: "Change language",
    hinglish: "Language Badle",
    hindi: "भाषा बदलें",
  },
  "Application ID": {
    english: "Application ID",
    hinglish: "Application ID",
    hindi: "एप्लीकेशन आई.डी.",
  },
  Stage: {
    english: "Stage",
    hinglish: "Stage",
    hindi: "अवस्था",
  },
  "Provide a valid date": {
    english: "Provide a valid date",
    hinglish: "Valid date daalen",
    hindi: "मान्य तिथि डालें",
  },
  "Select gender": {
    english: "Select gender",
    hinglish: "Gender Bataye",
    hindi: "लिंग चुनें",
  },
  "Cannot be blank": {
    english: "Cannot be blank",
    hinglish: "Kaali na chhode",
    hindi: "खाली नहीं छोड़े",
  },
  "Select any option": {
    english: "Select any option",
    hinglish: "Koi option chune",
    hindi: "कोई विकल्प चुने",
  },
  "Pincode validation failed": {
    english: "Pincode validation failed",
    hinglish: "Pincode verification failed",
    hindi: "पिनकोड वेरिफिकेशन फ़ैल हुआ",
  },
  "Disbursement Completed": {
    english: "Disbursement Completed",
    hinglish: "Disbursement Completed",
    hindi: "डिस्बर्समेंट पूरा हुआ",
  },
  "Customer is not eligible to take loan. Because CO-Customer is not property owner.":
    {
      english:
        "Customer is not eligible to take loan. Because CO-Customer is not property owner.",
      hinglish:
        "Customer loan lene ke liye eligible nahi hai kyuki uski Co-Customer apni property ko own nahi karte hai",
      hindi:
        "कस्टमर को लोन नहीं मिल पायेगा, क्योंकि सह-कस्टमर संपत्ति का मालिक नहीं है।",
    },
  "Enter a valid IFSC code": {
    english: "Enter a valid IFSC code",
    hinglish: "Valid IFSC daalen",
    hindi: "मान्य IFSC कोड दर्ज करें",
  },
  "Please upload all cheques": {
    english: "Please upload all cheques",
    hinglish: "Please sabhi checks daalen",
    hindi: "कृपया सभी चेक अपलोड करें",
  },
  "Ex-showroom price cannot be higher than onroad price": {
    english: "Ex-showroom price cannot be higher than onroad price",
    hinglish: "Ex-showroom price onroad price se jyada nahi ho sakti hai",
    hindi: "एक्स-शोरूम कीमत ऑनरोड कीमत से अधिक नहीं हो सकती है",
  },
  "Name (As per PAN)": {
    english: "Name (As per PAN)",
    hinglish: "Name (PAN card me jo hai)",
    hindi: "नाम (पैन कार्ड के अनुसार)",
  },
  "Please accept the credit pull consent": {
    english: "Please accept the credit pull consent",
    hinglish: "Please credit pull ke liye consent de",
    hindi: "कृपया क्रेडिट पुल सहमति स्वीकार करें",
  },
  erick_delivery_photo: {
    english: "erick_delivery_photo",
    hinglish: "erick_delivery_photo",
    hindi: "erick_डिलीवरी_फोटो",
  },
  Success: {
    english: "Success",
    hinglish: "Success",
    hindi: "सफल",
  },
  "Are you sure to remove this co-customer? This operation cannot be undone?": {
    english:
      "Are you sure to remove this co-customer? This operation cannot be undone?",
    hinglish:
      "Kya aap sure hai co-customer ko hatane ke liye? Ye action reverse nahi hoga",
    hindi:
      "क्या आप वाकई इस सह-कस्टमर को हटाना चाहते हैं? यह एक्शन रिवर्स नहीं होगा",
  },
  Cancel: {
    english: "Cancel",
    hinglish: "Cancel",
    hindi: "रद्द",
  },
  Confirm: {
    english: "Confirm",
    hinglish: "Confirm",
    hindi: "कन्फर्म",
  },
  "Cancel Co-customer": {
    english: "Cancel Co-customer",
    hinglish: "Cancel Co-customer",
    hindi: "सह-कस्टमर रद्द करें",
  },
  "Number not registered with us": {
    english: "Number not registered with us",
    hinglish: "Number humare saath register nahi hai",
    hindi: "नंबर हमारे पास रजिस्टर नहीं है",
  },
  "Internal server error": {
    english: "Internal server error",
    hinglish: "Internal server error",
    hindi: "Internal server error",
  },
  "Loan application not found": {
    english: "Loan application not found",
    hinglish: "Loan application nahi mili",
    hindi: "लोन एप्लीकेशन नहीं मिली",
  },
  "Customer not found": {
    english: "Customer not found",
    hinglish: "Customer nahi mila",
    hindi: "कस्टमर नहीं मिला",
  },

  "Co customer not found": {
    english: "Co customer not found",
    hinglish: "Co-customer nahi mila",
    hindi: "सह-कस्टमर नहीं मिला",
  },
  "Not authorized": {
    english: "Not authorized",
    hinglish: "Not authorised",
    hindi: "authorisation नहीं है",
  },
  "Dealer not found": {
    english: "Dealer not found",
    hinglish: "Dealer not found",
    hindi: "डीलर नहीं मिला",
  },
  "Verification not found or expired": {
    english: "Verification not found or expired",
    hinglish: "Verification nahi hua ya session expire ho gaya",
    hindi: "वेरिफिकेशन नहीं हुआ या सेशन समाप्त हो गया",
  },
  "Invalid OTP": {
    english: "Invalid OTP",
    hinglish: "Invalid OTP",
    hindi: "अमान्य OTP",
  },
  "Otp failed to sent": {
    english: "Otp failed to sent",
    hinglish: "OTP bheja na saka",
    hindi: "OTP भेजा नहीं जा सका",
  },
  "Otp sent to the agent": {
    english: "Otp sent to the agent",
    hinglish: "Otp agent to bheja gaya",
    hindi: "एजेंट को OTP भेजा गया",
  },
  "Internal Server Error": {
    english: "Internal Server Error",
    hinglish: "Internal Server Error",
    hindi: "Internal Server Error",
  },
  "OTP already verified": {
    english: "OTP already verified",
    hinglish: "OTP pahle hi verified hai",
    hindi: "OTP पहले ही वेरिफ़िएड है",
  },
  "Logged in successfully": {
    english: "Log in successful",
    hinglish: "Log in successful",
    hindi: "लॉग इन सफल हुआ",
  },
  "Otp verified successfully": {
    english: "Otp verified successfully",
    hinglish: "Otp verified successfully",
    hindi: "OTP वेरिफिकेशन सफल हुआ",
  },
  "Mobile number not found": {
    english: "Mobile number not found",
    hinglish: "Mobile Number nahi mila",
    hindi: "मोबाइल नंबर नहीं मिला",
  },
  "Something went wrong": {
    english: "Something went wrong",
    hinglish: "Something went wrong",
    hindi: "कुछ गलत हुआ",
  },
  "Logged out succssfully": {
    english: "Logged out succssfully",
    hinglish: "Log out successful",
    hindi: "लॉग आउट सफल हुआ",
  },
  "Kyc Extraction Failed": {
    english: "KYC Extraction Failed",
    hinglish: "KYC Extraction fail hua",
    hindi: "KYC निकालने में विफल",
  },
  "Upload clear PAN image": {
    english: "Upload clear PAN image",
    hinglish: "Clear Pan image daalen",
    hindi: "साफ़ पैन फोटो अपलोड करें",
  },
  "Pan Detail Extraction failed as": {
    english: "Pan Detail Extraction failed as",
    hinglish: "Pan Detail Extraction failed as",
    hindi: "Pan Detail Extraction failed as",
  },
  "Pan image needed to be in base64": {
    english: "Pan image needed to be in base64",
    hinglish: "Pan image needed to be in base64",
    hindi: "Pan image needed to be in base64",
  },
  "Parsing Failed": {
    english: "Parsing Failed",
    hinglish: "Parsing Failed",
    hindi: "Parsing Failed",
  },
  "Please recheck aadhaar image and try again": {
    english: "Please recheck aadhaar image and try again",
    hinglish: "Please adhaar image ko check karen aur dobara daalen",
    hindi: "कृपया आधार फोटो को चेक करें और दोबारा डालें",
  },
  "Aadhaar masking failed": {
    english: "Aadhaar masking failed",
    hinglish: "Aadhaar masking failed",
    hindi: "Aadhaar masking failed",
  },
  "Customer already exist": {
    english: "Customer already exist",
    hinglish: "Customer already exist karta hai",
    hindi: "कस्टमर पहले से मौजूद है",
  },
  "Pan and Aadhaar name is not matching": {
    english: "Pan and Aadhaar name is not matching",
    hinglish: "Pan aur adhaar me naam different hai",
    hindi: "पैन और आधार में नाम अलग है",
  },
  "Name match api failed": {
    english: "Name match api failed",
    hinglish: "Name match api failed",
    hindi: "Name match api failed",
  },
  "Customer Onboarded Successfully": {
    english: "Customer Onboarded Successfully",
    hinglish: "Customer Onboarded Successfully",
    hindi: "कस्टमर सफलतापूर्वक ऑनबोर्ड हो गया",
  },
  "Credit consent is mandatory": {
    english: "Credit consent is mandatory",
    hinglish: "Credit consent jaroori hai",
    hindi: "क्रेडिट सहमति अनिवार्य है",
  },
  "Otp sent to the customer": {
    english: "Otp sent to the customer",
    hinglish: "OTP customer ko bheja gaya",
    hindi: "OTP कस्टमर को भेजा गया",
  },
  "Updated psychometric data": {
    english: "Updated psychometric data",
    hinglish: "Updated psychometric data",
    hindi: "Updated psychometric data",
  },
  "Status changed successfully": {
    english: "Status changed successfully",
    hinglish: "Status successfully change hua",
    hindi: "स्टेटस सफलतापूर्वक बदली गई",
  },
  "CoApplicant already onboarding": {
    english: "Co-Customer already onboarded",
    hinglish: "Co-Customer pahle se hi onboarded hai",
    hindi: "सह-कस्टमर पहले से ही ऑनबोर्ड हैं",
  },
  "Customer Pan and CoCustomer Pan should not be same": {
    english: "Customer Pan and Co-Customer Pan should not be same",
    hinglish: "Customer Pan and Co-Customer Pan same nahi ho sakta",
    hindi: "कस्टमर पैन और को-कस्टमर पैन एक जैसा नहीं होना चाहिए",
  },
  "CoCustomer Onboarded Successfully": {
    english: "Co-Customer Onboarded Successfully",
    hinglish: "Co-Customer Onboarded Successfully",
    hindi: "सह-कस्टमर सफलतापूर्वक ऑनबोर्ड हो गया",
  },
  "Error deleting file: ${key}": {
    english: "Error deleting file: ${key}",
    hinglish: "Error deleting file: ${key}",
    hindi: "Error deleting file: ${key}",
  },
  "Co Customer Deleted Successfully": {
    english: "Co-Customer Deleted Successfully",
    hinglish: "Co-Customer succesfully delete hua",
    hindi: "सह-कस्टमर सफलतापूर्वक हटा दिया गया",
  },
  "Rejected Co-Customer not found": {
    english: "Rejected Co-Customer not found",
    hinglish: "Rejected Co-Customer nahi mila",
    hindi: "अस्वीकृत सह-कस्टमर नहीं मिला",
  },
  "Updated loan Successfully": {
    english: "Updated loan Successfully",
    hinglish: "Loan Successfully update hua",
    hindi: "लोन सफलतापूर्वक अपडेट किया गया",
  },
  "Updated loan details successful": {
    english: "Updated loan details successful",
    hinglish: "Loan details successfully update hua",
    hindi: "अपडेट किया गया लोन डिटेल सफल",
  },
  "Application is not in Loan Offer Stage": {
    english: "Application is not in Loan Offer Stage",
    hinglish: "Application loan offer stage me nahi hai",
    hindi: "फाइल लोन प्रस्ताव स्टेज में नहीं है",
  },
  "Loan Offer Accepted Successfully": {
    english: "Loan Offer Accepted Successfully",
    hinglish: "Loan offer successfully accept hua",
    hindi: "लोन ऑफर सफलतापूर्वक स्वीकार किया गया",
  },
  "Documents Uploaded Successfully": {
    english: "Documents Uploaded Successfully",
    hinglish: "Documents succesfully upload hue",
    hindi: "डॉक्यूमेंट सफलतापूर्वक अपलोड किए गए",
  },
  "Penny Drop Failed": {
    english: "Bank account verification failed",
    hinglish: "Bank account verification fail hua",
    hindi: "बैंक अकाउंट वेरिफिकेशन फ़ैल हुआ",
  },
  "Bank details updated successfully": {
    english: "Bank details updated successfully",
    hinglish: "Bank details succesfully update hui",
    hindi: "बैंक डिटेल्स सफलतापूर्वक अपडेट किया गया",
  },
  "Proceeded to Loan Disbursal": {
    english: "Proceeded to Loan Disbursal",
    hinglish: "Loan disbursement ke liye aange badha",
    hindi: "लोन वितरण के लिए आगे बढ़ा",
  },
  "Already uploaded without meta_page": {
    english: "Already uploaded without meta_page",
    hinglish: "Already uploaded without meta_page",
    hindi: "Already uploaded without meta_page",
  },
  "Document not found for override": {
    english: "Document not found for override",
    hinglish: "Already uploaded without meta_page",
    hindi: "Already uploaded without meta_page",
  },
  "Application ID is required": {
    english: "Application ID is required",
    hinglish: "Application ID ki jarurat hai",
    hindi: "एप्लिकेशन आईडी आवश्यक है",
  },
  "Virtual Account already exists for the loan application ${application_id}": {
    english:
      "Virtual Account already exists for the loan application ${application_id}",
    hinglish:
      "Virtual Account already exists for the loan application ${application_id}",
    hindi:
      "Virtual Account already exists for the loan application ${application_id}",
  },
  "Virtual Account Created Successfully and linked with loan application ${application_id}":
    {
      english:
        "Virtual Account Created Successfully and linked with loan application ${application_id}",
      hinglish:
        "Virtual Account Created Successfully and linked with loan application ${application_id}",
      hindi:
        "Virtual Account Created Successfully and linked with loan application ${application_id}",
    },
  "Page or limit cannot be 0 or negative": {
    english: "Page or limit cannot be 0 or negative",
    hinglish: "Page or limit cannot be 0 or negative",
    hindi: "Page or limit cannot be 0 or negative",
  },
  "Atleast 1 document should be uploaded": {
    english: "Atleast 1 document should be uploaded",
    hinglish: "Minimum 1 document upload karen",
    hindi: "कम से कम 1 डॉक्यूमेंट अपलोड करें",
  },
  "Collection documents cannot be updated": {
    english: "Collection documents cannot be updated",
    hinglish: "Collection document update nahi kiya ja sakta hai",
    hindi: "कलेक्शन डॉक्यूमेंट अपडेट नहीं किया जा सकता है",
  },
  "Updated successfully": {
    english: "Updated successfully",
    hinglish: "Updated successfully",
    hindi: "सफलतापूर्वक अपडेट किया गया",
  },
  "Document status fetched successfully": {
    english: "Document status fetched successfully",
    hinglish: "Document status succesfully mila",
    hindi: "डॉक्यूमेंट का स्टेटस सफलतापूर्वक प्राप्त हुई",
  },
  "Please provide language to update": {
    english: "Please provide language to update",
    hinglish: "Please provide language to update",
    hindi: "कृपया अपडेट करने के लिए भाषा प्रदान करें",
  },
  "Language Updated Successfully": {
    english: "Language Updated Successfully",
    hinglish: "Language Updated Successfully",
    hindi: "भाषा सफलतापूर्वक अपडेट की गई",
  },
  "OTP resent successfully": {
    english: "OTP resent successfully",
    hinglish: "OTP successfully dobara bheja gaya",
    hindi: "ओटीपी सफलतापूर्वक फिर से भेजा गया",
  },
  "Please re-check your inputs": {
    english: "Please re-check your inputs",
    hinglish: "Please input re-check karen",
    hindi: "कृपया अपने इनपुट दोबारा जांचें",
  },
  "E-Mandate Successfull!": {
    english: "E-Mandate Successfull!",
    hinglish: "E-Mandate Successfull!",
    hindi: "ई-मैंडेट सफल!",
  },
  "Failed to create mandate": {
    english: "Failed to create mandate",
    hinglish: "Failed to create mandate",
    hindi: "मैंडेट बनाने में विफल",
  },
  "Mobile Number is missing": {
    english: "Mobile Number is missing",
    hinglish: "Mobile Number is missing",
    hindi: "मोबाइल नंबर नहीं है",
  },
  "UPI Auto collect setup failed": {
    english: "UPI Auto collect setup failed",
    hinglish: "UPI Auto collect setup fail hua",
    hindi: "UPI ऑटो कलेक्ट सेटअप विफल रहा",
  },
  "Server error": {
    english: "Server error",
    hinglish: "Server error",
    hindi: "Server error",
  },
  "Please accept the checkbox": {
    english: "Please click on the checkbox",
    hinglish: "Please checkbox me click karen",
    hindi: "कृपया चेकबॉक्स पर क्लिक करें",
  },
  "Customer Details": {
    english: "Customer Details",
    hinglish: "Customer Details",
    hindi: "कस्टमर विवरण",
  },
  "Under Review": {
    english: "Under Review",
    hinglish: "Review ho raha hai",
    hindi: "रिव्यु हो रहा है",
  },
  "Co-Applicant Onboarding": {
    english: "Co-Customer Onboarding",
    hinglish: "Co-Customer Onboarding",
    hindi: "सह-कस्टमर ऑनबोर्डिंग",
  },
  "Co-Applicant Re-creation": {
    english: "Co-Customer Re-creation",
    hinglish: "Co-Customer Re-creation",
    hindi: "सह-कस्टमर पुन: निर्माण",
  },
  "Co-Applicant Basic Details": {
    english: "Co-Customer Basic Details",
    hinglish: "Co-Customer Basic Details",
    hindi: "सह-कस्टमर मूल विवरण",
  },
  "Co-Applicant Rejected": {
    english: "Co-Customer Rejected",
    hinglish: "Co-Customer Rejected",
    hindi: "सह-कस्टमर रिजेक्टेड",
  },
  "Co-Applicant Under Review": {
    english: "Co-Customer Under Review",
    hinglish: "Co-Customer Under Review",
    hindi: "सह-कस्टमर रिव्यु हो रहा है",
  },
  "Document Uploads": {
    english: "Document Uploads",
    hinglish: "Document Uploads",
    hindi: "डॉक्यूमेंट अपलोड",
  },
  "Loan Creation": {
    english: "Loan Creation",
    hinglish: "Loan Creation",
    hindi: "लोन निर्माण",
  },
  "Bank Details": {
    english: "Bank Details",
    hinglish: "Bank Details",
    hindi: "बैंक डिटेल्स",
  },
  "NBFC Assignment Pending": {
    english: "NBFC Assignment Pending",
    hinglish: "NBFC Assignment Pending",
    hindi: "एनबीएफसी असाइनमेंट पेंडिंग",
  },
  "Loan Summary": {
    english: "Loan Summary",
    hinglish: "Loan Summary",
    hindi: "लोन सारांश",
  },
  "Pre Disbursment": {
    english: "Pre Disbursment",
    hinglish: "Pre Disbursment",
    hindi: "Pre-डिस्बर्समेंट",
  },
  "Bank Details Under Review": {
    english: "Bank Details Under Review",
    hinglish: "Bank Details Under Review",
    hindi: "बैंक डिटेल्स रिव्यु की जा रही है",
  },
  "NBFC Approval Pending": {
    english: "NBFC Approval Pending",
    hinglish: "NBFC Approval Pending",
    hindi: "NBFC अनुमति पेंडिंग",
  },
  "Waiting Disbursal": {
    english: "Waiting Disbursal",
    hinglish: "Waiting Disbursal",
    hindi: "डिस्बर्समेंट प्रतीक्षा",
  },
  Disbursed: {
    english: "Disbursed",
    hinglish: "Disbursed",
    hindi: "डिस्बर्स",
  },
  Completed: {
    english: "Completed",
    hinglish: "Completed",
    hindi: "पूरा हुआ",
  },
  Loading: {
    english: "Loading",
    hinglish: "Loading",
    hindi: "प्रसंस्करण",
  },
  Search: {
    english: "Search",
    hinglish: "khoj",
    hindi: "खोज",
  },
  "Search Name/Application Id": {
    english: "Search Name/Application Id",
    hinglish: "khoj naam / application id",
    hindi: "खोज नाम / एप्लीकेशन आई.डी.",
  },

  // 17-05-2023
  "Apply for Loan": {
    english: "Apply for Loan",
    hinglish: "Loan ke liye Apply Karen",
    hindi: "लोन के लिए आवेदन",
  },
  "Get instant E- Rickshaw Loan": {
    english: "Get instant E- Rickshaw Loan",
    hinglish: "Instant E-Rickshaw Loan Le",
    hindi: "तत्काल ई-रिक्शा लोन प्राप्त करें",
  },
  "Avail Now": {
    english: "Avail Now",
    hinglish: "Abhi le",
    hindi: "अभी लाभ उठाएं",
  },
  "Amount Credited": {
    english: "Amount Credited",
    hinglish: "Amount Credited",
    hindi: "जमा की गई राशि",
  },
  "Complete All the Draft Applications to earn more": {
    english: "Complete All the Draft Applications to earn more",
    hinglish: "Aur kamane ke liye Draft application complete karen",
    hindi: "अधिक कमाने के लिए सभी ड्राफ़्ट एप्लीकेशन को पूरा करें",
  },
  "Get Instant Funds": {
    english: "Get Instant Funds",
    hinglish: "Instant Fund le",
    hindi: "तत्काल धन प्राप्त करें",
  },
  "Reward points": {
    english: "Reward points",
    hinglish: "Rewards points",
    hindi: "रिवॉर्ड पॉइंट",
  },
  Register: {
    english: "Register",
    hinglish: "Register",
    hindi: "रजिस्टर",
  },
  Upload: {
    english: "Upload",
    hinglish: "Upload",
    hindi: "डालें",
  },
  Nach: {
    english: "Nach",
    hinglish: "Nach",
    hindi: "Nach",
  },
  "Upload RC": {
    english: "Upload RC",
    hinglish: "RC Daalen",
    hindi: "RC डालें",
  },
  "Courier Documents": {
    english: "Courier Documents",
    hinglish: "Courier Documents",
    hindi: "कूरियर डॉक्यूमेंट",
  },
  "Total Amount Collected": {
    english: "Total Amount Collected",
    hinglish: "Total Amount Collected",
    hindi: "कुल जमा राशि",
  },
  "Check Now": {
    english: "Check Now",
    hinglish: "Check Now",
    hindi: "अब जांचें",
  },
  Rewards: {
    english: "Rewards",
    hinglish: "Rewards",
    hindi: "रिवॉर्ड",
  },
  "Welcome to": {
    english: "Welcome to",
    hinglish: "Welcome to",
    hindi: "आपका स्वागत है",
  },
  "Embifi Rewards": {
    english: "Embifi Rewards",
    hinglish: "Embifi Rewards",
    hindi: "Embifi रिवॉर्ड",
  },
  "Total Points Earned": {
    english: "Total Points Earned",
    hinglish: "Total Points Earned",
    hindi: "कुल कमाए हुए पॉइंट",
  },
  "Earn More": {
    english: "Earn More",
    hinglish: "Earn More",
    hindi: "और कमाए",
  },
  Task: {
    english: "Task",
    hinglish: "Kaam",
    hindi: "काम",
  },
  Coins: {
    english: "Coins",
    hinglish: "Coins",
    hindi: "कोइन्स",
  },
  Collections: {
    english: "Collections",
    hinglish: "Collections",
    hindi: "कलेक्शन",
  },
  "Amount Collected from customers": {
    english: "Amount Collected from customers",
    hinglish: "Amount Collected from customers",
    hindi: "कस्टमर से प्राप",
  },
  GPS: {
    english: "GPS",
    hinglish: "GPS",
    hindi: "जीपीएस",
  },
  "Vehicle Activity Tracker": {
    english: "Vehicle Activity Tracker",
    hinglish: "Vehicle Activity Tracker",
    hindi: "वाहन गतिविधि ट्रैकर",
  },
  "Total Collection": {
    english: "Total Collection",
    hinglish: "Total Collection",
    hindi: "टोटल कलेक्शन",
  },
  "Basic Details": {
    english: "Basic Details",
    hinglish: "Basic Details",
    hindi: "मूल विवरण",
  },
  DOB: {
    english: "DOB",
    hinglish: "DOB",
    hindi: "जन्म तिथि",
  },
  Gender: {
    english: "Gender",
    hinglish: "Gender",
    hindi: "लिंग",
  },
  "Mobile number": {
    english: "Mobile number",
    hinglish: "Mobile number",
    hindi: "मोबाइल नंबर",
  },
  "Current Address": {
    english: "Current Address",
    hinglish: "Current Address",
    hindi: "वर्त्तमान पता",
  },
  "Loan Details": {
    english: "Loan Details",
    hinglish: "Loan Details",
    hindi: "लोन विवरण",
  },
  "Loan Amount": {
    english: "Loan Amount",
    hinglish: "Loan Amount",
    hindi: "लोन धनराशि",
  },
  "Interest Rate": {
    english: "Interest Rate",
    hinglish: "Interest Rate",
    hindi: "ब्याज दर",
  },
  Tenure: {
    english: "Tenure",
    hinglish: "Tenure",
    hindi: "कार्यकाल",
  },
  "EMI Amount": {
    english: "EMI Amount",
    hinglish: "EMI Amount",
    hindi: "EMI धनराशि",
  },
  Collection: {
    english: "Collection",
    hinglish: "Collection",
    hindi: "कलेक्शन",
  },
  "Amount Collected": {
    english: "Amount Collected",
    hinglish: "Amount Collected",
    hindi: "जमा की गई राशि",
  },
  "Due Amount": {
    english: "Due Amount",
    hinglish: "Due Amount",
    hindi: "बकाया राशि",
  },
  "Due Date": {
    english: "Due Date",
    hinglish: "Due Date",
    hindi: "भुगतान तिथि",
  },
  RC: {
    english: "RC",
    hinglish: "RC",
    hindi: "RC",
  },
  Courier: {
    english: "Courier",
    hinglish: "Courier",
    hindi: "कूरियर",
  },
  "Post Disbursement": {
    english: "Post Disbursement",
    hinglish: "Post Disbursement",
    hindi: "पोस्ट डिसबर्समेंट",
  },
  "Post Disbursal Documents": {
    english: "Post Disbursal Documents",
    hinglish: "Post Disbursal Documents",
    hindi: "पोस्ट डिस्बर्सल डॉक्यूमेंट",
  },
  "Disbursal Date": {
    english: "Disbursal Date",
    hinglish: "Disbursal Date",
    hindi: "डिस्बर्सल तिथि",
  },
  "E-Nach": {
    english: "E-Nach",
    hinglish: "E-Nach",
    hindi: "E-Nach",
  },
  "Physical Nach": {
    english: "Physical Nach",
    hinglish: "Physical Nach",
    hindi: "फिजिकल Nach",
  },
  "Debit card/ Net Banking": {
    english: "Debit card/ Net Banking",
    hinglish: "Debit card/ Net Banking",
    hindi: "डेबिट कार्ड / नेट बैंकिंग",
  },
  Download: {
    english: "Download",
    hinglish: "Download",
    hindi: "डाउनलोड",
  },
  "Search Application": {
    english: "Search Application",
    hinglish: "Search Application",
    hindi: "एप्लीकेशन खोजे",
  },
  "Amount Credited": {
    english: "Amount Credited",
    hinglish: "Amount Credited",
    hindi: "जमा की गई राशि",
  },
  "Total Amount Credited": {
    english: "Total Amount Credited",
    hinglish: "Total Amount Credited",
    hindi: "कुल जमा की गई राशि",
  },
  "Recent Amount Credited": {
    english: "Recent Amount Credited",
    hinglish: "Recent Amount Credited",
    hindi: "हाल ही में जमा की गई राशि",
  },
  "Credit Date": {
    english: "Credit Date",
    hinglish: "Credit Date",
    hindi: "क्रेडिट तिथि",
  },
  Logout: {
    english: "Logout",
    hinglish: "Logout",
    hindi: "लॉगआउट",
  },
  Profile: {
    english: "Profile",
    hinglish: "Profile",
    hindi: "प्रोफाइल",
  },
  Capture: {
    english: "Capture",
    hinglish: "Capture",
    hindi: "फोटो लीजिए",
  },
  "Select Anchor": {
    english: "Select Anchor",
    hinglish: "Select Anchor",
    hindi: "एंकर का चयन करें",
  },
  "Press to Re-capture/upload": {
    english: "Press to Re-capture/upload",
    hinglish: "Dobara upload karne k liye press karein",
    hindi: "अपलोड को पुनः कैप्चर करने के लिए दबाएँ",
  },
  "Please re-check you PAN": {
    english: "Please re-check your PAN",
    hinglish: "Dobara PAN check karein",
    hindi: "कृपया अपने पैन की फिर से जांच करें",
  },
  "This field is required": {
    english: "This field is required",
    hinglish: "Yah field avashyak hai",
    hindi: "यह फ़ील्ड आवश्यक है",
  },
  Date: {
    english: "Date",
    hinglish: "Date",
    hindi: "डेट",
  },
  "Full Name": {
    english: "Full Name",
    hinglish: "Full Name",
    hindi: "पूरा नाम",
  },
  "Is Aadhaar property owned by co-customer?": {
    english: "Is Aadhaar property owned by co-customer?",
    hinglish: "",
    hindi: "क्या आधार संपत्ति सह-ग्राहक के स्वामित्व में है?",
  },
  "Is Residential property owned by co applicant?": {
    english: "Is Residential property owned by co applicant?",
    hinglish: "",
    hindi: "क्या आवासीय संपत्ति सह आवेदक के स्वामित्व में है?",
  },
  "Processing Fees": {
    english: "Processing Fees",
    hinglish: "Processing fees",
    hindi: "प्रोसेसिंग फीस",
  },
  "Battery Ah should be between 100-200": {
    english: "Battery Ah should be between 100-200",
    hinglish: "Battery Ah should be between 100-200",
    hindi: "बैटरी Ah 100-200 के बीच होनी चाहिए",
  },
  "cheque 1": {
    english: "cheque 1",
    hinglish: "cheque 1",
    hindi: "चेक 1",
  },
  "cheque 2": {
    english: "cheque 2",
    hinglish: "cheque 2",
    hindi: "चेक 2",
  },
  "cheque 3": {
    english: "cheque 3",
    hinglish: "cheque 3",
    hindi: "चेक 3",
  },
  "Account Holdername": {
    english: "Account Holdername",
    hinglish: "Account Holdername",
    hindi: "खाता धारक का नाम",
  },
  "Cannot not start with space": {
    english: "Cannot not start with space",
    hinglish: "Cannot not start with space",
    hindi: "स्पेस से प्रारंभ नहीं कर सकते",
  },
  "Account number does not match": {
    english: "Account number does not match",
    hinglish: "Account number does not match",
    hindi: "खाता संख्या मेल नहीं खाती",
  },
  "Phone number not valid": {
    english: "Phone number not valid",
    hinglish: "Phone number not valid",
    hindi: "फ़ोन नंबर मान्य नहीं",
  },
  "Email not valid": {
    english: "Email not valid",
    hinglish: "Email invalid",
    hindi: "ईमेल मान्य नहीं है",
  },
  "CoApplicant's distance_status is Declined, Rejected": {
    english: "CoApplicant's distance_status is Declined, Rejected",
    hinglish: "CoApplicant's distance_status is Declined, Rejected",
    hindi: "सह-कस्टमर का डिस्टेंस स्टेटस अस्वीकार, खारिज",
  },
  "Processing Fees": {
    english: "Processing Fees",
    hinglish: "Processing fees",
    hindi: "प्रोसेसिंग फीस",
  },
  "Terms and conditions": {
    english: "Terms and conditions",
    hinglish: "Terms and conditions",
    hindi: "टर्म्स एंड कंडीशंस",
  },
  "Privacy policy": {
    english: "Privacy policy",
    hinglish: "Privacy policy",
    hindi: "प्राइवेसी पालिसी",
  },
  Resend: {
    english: "Resend",
    hinglish: "Resend",
    hindi: "फिर भेजें",
  },
  Successfull: {
    english: "Successfull",
    hinglish: "Successfull",
    hindi: "सफल",
  },
  "Registration via NPCI": {
    english: "Registration via NPCI",
    hinglish: "Registration via NPCI",
    hindi: "NPCI के माध्यम से पंजीकरण",
  },
  "Upload Again": {
    english: "Upload Again",
    hinglish: "Upload Again",
    hindi: "फिर से अपलोड करें",
  },
  Unsuccessful: {
    english: "Unsuccessful",
    hinglish: "Unsuccessful",
    hindi: "असफल",
  },
  "Pay via QR": {
    english: "Pay via QR",
    hinglish: "Pay via QR",
    hindi: "QR के माध्यम से भुगतान करें",
  },
  "Scan QR for every Payment": {
    english: "Scan QR for every Payment",
    hinglish: "Scan QR for every Payment",
    hindi: "प्रत्येक भुगतान के लिए QR स्कैन करें",
  },
  "Storage permission required": {
    english: "Storage permission required",
    hinglish: "Storage permission required",
    hindi: "स्टोरेज परमिशन आवश्यक है",
  },
  "App needs access to store images": {
    english: "App needs access to store images",
    hinglish: "App needs access to store images",
    hindi: "एप्लिकेशन को इमेजेज को स्टोर करने के लिए एक्सेस की आवश्यकता है",
  },
  "Image Downloaded Successfully": {
    english: "Image Downloaded Successfully",
    hinglish: "Image Downloaded Successfully",
    hindi: "छवि सफलतापूर्वक डाउनलोड की गई",
  },
  "Borrower Name": {
    english: "Borrower Name",
    hinglish: "Borrower Name",
    hindi: "उधारकर्ता का नाम",
  },
  File: {
    english: "File",
    hinglish: "File",
    hindi: "फाइल",
  },
  "file type": {
    english: "File type",
    hinglish: "File type",
    hindi: "फ़ाइल प्रकार",
  },
  "Cannot be less than 1": {
    english: "Cannot be less than 1",
    hinglish: "Ek se kam nahi hosakta",
    hindi: "1 से कम नहीं हो सकता",
  },
  "Cannot be greater than Total members": {
    english: "Cannot be greater than Total members",
    hinglish: "Total members se jada nahi hosakta",
    hindi: "कुल सदस्यों से बड़ा नहीं हो सकता",
  },
  "Invalid Mobile Number": {
    english: "Invalid Mobile Number",
    hinglish: "Invalid Mobile Number",
    hindi: "अमान्य मोबाइल नंबर",
  },
  "Mobile cannot be same as reference 1": {
    english: "Mobile cannot be same as reference 1",
    hinglish: "Mobile aur reference 1 same nahi hosakte",
    hindi: "मोबाइल संदर्भ 1 के समान नहीं हो सकता है",
  },
  "Please accept the checkbox": {
    english: "Please accept the checkbox",
    hinglish: "Kripya checkbox ko svikar karen",
    hindi: "कृपया चेकबॉक्स स्वीकार करें",
  },
  "Otp sent to mobile": {
    english: "Otp sent to mobile",
    hinglish: "Mobile pe bheja gaya Otp",
    hindi: "मोबाइल पर भेजा गया ओटीपी",
  },
  "Field Investigation Updated": {
    english: "Field Investigation Updated",
    hinglish: "Field Investigation Updated",
    hindi: "फील्ड इन्वेस्टीगेशन अपडेटेड",
  },
  "Other Ownership Proof": {
    english: "Other Ownership Proof",
    hinglish: "Other Ownership Proof",
    hindi: "अन्य ओनरशिप प्रमाण",
  },
  Gallery: {
    english: "Gallery",
    hinglish: "Gallery",
    hindi: "गैलरी",
  },
  "STAMP PAPER": {
    english: "STAMP PAPER",
    hinglish: "STAMP PAPER",
    hindi: "स्टाम्प पेपर",
  },
  Combine: {
    english: "Combine",
    hinglish: "Combine",
    hindi: "कंबाइन",
  },
  "Fetching of credit score is taking time, Please check after 20 mins": {
    english:
      "Fetching of credit score is taking time, Please check after 20 mins",
    hinglish:
      "Credit score dekhne me time lag raha hai please 20 mins baad check karen",
    hindi:
      "क्रेडिट स्कोर प्राप्त करने में समय लग रहा है, कृपया 20 मिनट बाद चेक करें",
  },
  "Fetching of credit score is taking time, Please check after 40 mins": {
    english:
      "Fetching of credit score is taking time, Please check after 40 mins",
    hinglish:
      "Credit score dekhne me time lag raha hai please 40 mins baad check karen",
    hindi:
      "क्रेडिट स्कोर प्राप्त करने में समय लग रहा है, कृपया 40 मिनट बाद चेक करें",
  },
  "Customer already exist as co-customer in an un-closed loan": {
    english: "Customer already exists as a co-customer in an unclosed loan",
    hinglish: "Graahak pehle se hi ek an-closed loan mein sah-graahak hai",
    hindi:
      "ग्राहक पहले से ही एक अन-क्लोज्ड लोन में सह-ग्राहक के रूप में मौजूद है",
  },
  "Loan Amount credited": {
    english: "Loan amount credited",
    hinglish: "Loan amount jama ki gayi",
    hindi: "लोन अमाउंट जमा की गई",
  },
  "Date of disbursal": {
    english: "Date of disbursal",
    hinglish: "Pay out ki date",
    hindi: "संवितरण की तिथि",
  },
  Amount: {
    english: "Amount",
    hinglish: "Amount",
    hindi: "अमाउंट",
  },
  "UTR No.": {
    english: "UTR No.",
    hinglish: "UTR No.",
    hindi: "यूटीआर नंबर",
  },
  "Document needed": {
    english: "Document needed",
    hinglish: "Dastavez ki avashyakta",
    hindi: "दस्तावेज़ की आवश्यकता",
  },
  "Upload RC": {
    english: "Upload RC",
    hinglish: "RC upload karein",
    hindi: "अपलोड करें आरसी",
  },
  "Upload Courier receipt": {
    english: "Upload courier receipt",
    hinglish: "Courier rasid upload karein",
    hindi: "कूरियर रसीद अपलोड करें",
  },
  "All the documents for this application are approved please wait for 24hrs for loan get credited on your account":
    {
      english:
        "All the documents for this application are approved. Please wait for 24 hours for the loan to be credited to your account.",
      hinglish:
        "Is application ke saare dastavez manjoor ho gaye hain. Kripya 24 ghante tak intezar karein, taaki aapke khate mein loan jama ho sake.",
      hindi:
        "इस आवेदन के लिए सभी दस्तावेज स्वीकृत हैं। कृपया अपने खाते में ऋण जमा होने के लिए 24 घंटे प्रतीक्षा करें।",
    },
  All: {
    english: "All",
    hinglish: "Sabhi",
    hindi: "सभी",
  },
  Drafts: {
    english: "Drafts",
    hinglish: "Drafts",
    hindi: "ड्राफ्ट",
  },
  Closed: {
    english: "Closed",
    hinglish: "Closed",
    hindi: "बंद",
  },
  "Loaded all the data": {
    english: "Loaded all the data",
    hinglish: "Sabhi data load ho gaye",
    hindi: "सभी डेटा लोड किया",
  },
  "No Applications here": {
    english: "No Applications here",
    hinglish: "Yahaan koi applications nahin hai",
    hindi: "कोई एप्लीकेशन नहीं",
  },
  Notifications: {
    english: "Notifications",
    hinglish: "Notifications",
    hindi: "सूचनाएँ",
  },
  "New notification": {
    english: "New notification",
    hinglish: "Nayi adhishoochana",
    hindi: "नई अधिसूचना",
  },
  "a few seconds ago": {
    english: "A few seconds ago",
    hinglish: "Kuch seconds pehle",
    hindi: "कुछ सेकंड पहले",
  },
  Uploading: {
    english: "Uploading",
    hinglish: "Uploading",
    hindi: "अपलोड हो रहा है",
  },
  "verify mobile": {
    english: "Verify mobile",
    hinglish: "Mobile number ko verify karein",
    hindi: "मोबाइल वेरीफाई करें",
  },
  "loan agreement": {
    english: "Loan agreement",
    hinglish: "Loan agreement",
    hindi: "लोन एग्रीमेंट",
  },
  "E-sign": {
    english: "E-sign",
    hinglish: "E-sign",
    hindi: "ई-साइन",
  },
  Manual: {
    english: "Manual",
    hinglish: "Manual",
    hindi: "मैनुअल",
  },
  NOTE: {
    english: "NOTE",
    hinglish: "NOTE",
    hindi: "नोट",
  },
  "Make sure Aadhaar and phone no. of applicant are linked": {
    english: "Make sure Aadhaar and phone number of the applicant are linked",
    hinglish: "Yeh zaroori hai ki aadhaar aur phone number jode gaye hain",
    hindi: "सुनिश्चित करें कि आवेदक का आधार और फोन नंबर जुड़े हुए हैं",
  },
  "Co-Borrower": {
    english: "Co-Borrower",
    hinglish: "Sah-udhaar-karta",
    hindi: "सह-उधारकर्ता",
  },
  "Co-Borrower pending": {
    english: "Co-Borrower pending",
    hinglish: "Sah-udhaar-karta pending hai",
    hindi: "सह-उधारकर्ता पेंडिंग है",
  },
  "Your application generated successfully": {
    english: "Your application generated successfully",
    hinglish: "Aapka application safalta se ban gaya hai",
    hindi: "आपका एप्लीकेशन सफलतापूर्वक जनरेट हुआ है",
  },
  Generate: {
    english: "Generate",
    hinglish: "Generate",
    hindi: "जेनेरेट",
  },

  "Post disbursed overview": {
    english: "Post disbursed overview",
    hinglish: "Post disbursed overview",
    hindi: "पोस्ट डिस्बर्सेड ओवरव्यू",
  },
  Schedule: {
    english: "Schedule",
    hinglish: "Schedule",
    hindi: "शिड्यूल",
  },
  "Mobile number cannot be same with anchor/dealer": {
    english: "Mobile number cannot be same with anchor/dealer",
    hinglish: "Mobile number anchor/dealer se match nahi hone chahiye",
    hindi: "मोबाइल नंबर एंकर/डीलर के साथ समान नहीं हो सकता",
  },
  "Customer Mobile number matches with another customer in the database": {
    english:
      "Customer Mobile number matches with another customer in the database",
    hinglish: "Customer ka mobile number kisi aur customer se match horaha hai",
    hindi: "ग्राहक मोबाइल नंबर डेटाबेस में किसी अन्य ग्राहक के साथ मेल खाता है",
  },
  "Co Customer Mobile number matches with customer": {
    english: "Co Customer Mobile number matches with customer",
    hinglish: "Co-customer ka mobile number customer se match horaha hai",
    hindi: "सह ग्राहक मोबाइल नंबर ग्राहक के साथ मेल खाता है",
  },
  "Agent Mobile number matches with customer in the system": {
    english: "Agent Mobile number matches with customer in the system",
    hinglish: "Agent ka mobile number customer se match horaha hai",
    hindi: "एजेंट मोबाइल नंबर सिस्टम में ग्राहक के साथ मेल खाता है",
  },
  "Co Customer Mobile number already matches with agent": {
    english: "Co Customer Mobile number already matches with agent",
    hinglish: "co-customer ka mobile number agent se match horaha hai",
    hindi: "सह ग्राहक का मोबाइल नंबर पहले से ही एजेंट के साथ मेल खाता है",
  },
  "Co Customer Mobile number already matches with reference": {
    english: "Co Customer Mobile number already matches with reference",
    hinglish: "Co-customer ka mobile number reference se match horaha hai",
    hindi: "सह ग्राहक मोबाइल नंबर पहले से ही संदर्भ के साथ मेल खाता है",
  },
  "customer's Mobile number matches with reference in the system": {
    english: "customer's Mobile number matches with reference in the system",
    hinglish: "customer ka mobile number kisi reference se match horaha hai",
    hindi: "ग्राहक का मोबाइल नंबर सिस्टम में संदर्भ के साथ मेल खाता है",
  },
  "refrence Mobile number cannot be same with anchor/dealer in db": {
    english: "refrence Mobile number cannot be same with anchor/dealer in db",
    hinglish:
      "reference ka mobile number anchor/dealer se match nahi hone chahiye",
    hindi:
      "रीफरेंस मोबाइल नंबर डीबी में एंकर / डीलर के साथ समान नहीं हो सकता है",
  },
  "Due Amount as on 26/05/23": {
    english: "Due Amount as on 26/05/23",
    hinglish: "26/05/23 tak ka due amount",
    hindi: "दिनांक 26/05/23 तक बकाया राशि",
  },
  "Nach status": {
    english: "Nach status",
    hinglish: "Nach status",
    hindi: "नाच स्थिति",
  },
  Register_Success: {
    english: "Register_Success",
    hinglish: "Register_Success",
    hindi: "पंजीकृत_सफलता",
  },
  Pending: {
    english: "Pending",
    hinglish: "Pending",
    hindi: "बकाया",
  },
  Delay: {
    english: "Delay",
    hinglish: "Delay",
    hindi: "विलंब",
  },
  Penalty: {
    english: "Penalty",
    hinglish: "Penalty",
    hindi: "जुर्माना",
  },
  Total: {
    english: "Total",
    hinglish: "Total",
    hindi: "कुल",
  },
  "Total Due till today": {
    english: "Total Due till today",
    hinglish: "Total Due till today",
    hindi: "आज तक कुल बकाया",
  },
  "Collected Amount": {
    english: "Collected Amount",
    hinglish: "Collected Amount",
    hindi: "वसूली हुई राशि",
  },
  "Collected %": {
    english: "Collected %",
    hinglish: "Collected %",
    hindi: "वसूली %",
  },
  "Collection History": {
    english: "Collection History",
    hinglish: "Collection History",
    hindi: "वसूली इतिहास",
  },
  "Transaction History": {
    english: "Transaction History",
    hinglish: "Transaction History",
    hindi: "लेन-देन इतिहास",
  },
  "EFI Date": {
    english: "EFI Date",
    hinglish: "EFI Date",
    hindi: "ईएफआई तिथि",
  },
  "EFI Amount": {
    english: "EFI Amount",
    hinglish: "EFI Amount",
    hindi: "ईएफआई राशि",
  },
  Status: {
    english: "Status",
    hinglish: "Status",
    hindi: "स्थिति",
  },
  Paid: {
    english: "Paid",
    hinglish: "Paid",
    hindi: "भुगतान हुआ",
  },
  "Partially Paid": {
    english: "Partially Paid",
    hinglish: "Partially Paid",
    hindi: "आंशिक भुगतान हुआ",
  },
  Name: {
    english: "Name",
    hinglish: "Name",
    hindi: "नाम",
  },
  "Due Amount": {
    english: "Due Amount",
    hinglish: "Due Amount",
    hindi: "बकाया राशि",
  },
  "Phone No": {
    english: "Phone No",
    hinglish: "Phone No",
    hindi: "फोन नंबर",
  },
  "Delayed by 3 days": {
    english: "Delayed by 3 days",
    hinglish: "3 din se late hua",
    hindi: "3 दिनों की विलंबितता",
  },
  Call: {
    english: "Call",
    hinglish: "Call",
    hindi: "कॉल",
  },
  "Transaction Date": {
    english: "Transaction Date",
    hinglish: "Transaction ki Date",
    hindi: "लेनदेन तिथि",
  },
  "Transaction Amount": {
    english: "Transaction Amount",
    hinglish: "Transaction Amount",
    hindi: "लेनदेन राशि",
  },
  "Payment Mode": {
    english: "Payment Mode",
    hinglish: "Payment Mode",
    hindi: "भुगतान का तरीका",
  },
  "Download Receipt": {
    english: "Download Receipt",
    hinglish: "Receipt download karein",
    hindi: "रसीद डाउनलोड करें",
  },
  UPI: {
    english: "UPI",
    hinglish: "UPI",
    hindi: "यूपीआई",
  },
  Cash: {
    english: "Cash",
    hinglish: "Cash",
    hindi: "नकद",
  },
  Nach: {
    english: "Nach",
    hinglish: "Nach",
    hindi: "नाच",
  },
  "Courier Document": {
    english: "Courier Document",
    hinglish: "Courier Document",
    hindi: "कूरियर दस्तावेज़",
  },
  Send: {
    english: "Send",
    hinglish: "Send",
    hindi: "भेजें",
  },
  "Upload Receipt": {
    english: "Upload Receipt",
    hinglish: "Receipt upload karein",
    hindi: "रसीद अपलोड करें",
  },
  "Receipt not available": {
    english: "Receipt not available",
    hinglish: "Receipt available nahi hai",
    hindi: "रसीद उपलब्ध नहीं है",
  },
  Collection: {
    english: "Collection",
    hinglish: "Collection",
    hindi: "कलेक्शन",
  },
  EMI: {
    english: "EMI",
    hinglish: "EMI",
    hindi: "EMI",
  },
  "Our lending partners": {
    english: "Our lending partners",
    hinglish: "Our lending partners",
    hindi: "हमारे लेंडिंग पार्टनर्स",
  },
  "Note: Co Applicant or Guarantor must have own residence in current residential region of Applicant.":
    {
      english:
        "Note: Co Applicant or Guarantor must have own residence in current residential region of Applicant.",
      hinglish:
        "Note: Co Applicant or Guarantor must have own residence in current residential region of Applicant.",
      hindi:
        "नोट: सह कस्टमर या गारंटर के पास कस्टमर के वर्तमान आवासीय क्षेत्र में अपना निवास होना चाहिए।",
    },
  "Co customer or Guarantor Required": {
    english: "Co customer or Guarantor Required",
    hinglish: "Co customer or Guarantor Required",
    hindi: "सह-कस्टमर या गारंटर अनिवार्य है",
  },
  "Debit card/ Net Banking/ Aadhaar": {
    english: "Debit card/ Net Banking/ Aadhaar",
    hinglish: "Debit card/ Net Banking/ Aadhaar",
    hindi: "डेबिट कार्ड / नेट बैंकिंग / आधार",
  },
  "Current Residential is owned by": {
    english: "Current Residential is owned by",
    hinglish: "Current Resident kon own karta hai",
    hindi: "वर्तमान पते का मालिक",
  },
  Quotation: {
    english: "Quotation",
    hinglish: "Quotation",
    hindi: "कोटेशन",
  },
  "Please Enter Aadhaar linked mobile number whose last 3 digits are": {
    english:
      "Please Enter Aadhaar linked mobile number whose last 3 digits are",
    hinglish:
      "Please Enter Aadhaar linked mobile number jiske last 3 digits hai",
    hindi: "कृपया आधार से जुड़ा मोबाइल नंबर दर्ज करें जिसके अंतिम 3 अंक हैं",
  },
  "OTP will be sent to the same number": {
    english: "OTP will be sent to the same number",
    hinglish: "Ussi number par OTP bheja jayega",
    hindi: "उसी नंबर पर ओटीपी भेजा जाएगा",
  },
  "You will be redirected to draft screen. Are you sure to leave the screen?": {
    english:
      "You will be redirected to draft screen. Are you sure to leave the screen?",
    hinglish:
      "You will be redirected to draft screen. Are you sure to leave the screen?",
    hindi:
      "आपको ड्राफ्ट स्क्रीन पर पुनः निर्देशित किया जाएगा। क्या आप निश्चित रूप से स्क्रीन छोड़ना चाहते हैं?",
  },
  "Click only when your current address is same as Aadhar": {
    english: "Click only when your current address is same as Aadhar",
    hinglish: "Click only when your current address is same as Aadhar",
    hindi:
      "केवल तभी क्लिक करें जब आपका वर्तमान पता और आधार में लिखा पता सामान हो",
  },
  "Fill address where you are currently living": {
    english: "Fill address where you are currently living",
    hinglish: "Wohi address bhare jaha aap abhi rah rahe hai",
    hindi: "यहाँ वहीं पता भरें जहाँ आप वर्तमान में रह रहे हैं",
  },
  "Cost of a vehicle to customer after including RTO & Insurance": {
    english: "Cost of a vehicle to customer after including RTO & Insurance",
    hinglish: "customer ko gaadi ki keemat RTO aur insurance jod kar",
    hindi: "ग्राहक को गाडी की कीमत में आरटीओ और बीमा को जोड़ कर",
  },
  "This is cost to dealer + Profit": {
    english: "This is cost to dealer + Profit",
    hinglish: "This is cost to dealer + Profit",
    hindi: "गाडी की कीमत डीलर की लागत + अपने लाभ के बाद",
  },
  "Fill the details of the person who is going for the investigation": {
    english:
      "Fill the details of the person who is going for the investigation",
    hinglish:
      "Jo agent investigation ke liye ja raha hai uski hi detail bharen",
    hindi: "जो व्यक्ति जांच के लिए जा रहा है उसका ही विवरण भरें",
  },
  "Check the status of the pending and rejected documents": {
    english: "Check the status of the pending and rejected documents",
    hinglish: "Check the status of the pending and rejected documents",
    hindi: "पेंडिंग और रिजेक्ट डॉक्यूमेंट की जानकारी देखें",
  },
  "No mobile is linked with customer’s Aadhaar number": {
    english: "No mobile is linked with customer’s Aadhaar number",
    hinglish: "Customer ka Koi bhi mobile number aadhaar se linked nahi hai",
    hindi: "कोई भी मोबाइल नंबर कस्टमर के आधार से लिंक नहीं है",
  },
  "Please ask customer to link phone number with Aadhaar for future ease": {
    english:
      "Please ask customer to link phone number with Aadhaar for future ease",
    hinglish:
      "Future me aasaani ke liye please customer ko apna mobile number aadhaar se link karne ke liye kahen",
    hindi:
      "भविष्य में आसानी के लिए कृपया कस्टमर से फोन नंबर को आधार से जोड़ने के लिए कहें",
  },
  "Debit card/ Net Banking E-Nach": {
    english: "Debit card/ Net Banking E-Nach",
    hinglish: "Debit card/ Net Banking E-Nach",
    hindi: "डेबिट कार्ड / नेट बैंकिंग E-Nach",
  },
  "Aadhaar Based E-Nach": {
    english: "Aadhaar Based E-Nach",
    hinglish: "Aadhaar Based E-Nach",
    hindi: "आधार E-Nach",
  },
  "Please wait, we are reviewing the application details to assign the NBFC for this case":
    {
      english:
        "Please wait, we are reviewing the application details to assign the NBFC for this case",
      hinglish:
        "Pease wait karen, hum file check kar rahe hai NBFC assign karne ke liye",
      hindi:
        "कृपया प्रतीक्षा करें, हम एनबीएफसी नियुक्त करने के लिए  फाइल को जांच रहे है",
    },
  "You can do next steps shortly": {
    english: "You can do next steps shortly",
    hinglish: "Aap Next steps jald hi kar paynege",
    hindi: "आप अगली प्रक्रिया जल्द ही कर पाएंगे",
  },
  "Please select the person who owns the house where co-customer is living currently":
    {
      english:
        "Please select the person who owns the house where co-customer is living currently",
      hinglish: "Co-customer jis ghar me rahta hai uska maalik kon hai",
      hindi: "सह कस्टमर जिस घर में रहते है उसका मालिक कौन है ",
    },
  "Please select the person who owns the house where customer is living currently":
    {
      english:
        "Please select the person who owns the house where customer is living currently",
      hinglish: "Customer jis ghar me rahta hai uska maalik kon hai",
      hindi: "कस्टमर जिस घर में रहते है उसका मालिक कौन है ",
    },
  "Cheque details are not matching with Bank Account details, Please re-upload the correct Cheque":
    {
      english:
        "Cheque details are not matching with Bank Account details, Please re-upload the correct Cheque",
      hinglish:
        "Cheque ki details pahle di hui bank details se alag hai please sahi cheque upload karen",
      hindi:
        "चेक की डिटेल्स पहले दी गयी अकाउंट डिटेल्स से अलग है कृपया सही चेक अपलोड करें",
    },
  "OCR Failed, Please re-upload clear image of cheque": {
    english: "OCR Failed, Please re-upload clear image of cheque",
    hinglish: "Cheque upload fail ho gaya hai, kripaya clear photo daale",
    hindi: "चेक अपलोड विफल हो गया है, कृपया चेक की साफ़ फोटो पुनः अपलोड करें",
  },
  "OEM Name": {
    english: "OEM Name",
    hinglish: "OEM Name",
    hindi: "OEM नाम",
  },
  "Battery Type": {
    english: "Battery Type",
    hinglish: "Battery Type",
    hindi: "बैटरी Type",
  },
  "Hold Back": {
    english: "Hold Back",
    hinglish: "Hold Back",
    hindi: "Hold Back",
  },
  "Subvention %": {
    english: "Subvention %",
    hinglish: "Subvention %",
    hindi: "Subvention %",
  },
  "Subvention Amount": {
    english: "Subvention Amount",
    hinglish: "Subvention Amount",
    hindi: "Subvention Amount",
  },
  "GPS Charge": {
    english: "GPS Charge",
    hinglish: "GPS Charge",
    hindi: "GPS Charge",
  },
  "Loan Details": {
    english: "Loan Details",
    hinglish: "Loan Details",
    hindi: "Loan Details",
  },
  Commercials: {
    english: "Commercials",
    hinglish: "Commercials",
    hindi: "Commercials",
  },
  NA: {
    english: "NA",
    hinglish: "NA",
    hindi: "NA",
  },
  "Lithium Ion": {
    english: "Lithium Ion",
    hinglish: "Lithium Ion",
    hindi: "Lithium Ion",
  },
  "Lead Acid": {
    english: "Lead Acid",
    hinglish: "Lead Acid",
    hindi: "Lead Acid",
  },
  "Customer’s Bank does not support Aadhaar E-NACH, Please take sign for Physical NACH":
    {
      english:
        "Customer’s Bank does not support Aadhaar E-NACH, Please take sign for Physical NACH",
      hinglish:
        "Customer ka bank aadhaar E-Nach support nahi karta hai, please customer ka nach form sign karwaye",
      hindi:
        "कस्टमर का बैंक Aadhaar E-NACH को सपोर्ट नहीं करता है, कृपया कस्टमर का नेच फॉर्म पर साइन ले",
    },
  "Other Documents": {
    english: "Other Documents",
    hinglish: "Other Documents",
    hindi: "अन्य डॉक्यूमेंट",
  },
  "See more": {
    english: "See more",
    hinglish: "See more",
    hindi: "और देखें",
  },
  Collections: {
    english: "Collections",
    hinglish: "Collections",
    hindi: "कलेक्शन",
  },
  "What's New": {
    english: "What's New",
    hinglish: "What's New",
    hindi: "What's New",
  },
  "PF Rate": {
    english: "PF Rate",
    hinglish: "PF Rate",
    hindi: "PF Rate",
  },
  "PF Amount": {
    english: "PF Amount",
    hinglish: "PF Amount",
    hindi: "PF Amount",
  },
  "Not able to upload the FI because your internet is very slow.": {
    english: "Not able to upload the FI because your internet is very slow.",
    hinglish: "Internet slow hone ke karan FI upload nahi pa rahi hai. ",
    hindi: "इंटरनेट धीमा होने के कारण आपका FI अपलोड नहीं हो पा रहा है। ",
  },
  "But your FI data is saved in the app again click proceed wherever you get good internet":
    {
      english:
        "But your FI data is saved in the app again click proceed wherever you get good internet",
      hinglish:
        "Lekin aapka FI data save ho gaya hai. Jab bhi aapka internet sahi hoi jaye to proceed par click karke FI ko upload karen",
      hindi:
        "लेकिन आपका FI डेटा ऐप में सेव हो गया है, जब भी आपको अच्छा इंटरनेट मिले वहां आगे बढ़ें पर क्लिक करें आप FI अपलोड कर सकते है।",
    },
  "Please contact our support team for OEM details": {
    english: "Please contact our support team for OEM details",
    hinglish: "Humari support team ko contact karen",
    hindi: "कृपया हमारी सहायता टीम से संपर्क करें",
  },
  "Cheque OCR failed, Please re-upload clear image": {
    english: "Cheque OCR failed, Please re-upload clear image",
    hinglish: "Cheque upload fail ho gaya hai, kripaya clear photo daale",
    hindi: "चेक अपलोड विफल हो गया है, कृपया चेक की साफ़ फोटो पुनः अपलोड करें",
  },
  "Please upload correct cheque. Bank Details are not matching with the cheque details.":
    {
      english:
        "Please upload correct cheque. Bank Details are not matching with the cheque details.",
      hinglish:
        "Cheque upload fail ho gaya hai. Pahle jo bank detail di thi uss se iss cheque ki detail match nahi ho rahi hai",
      hindi:
        "चेक अपलोड विफल हो गया है। आपके द्वारा पहले दी हुई बैंक डिटेल इस चेक डिटेल से मेल नहीं खा रही हैं।",
    },
  "Cheque details are not matching with Bank Account details. Please re-upload the correct Cheque.":
    {
      english:
        "Cheque details are not matching with Bank Account details. Please re-upload the correct Cheque.",
      hinglish:
        "Cheque upload fail ho gaya hai. Pahle jo bank detail di thi uss se iss cheque ki detail match nahi ho rahi hai",
      hindi:
        "चेक अपलोड विफल हो गया है।आपके द्वारा पहले दी हुई बैंक डिटेल इस चेक डिटेल से मेल नहीं खा रही हैं।",
    },
  "Duplicate cheque found, Please re-upload other cheque": {
    english: "Duplicate cheque found, Please re-upload other cheque",
    hinglish: "Duplicate cheque mila hai, kripya sahi cheque reupload karen",
    hindi: "डुप्लीकेट चेक मिला, कृपया सही चेक पुनः अपलोड करें",
  },
  "Not able to upload the FI because your internet is very slow. But your FI data is saved in the app again click proceed wherever you get good internet":
    {
      english:
        "Not able to upload the FI because your internet is very slow. But your FI data is saved in the app again click proceed wherever you get good internet",
      hinglish:
        "Internet slow hone ke karan FI upload nahi pa rahi hai. Lekin aapka FI data save ho gaya hai. Jab bhi aapka internet sahi hoi jaye to proceed par click karke FI ko upload karen",
      hindi:
        "इंटरनेट धीमा होने के कारण आपका FI अपलोड नहीं हो पा रहा है। लेकिन आपका FI डेटा ऐप में सेव हो गया है, जब भी आपको अच्छा इंटरनेट मिले वहां आगे बढ़ें पर क्लिक करें आप FI अपलोड कर सकते है।",
    },
  "You need to upload or capture the image again": {
    english: "You need to upload or capture the image again",
    hinglish: "You need to upload or capture the image again",
    hindi: "आपको ये डॉक्यूमेंट दोबारा डालना है",
  },
  "Last 3 digit of mobile number linked with Aadhaar is": {
    english: "Last 3 digit of mobile number linked with Aadhaar is",
    hinglish: "Last 3 digit of mobile number linked with Aadhaar is",
    hindi: "आधार से लिंक नंबर के आखिरी की 3 संख्या है ",
  },
  "If this number is not available, proceed with Physical Sign": {
    english: "If this number is not available, proceed with Physical Sign",
    hinglish: "If this number is not available, proceed with Physical Sign",
    hindi: "अगर ये नंबर अभी चालु नहीं है तो फिजिकल साइन पर क्लिक करें",
  },
  "You need to upload or capture the document again": {
    english: "You need to upload or capture the document again",
    hinglish: "You need to upload or capture the image again",
    hindi: "डॉक्यूमेंट दोबारा अपलोड करें",
  },
  "Wait for the approval of document from our end": {
    english: "Wait for the approval of document from our end",
    hinglish: "Wait for the approval of document from our end",
    hindi: "टीम अभी डॉक्यूमेंट को चेक कर रही है ",
  },
};
