import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";

export default function CustomInputAutocomplete({ text, options, onChange:setVal, currentValue , style={} }) {
  useEffect(()=>{
    if(options?.length==1){
      setVal(options[0]?.split('-')[0]?.trim())
    }
  },[options])

  return (
    <Autocomplete
      sx={{ 
        "& input": {
          width: "100% !important",
          ...style
        },
      }}
      id="custom-input-demo"
      options={options}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <input placeholder={text ? text : "Select an option"} type="text" {...params.inputProps} className="form-input"/>
        </div>
      )}
      onChange={(e) => {
        setVal(e.target.innerText.split('-')[0]?.trim())
      }}
      value={(options && options?.length > 0 && (currentValue !== '')) && options?.filter(x=> {
        return x.includes(currentValue)
      })  || '' }
      defaultValue=''
    />
  );
}
