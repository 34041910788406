import { useState } from "react";
import arrow from "../../assets/reject-arrow.svg";
import mailIco from "../../assets/reject-mail-icon.svg";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CloseIcon from "@mui/icons-material/Close";

const RejectionPopup = ({ message }) => {
  const [showPopup, setShowpopup] = useState(false);
  return (
    <>
      <div
        className="reject-info-button pointer"
        onClick={() => {
          setShowpopup(!showPopup);
        }}
      >
        <img src={mailIco} /> view
      </div>
      {showPopup && (
        <div className="rejection-popup-wrapper">
          <div className="rejection-popup">
            <div className="arrow-icon">
              <img src={arrow} />
            </div>
            <div className="d-flex justify-content-end">
              <div
                style={{ fontSize: 10, color: "white", cursor: "pointer" }}
                onClick={() => {
                  setShowpopup(!setShowpopup);
                }}
              >
                <CloseIcon sx={{ color: "white !important;", fontSize:18}} />
              </div>
            </div>
            <div style={{ width: "90%", minWidth:'50px', marginTop: "-20px" }}>{message}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default RejectionPopup;
