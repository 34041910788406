import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { demoDashboardData, fetchDashboardData } from "../../api/agent";
import CountUp from "react-countup";
// import "./Home.css";
import homeIco from "../../assets/homeIco1.svg";
import WalletIco from "../../assets/wallet.svg";
import CoinIco from "../../assets/coin.svg";
import RaficiIco from "../../assets/rafiki.svg";
import AmicoIco from "../../assets/amico.svg";
import CuateIco from "../../assets/cuate.svg";
import Hand2Ico from "../../assets/hand-2.svg";
import CoinCard from "../component/CoinCard";
import { cf } from "../util/CurrencyFormatter";
// import { l } from "../../util/languageConvertor";
import { l } from "../../util/languageConvertor";
import { GlobalContext } from "../../Contexts/GlobalContext";


const DemoHome = () => {
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(false);

  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const getDashboardData = async () => {
    try {
      setLoading(true);
      let { data } = await demoDashboardData();
      setDashboardData(data);
    } catch (error) {
      enqueueSnackbar(error?.message || "Error loading dashboard data", { variant: "error" });
    }
    setLoading(false);
  };

  const handleOnClick = () => {
  }

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#F9F8FF" }} className="content-center">
        <Grid container spacing={2} className="p-3 response-wrapper">
          <Grid sx={{
            marginTop: "80px"
          }} item xs={12} sm={12} className="mb-2">
            <div className="card-new-home create-card-new" style={{ height: "160px" }}>
              <div className="d-flex justify-content-between">
                <p className="heading-new mb-0">
                  {l("Apply for Loan", lang)}
                  <p className="sub-heading-new mb-0">
                    {l("Get instant E- Rickshaw Loan", lang)}
                  </p>
                  <div>

                    <Button
                      className="btn-new mt-4 avail-button"
                      onClick={() => navigate("/create-loan/pan-upload")}
                    >
                      {l("Avail Now", lang)}
                    </Button>
                  </div>
                </p>
                <p className="desc-text" style={{ color: "#6A6A6A" }}>
                  <img src={homeIco} alt="homeIco" />
                </p>
              </div>
              <div className="button-holder"></div>
              <i></i>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} className="mb-2">
            <div className="card-2-new-home create-card-new" style={{ height: "160px" }}>
              <div className="card-2-header">
                <p className="p-no-margin">{l("Amount Credited", lang)}</p>
                <p className="p-no-margin">{cf(dashboardData?.totalLoanAmount)}</p>
              </div>
              <div className="d-flex justify-content-between card-2-main">
                <p className="heading-new mb-0">
                  <p className="sub-heading-2-new mb-0">
                    {l("Complete All the Draft Applications to earn more", lang)}
                  </p>
                  <div>
                    <Button
                      className="btn-new mt-4 instant-button"
                      onClick={() => navigate("/draft-applications", { state: { origin: "/home" } })}
                    >
                      {l("Get Instant Funds", lang)}
                    </Button>
                  </div>
                </p>
                <p className="desc-text" style={{ color: "#6A6A6A", padding: "10px 0 0 10px" }}>
                  <img src={WalletIco} alt="homeIco" />
                </p>
              </div>
              <div className="button-holder"></div>
              <i></i>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} className="mb-2">
            <div className="card-2-new-home create-card-new" style={{ height: "160px" }}>
              <div style={{ cursor: "pointer" }} className="card-2-header" onClick={() => navigate('/rewards', { state: { rewardpoints: dashboardData?.rewardPoints } })}>
                <p className="p-no-margin">{l("Reward points", lang)}</p>
                <div style={{ display: "flex", gap: "5px" }}>
                  <div><img src={CoinIco} alt="coin icon" /></div><p className="p-no-margin">{cf(dashboardData?.rewardPoints, false)}</p>
                </div>
              </div>
              <div className="d-flex justify-content-between card-2-main">
                <CoinCard
                  icon={RaficiIco}
                  coins={100}
                  cardText={l("Nach",lang)}
                  buttonText={l("Register",lang)}
                  onClick={() => navigate('/post-disbursal-documents', {
                    state: {
                      filter: 'nach',
                      origin: "/home"
                    }
                  })} />
                <CoinCard
                  icon={AmicoIco}
                  coins={50}
                  cardText={l("Upload RC",lang)}
                  buttonText={l("Upload",lang)}
                  onClick={() => navigate('/post-disbursal-documents', {
                    state: {
                      filter: 'rc',
                      origin: "/home"
                    }
                  })} />
                <CoinCard
                  icon={CuateIco}
                  coins={50}
                  cardText={l("Courier Documents",lang)}
                  buttonText={l("Upload",lang)}
                  onClick={() => navigate('/post-disbursal-documents', {
                    state: {
                      filter: 'courier',
                      origin: "/home"
                    }
                  })}
                />
              </div>
              <div className="button-holder"></div>
              <i></i>
            </div>
          </Grid>

          <Grid sx={{
            marginBottom: "80px"
          }} item xs={12} sm={12} className="">
            {/* <div className="card-new-home create-card-new" style={{ height: "160px" }}>
              <div className="d-flex justify-content-between">
                <p className="heading-new mb-0">
                  ₹ 45,537
                  <p className="sub-heading-new mb-0 pr-2">
                    Check Collections Due in Coming
                    7 days
                  </p>
                  <div>
                    <Button
                      className="btn-new mt-4 avail-button"
                      onClick={() => navigate("/collection")}
                    >
                      Check Now
                    </Button>
                  </div>
                </p>
                <p className="desc-text" style={{ color: "#6A6A6A" }}>
                  <img src={Hand2Ico} alt="hand2ico" />
                </p>
              </div>
              <div className="button-holder"></div>
              <i></i>
            </div> */}
            <div className="card-2-new-home create-card-new" style={{ height: "160px" }}>
              <div className="card-2-header">
                <p className="p-no-margin">{l("Total Amount Collected", lang)}</p>
                <p className="p-no-margin">{cf(dashboardData?.totalAmoutCollected)}</p>
              </div>
              <div className="d-flex justify-content-between card-2-main">
                {/* <p className="heading-new mb-0">
                  <p className="sub-heading-2-new mb-0">
                    {l("Collections Due in Coming 7 days", lang)}
                  </p>
                </p> */}
                <div className='d-flex align-items-center'>
                  <Button
                    className="btn-new instant-button"
                    onClick={() => navigate("/collection", { state: { origin: "/home" } })}
                  >
                    {l("Check Now", lang)}
                  </Button>
                </div>
                <p className="desc-text" style={{ color: "#6A6A6A", padding: "10px 0 0 10px" }}>
                  <img src={Hand2Ico} alt="Hand2Ico" />
                </p>
              </div>
              <div className="button-holder"></div>
              <i></i>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DemoHome;
