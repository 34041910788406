import React, { useContext, useEffect } from "react";
import { Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "../Contexts/UserContext";
import Loading from "../components/Loading";

export default function PublicRoute() {
  const { user, isLoading } = useContext(UserContext);
  const { pathname } = useLocation();
  

  if (isLoading) {
    return <Loading />;
  }

  if (user) {
    return <Navigate to="/home" state={{ from: pathname }} replace />
  } else {
    return <Outlet />;
  }
}
