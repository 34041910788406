import React, { useState, useRef, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Button, Dialog, DialogContent, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { fetchNachableBankList } from "../../api/agent";
import CheckIcon from "@mui/icons-material/Check";
import bankNachIco from "../../assets/bankNach.svg";

const CustomPaper = (props) => (
  <Paper
    {...props}
    style={{
      boxShadow: "none",
      background: "#F9F8FF",
      zIndex: 0,
      borderRadius: 0,
    }}
  />
);

const BankListComponent = ({
  open,
  handleClose,
  onChange,
  value,
  skippable = true,
}) => {
  const [openWarning, setWarning] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [selected, setSelected] = useState({});
  const optionsListRef = useRef();

  const fetchBankList = async () => {
    const { data } = await fetchNachableBankList();
    if (data?.status) {
      let bankList = data?.nachableBankList?.map((val: any) => {
        return {
          label: `${val?.nachable_bank_name}`,
          value: `${val?.nachable_bank_name}`,
        };
      });
      setBankList(bankList);
    }
    // console.log(data?.nachableBankList);
  };

  useEffect(() => {
    fetchBankList();
  }, []);

  useEffect(() => {
    if (value !== "Others") {
      setSelected({
        label: value,
        value,
      });
    } else {
      setSelected(null);
    }
  }, [value]);

  useEffect(() => {
    console.log("selected", selected);
  }, [selected]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"sm"}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <div
          style={{
            background: "#F9F8FF",
            minHeight: "300px",
            overflowX: "hidden",
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: "0 0 5px 5px",
          }}
        >
          <Autocomplete
            options={bankList}
            open={open}
            value={selected}
            PaperComponent={CustomPaper}
            forcePopupIcon={false}
            onChange={(e, value) => {
              if (value) {
                handleClose();
                setSelected(value);
                if (onChange) onChange(value);
              }
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <div
                  className="py-1"
                  style={{
                    fontWeight: option.label === selected?.label ? "bold" : "",
                  }}
                >
                  {option.label}
                  {option.label === selected?.label && (
                    <CheckIcon sx={{ ml: 2 }} />
                  )}
                </div>
              </Box>
            )}
            renderInput={(params) => (
              <div
                className="d-flex"
                style={{
                  borderBottom: "1px solid #CAC5CD",
                  alignItems: "center",
                }}
              >
                <div className="ps-3">
                  <SearchIcon sx={{ color: "#777777" }} />
                </div>
                <TextField
                  className="bank-search-text"
                  {...params}
                  placeholder="Search Nachable bank"
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                />
              </div>
            )}
            PopperComponent={(props) => (
              <div ref={optionsListRef}>{props.children}</div>
            )}
          />
        </div>
        {skippable && (
          <div style={{ width: "100%" }}>
            <Button
              className="w-100 mt-2 bank-no-btn"
              variant="contained"
              onClick={() => {
                handleClose();
                setWarning(true);
              }}
            >
              I don't have Nachable bank
            </Button>
          </div>
        )}
      </Dialog>

      <Dialog
        open={openWarning}
        className="pop-dialog-card"
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "30px",
          },
        }}
        fullWidth
        maxWidth={"xs"}
        onClose={() => setWarning(false)}
      >
        <DialogContent>
          <div className="content-center">
            <img src={bankNachIco} alt="bank icon" className="mb-2 mt-3" />
          </div>
          <p className="pop-desc text-center">
            Please ask borrower to open bank account in above mentioned list of
            bank as we required nach registration before disbursement
          </p>
        </DialogContent>

        <div
          className="content-center"
          onClick={() => {
            handleClose();
            setWarning(false)
            if (onChange) onChange({ label: "Others" });
          }}
        >
          <Button className="btn-submit">OK</Button>
        </div>
      </Dialog>
    </>
  );
};

export default BankListComponent;
