import React, { useState, useEffect } from "react";
import { CircularProgress, Typography, Box } from "@mui/material";
import Pause from "../assets/play.svg";
import Play from "../assets/pause.svg";
import useSpeak from "../hooks/useSpeak";
import { seenAppTours } from "../api/agent";

const Voiceplayer = ({ text , screenName , action }) => {

  const {
    isSupported,
    speak,
    isSpeaking,
    setIsSpeaking,
    utterance,
    handlePause,
    handlePlay,
  } = useSpeak();

  useEffect(() => {
    const handleonload = (e) => {
      localStorage.setItem("V_isReload", true);
    };

    window.addEventListener("beforeunload", handleonload);

    return () => {
      window.removeEventListener("beforeunload", handleonload);
    };
  }, []);

  const onEnds = async () => {
    try {
      await seenAppTours(screenName);
    } catch (err) {
      console.log("AppTour :-", err);
    }
  };

  useEffect(() => {
    const synthesis = window.speechSynthesis;
    console.log("Voiceplayer-useEffect :-", isSpeaking);
    speak(text, () => {
      onEnds()
      setIsSpeaking(false);
    });

    return () => {
      synthesis.cancel();
    };
  }, [text , isSpeaking]);

  // useEffect(()=>{
  //   setIsSpeaking(action)
  // },[action])


  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: 35,
        height: 35,
      }}
      onClick={() => {
        console.log("isSpeaking", isSpeaking);
        if (isSpeaking) {
          handlePause();
        } else {
          handlePlay();
        }
      }}
      onLoad={() => {
        const storedValue = localStorage.getItem("V_isReload");
        if (storedValue === "true") {
          setIsSpeaking(false);
          localStorage.removeItem("V_isReload");
        }
      }}
    >
      <img
        src={isSpeaking ? Play : Pause}
        style={{ width: isSpeaking ? 25 : 35, height: isSpeaking ? 25 : 35 }}
      />
    </Box>
  );
};

export default Voiceplayer;
