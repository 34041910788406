import { CircularProgress, Dialog, Divider, MenuItem } from "@mui/material";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { UserContext } from "../../Contexts/UserContext";
import { updateLanguage } from "../../api/agent";
import MixPanelAudit from "../../util/MixPanelAudit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { l } from "../../util/languageConvertor";

const ChangeLanguageDialog = ({ open, handleClose }) => {
  const { langState } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const [lang, setLang] = langState;
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeLang = async (selected) => {
    setLoading(selected);
    try {
      setLang(selected);
      let { data } = await updateLanguage({ language: selected });
      if (data?.status) {
        enqueueSnackbar(l("Language Updated Successfully", selected), {
          variant: "success",
        });
        MixPanelAudit.intercept("Language Change", {
          language: selected,
          distinct_id: user?.mobile_number[0],
          agent_name: user?.name,
        });
      }
    } catch (error) {
      navigate(
        error?.response?.data?.message ||
          "Something went wrong. You can change language Later"
      );
    }
    setLoading(null);
    handleClose();
  };

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <div>
          <MenuItem onClick={() => handleChangeLang("english")}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                color: "#573A7A",
              }}
            >
              English{" "}
              <span
                style={{
                  color: lang === "english" ? "#573A7A" : "#AEAEAE",
                }}
              >
                {loading === "english" ? (
                  <CircularProgress
                    color="secondary"
                    size={25}
                    sx={{ mr: 0.5 }}
                  />
                ) : (
                  <CheckCircleIcon sx={{ fontSize: 30 }} />
                )}
              </span>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleChangeLang("hindi")}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                color: "#573A7A",
              }}
            >
              हिंदी{" "}
              <span
                style={{
                  color: lang === "hindi" ? "#573A7A" : "#AEAEAE",
                }}
              >
                {loading === "hindi" ? (
                  <CircularProgress
                    color="secondary"
                    size={25}
                    sx={{ mr: 0.5 }}
                  />
                ) : (
                  <CheckCircleIcon sx={{ fontSize: 30 }} />
                )}
              </span>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleChangeLang("hinglish")}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                color: "#573A7A",
              }}
            >
              Hinglish{" "}
              <span
                style={{
                  color: lang === "hinglish" ? "#573A7A" : "#AEAEAE",
                }}
              >
                {loading === "hinglish" ? (
                  <CircularProgress
                    color="secondary"
                    size={25}
                    sx={{ mr: 0.5 }}
                  />
                ) : (
                  <CheckCircleIcon sx={{ fontSize: 30 }} />
                )}
              </span>
            </div>
          </MenuItem>
        </div>
      </Dialog>
    </div>
  );
};

export default ChangeLanguageDialog;
