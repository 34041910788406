import React, { useContext } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import esignIcon from "../../../../assets/eSign.svg";
import manualIco from "../../../../assets/manualIco.svg";
import infoIco from "../../../../assets/infoIco.svg";
import { useState } from "react";
import EsignDailog from "./EsignDailog";
import ButtonComp from "../../../../components/V3/ButtonComponent";
import ManualContent from "./ManualDialog";
import { useEffect } from "react";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import arrowIco from "../../../../assets/bottomArrow.svg";
import approvedIco from "../../../../assets/approved.svg";
import uploadedIco from "../../../../assets/uploaded.svg";
import rejectedIco from "../../../../assets/rejectedIco.svg";
import MixPanelAudit from "../../../../util/MixPanelAudit";
import { l } from "../../../../util/languageConvertor";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { UserContext } from "../../../../Contexts/UserContext";
const InititialMenu = ({
  setContent,
  handleClose,
  esignSupported,
  agentName,
  applicationID,
}) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        Choose your sign method
        <div
          style={{ position: "absolute", top: 0, right: 5, cursor: "pointer" }}
        >
          <CloseIcon className="pointer" onClick={() => handleClose()} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ButtonComp
              disabled={!esignSupported}
              icon={esignIcon}
              text={"E-sign"}
              action={() => {
                setContent("esign");
                MixPanelAudit.intercept("loan_agreement_esign_click", {
                  agent_name: agentName,
                  application_id: applicationID,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ButtonComp
              icon={manualIco}
              text={"Manual"}
              action={() => {
                setContent("manual");
                MixPanelAudit.intercept("loan_agreement_manual_click", {
                  agent_name: agentName,
                  application_id: applicationID,
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

const LoanAgreementCard = ({ icon }) => {
  const [content, setContent] = useState("initial");
  const [open, setOpen] = useState(false);
  const { journeyState } = useContext(LoanContext);
  const { langState } = useContext(GlobalContext);
  const [journeyData, setJourneyData] = journeyState;
  const [lang, setLang] = langState;
  const { user } = useContext(UserContext);
  const [status, setStatus] = useState("pending");
  const [eSignStatus, setESignStatus] = useState("pending");
  const [manualSignStatus, setManualSignStatus] = useState("pending");

  // const checkStatus = () => {
  // if (journeyData?.esign_status?.customer?.signed) {
  //   if (journeyData?.co_customer_exist) {
  //     if (journeyData?.esign_status?.co_customer?.signed) {
  //         setStatus("completed");
  //       } else {
  //         setStatus("partial");
  //       }
  //     } else {
  //       setStatus("completed");
  //     }
  //   } else {
  //     if (journeyData?.documents?.loan_agreement?.length > 0) {
  //       if (journeyData?.documents?.loan_agreement?.[0]?.meta_page_document) {
  //         if (journeyData?.documents?.loan_agreement?.[0]?.completed) {
  //           setStatus("completed");
  //         }
  //       } else {
  //         switch (journeyData?.documents?.loan_agreement?.[0]?.isApproved) {
  //           case true:
  //             setStatus("completed");
  //             break;
  //           case false:
  //             setStatus("rejected");
  //             break;
  //           case null:
  //             setStatus("uploaded");
  //             break;
  //           default:
  //             setStatus("pending");
  //         }
  //       }
  //     } else {
  //       setStatus("pending");
  //     }
  //   }
  // };

  const checkIsApproved = () => {
    let isApproved;
    let manualIsApproved =
      journeyData?.documents?.loan_agreement?.[0]?.isApproved;
    let eSignIsApproved;
    if (!manualIsApproved) {
      if (journeyData?.esign_status?.customer?.signed) {
        if (journeyData?.co_customer_exist) {
          if (journeyData?.esign_status?.co_customer?.signed) {
            eSignIsApproved = true;
          }
        } else {
          eSignIsApproved = true;
        }
      }
    }
    if (eSignIsApproved || manualIsApproved) isApproved = true;
    return isApproved;
  };

  const checkManualStatus = () => {
    const isApproved = journeyData?.documents?.loan_agreement?.[0]?.isApproved;
    switch (isApproved) {
      case false:
        setManualSignStatus("rejected");
        return "rejected";
      case null:
        if (journeyData?.documents?.loan_agreement?.[0]?.meta_page_document) {
          if (journeyData?.documents?.loan_agreement?.[0]?.completed) {
            setManualSignStatus("uploaded");
            return "uploaded";
          }
        } else {
          setManualSignStatus("uploaded");
          return "uploaded";
        }
        break;
      default:
        setManualSignStatus("pending");
        return "pending";
    }
  };

  const checkESignStatus = () => {
    if (journeyData?.esign_status?.customer?.signed) {
      if (journeyData?.co_customer_exist) {
        if (!journeyData?.esign_status?.co_customer?.signed) {
          setESignStatus("partial");
          return "partial";
        }
      }
    } else {
      setESignStatus("pending");
      return "pending";
    }
  };

  const checkStatus = () => {
    if (checkIsApproved()) {
      setStatus("completed");
      setESignStatus("pending");
      return setManualSignStatus("completed");
    } else {
      setStatus("pending");
    }
    checkManualStatus();
    checkESignStatus();
  };

  const handleClose = () => {
    // console.log(
    //   "journeyData?.nbfcData :-",
    //   journeyData?.nbfcData?.loan_agreement,
    //   content
    // );
    setOpen(false);
    if (journeyData?.nbfcData?.loan_agreement !== "manual")
      setContent("initial");
  };

  // const checkStatus = () => {
  //   if (journeyData?.documents?.loan_agreement?.length > 0) {
  //     if (journeyData?.documents?.loan_agreement?.[0]?.meta_page_document) {
  //       if (journeyData?.documents?.loan_agreement?.[0]?.completed) {
  //         if (journeyData?.documents?.loan_agreement?.[0]?.isApproved) {
  //           setStatus("completed");
  //         } else {
  //           setStatus("uploaded");
  //         }
  //       }
  //     } else {
  //       switch (journeyData?.documents?.loan_agreement?.[0]?.isApproved) {
  //         case true:
  //           setStatus("completed");
  //           break;
  //         case false:
  //           setStatus("rejected");
  //           break;
  //         case null:
  //           setStatus("uploaded");
  //           break;
  //         default:
  //           setStatus("pending");
  //       }
  //     }
  //   } else {
  //     if (journeyData?.esign_status?.customer?.signed) {
  //       if (journeyData?.co_customer_exist) {
  //         if (journeyData?.esign_status?.co_customer?.signed) {
  //           setStatus("completed");
  //         } else {
  //           setStatus("partial");
  //         }
  //       } else {
  //         setStatus("completed");
  //       }
  //     } else {
  //       setStatus("pending");
  //     }
  //   }
  // };

  useEffect(() => {
    // console.log("Effect 1");
    checkStatus();
    if (journeyData?.nbfcData?.loan_agreement === "manual")
      setContent("manual");
  }, [journeyData]);


  return (
    <>
      <div
        style={{ position: "relative", opacity: 1 }}
        onClick={() => {
          if (status !== "completed") {
            setOpen(true);
            MixPanelAudit.intercept("loan_agreement_generate_click", {
              agent_name: user?.name,
              application_id: journeyData?.application_id,
            });
          }
        }}
      >
        <div className="doc-status-holder pointer">
          {manualSignStatus === "completed" && (
            <img src={approvedIco} alt="" width={40} height={40} />
          )}
          {manualSignStatus === "rejected" && (
            <img src={rejectedIco} alt="" width={40} height={40} />
          )}
          {manualSignStatus === "uploaded" && (
            <img src={uploadedIco} alt="" width={40} height={40} />
          )}
        </div>
        {eSignStatus === "partial" && (
          <div
            style={
              eSignStatus === "partial" && manualSignStatus === "uploaded"
                ? {
                    width: "80%",
                    position: "absolute",
                    zIndex: 1000,
                    top: -10,
                    background: "#f44949",
                    height: 25,
                    borderRadius: '5px',
                    color: "white",
                    fontSize: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : {
                    width: "100%",
                    position: "absolute",
                    zIndex: 1000,
                    top: -12,
                    background: "#f44949",
                    height: 25,
                    borderRadius: "10px 10px 0 0",
                    color: "white",
                    fontSize: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
            }
          >
            {l("Co-Borrower pending", lang)}
            {eSignStatus === "partial" && manualSignStatus === "uploaded" ? (
              <></>
            ) : (
              <img
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: -15,
                }}
                height={20}
                src={arrowIco}
              ></img>
            )}
          </div>
        )}
        <div
          className={`documents-card-v3 pointer`}
          style={{
            height: 130,
          }}
          onClick={() => {}}
        >
          <div>
            <p className={`mb-2 text-center`}>
              <img
                src={icon}
                alt=""
                width={60}
                height={60}
                style={{ objectFit: "contain" }}
              />
            </p>
            <p className="heading mb-0">
              {l(`Loan Agreement`, lang)} <span className="mand-star">*</span>
            </p>
            {/* {status !== "completed" && (
              <div className={`w-100 mt-3 content-center`}>
                <Button
                  className="upload-btn"
                  style={{
                    height: 25,
                  }}
                  onClick={() => {
                    setOpen(true);
                    MixPanelAudit.intercept("loan_agreement_generate_click", {
                      agent_name: user?.name,
                      application_id: journeyData?.application_id,
                    });
                  }}
                >
                  {l("Generate", lang)}
                </Button>
              </div>
            )} */}
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
      >
        {content === "initial" && (
          <InititialMenu
            setContent={setContent}
            handleClose={handleClose}
            esignSupported={
              journeyData?.nbfcData?.other_details?.esign_supported || false
            }
            agentName={user?.name}
            applicationID={journeyData?.application_id}
          />
        )}
        {content === "esign" && (
          <EsignDailog setContent={setContent} handleClose={handleClose} />
        )}
        {content === "manual" && (
          <ManualContent
            setContent={setContent}
            handleClose={handleClose}
            defaultContentType={journeyData?.nbfcData?.loan_agreement}
          />
        )}
      </Dialog>
    </>
  );
};

export default LoanAgreementCard;
