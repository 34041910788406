import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Buffer } from "buffer";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraIcon from "@mui/icons-material/Camera";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { FiCamera, FiImage, FiX } from "react-icons/fi";
import { GrGallery } from "react-icons/gr";

import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Webcam from "react-webcam";
import { compressImage, getBase64 } from "../../util/convertFileBase";
import { CircularProgress, Grid } from "@mui/material";
import ImageCropper from "../Crop";
import PermissionModal from "../PermissionModal";
import usePermission from "../../hooks/usePermission";
import { useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import GridViewIcon from "@mui/icons-material/GridView";
import uploadedIco from "../../assets/uploaded.svg";
import rejectedIco from "../../assets/rejectedIco.svg";
import approvedIco from "../../assets/approved.svg";
import downloadIco from "../../assets/downloadAgree.svg";
import uploadIco from "../../assets/uploadAgree.svg";
import closeBtn from "../../assets/closeBtn.svg";

import LoadingOver from "../LoadingOver";

import ButtonComp from "../V3/ButtonComponent";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SelectUpload({
  setFile,
  error,
  errorTxt,
  onSelect,
  text,
  tempState,
  disabled,
  openMult,
  icon,
  status,
  isRequired,
  isLoading,
  isUpload,
  extraHeight,
  manual,
  handleDownload,
}) {
  const { showPermissionState } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isSelfie, setSelfie] = useState(false);
  const [captureImage, setImage] = useState("");
  const [confirmFilename, setConfirmFilename] = useState("");
  const [isInput, setInput] = useState(false);
  const [pdfFile, setPdf] = useState("");
  const [loading, setLoading] = React.useState(true);
  const { permissions, getUserLocation } = usePermission();
  const [showPermission, setShowPermission] = showPermissionState;
  const [showManual, setShowManual] = useState(false);
  const fileInput = useRef();
  const sliderBottom = useRef();
  const backdrop = useRef();

  const [cropImgTemp, setTempCrop] = useState("");
  const [croppedImg, setCropImg] = useState("");

  const handleClickOpen = () => {
    // setOpen(true);
    setShowPermission((st) => ({ ...setConfirmFilename, state: false }));
    sliderBottom.current.classList.remove("close");
    backdrop.current.classList.remove("hide");
  };
  const handleClose = () => {
    // setOpen(false);
    setTimeout(() => {
      sliderBottom.current.classList.add("close");
      backdrop.current.classList.add("hide");
    }, 100);
  };

  const handleRecapture = () => {
    fileInput.current.value = "";
    setConfirmFilename("");
    setInput(false);
    setPdf("");
    setImage("");
    handleClickOpen();
    setFile && setFile("");
  };

  useEffect(() => {
    if (!open) {
      setIsCameraOpen(false);
      setImage("");
    }
  }, [open]);

  useEffect(() => {
    if (croppedImg !== "") {
      setFile && setFile(croppedImg);
    }
  }, [croppedImg]);

  useEffect(() => {
    if (pdfFile !== "") {
      setFile && setFile(pdfFile);
    }
  }, [pdfFile]);

  const handleUserMedia = () => setTimeout(() => setLoading(false), 1000);


  return (
    <>
      <>
        <div style={{ position: "relative" }}>
          {/* {status!==undefined && ( */}
          {tempState !== "" && (
            <div className="doc-status-holder pointer">
              <img
                src={(() => {
                  switch (status) {
                    case true:
                      return approvedIco;
                    case false:
                      return rejectedIco;
                    case null:
                      return uploadedIco;
                    default:
                      return uploadedIco;
                  }
                })()}
                alt=""
                width={40}
                height={40}
              />
            </div>
          )}
          <>
            <div
              className="documents-card-v3 pointer"
              style={{
                height: extraHeight,
              }}
              onClick={() => {
                if (!status) {
                  if (manual) {
                    setShowManual(true);
                  } else {
                    if (isUpload) {
                      fileInput.current.click();
                      setInput(true);
                    } else {
                      handleClickOpen();
                    }
                  }
                }
              }}
            >
              <div>
                <p className="mb-2 text-center">
                  <img
                    src={icon}
                    alt=""
                    width={60}
                    height={60}
                    style={{ objectFit: "contain" }}
                  />
                </p>
                <p className="heading mb-0">
                  {text} {isRequired && <span className="mand-star">*</span>}
                </p>
              </div>
            </div>
            {isLoading && manual === false && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 500,
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  background: "rgba(0, 0, 0, 0.31)",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  borderRadius: 20,
                }}
              >
                <CircularProgress sx={{ color: "white" }} />
              </div>
            )}
          </>
        </div>
      </>

      <div
        className="slider-backdrop hide"
        ref={backdrop}
        onClick={() => {
          handleClose();
        }}
      ></div>
      <div ref={sliderBottom} class="slider close">
        <Grid container spacing={2}>
          <Grid className="content-center" item xs={6} sm={6}>
            <Button
              className="bottom-menu-button"
              onClick={() => {
                handleClose();
                openMult();
                // setIsCameraOpen(true);
              }}
            >
              <div>
                <GridViewIcon
                  className="bottom-menu-ico"
                  sx={{ fontSize: 40 }}
                />
                <p className="w-100">Multi-upload</p>
              </div>
            </Button>
          </Grid>
          <Grid className="content-center" item xs={6} sm={6}>
            <Button
              className="bottom-menu-button"
              onClick={() => {
                fileInput.current.click();
                setInput(true);
              }}
            >
              <div>
                <FiImage className="bottom-menu-ico" />
                <p className="w-100">Gallery</p>
              </div>
            </Button>
            <input
              ref={fileInput}
              type="file"
              class="custom-file-input"
              accept="application/pdf , image/png, image/jpg, image/jpeg"
              onChange={async (e) => {
                setCropImg("");
                onSelect && onSelect();
                if (e.target.files[0].type.match("application/pdf")) {
                  setInput(true);
                  setPdf(await getBase64(e.target.files[0]));
                  setConfirmFilename(e.target.files[0].name);
                } else if (e.target.files[0]["type"].split("/")[0] == "image") {
                  if (e.target.files[0].type.match("image/svg")) {
                    alert("Not supported file");
                    return false;
                  }
                  setInput(false);
                  setImage(
                    await getBase64(await compressImage(e.target.files[0]))
                  );
                } else {
                  alert("Pdf/Image only");
                  handleRecapture();
                }
                e.target.value = "";
                handleClose();
              }}
              hidden
            />
          </Grid>

          {/* <Grid className="content-center" item xs={4} sm={4}>
            <Button
              className="bottom-menu-button"
              onClick={() => handleClose()}
            >
              <div>
                <FiX className="bottom-menu-ico" />
                <p className="w-100">Close</p>
              </div>
            </Button>
          </Grid> */}
        </Grid>
      </div>

      {/* /////// CAMERA ///// */}

      {isCameraOpen && (
        <div className="camera-screen">
          <CloseIcon
            className="camera-close pointer"
            onClick={() => {
              setIsCameraOpen(false);
            }}
          />
          <div className="camera-loading">
            <div className="content-center">
              <CircularProgress />
            </div>
            Camera Loading
          </div>
          <Webcam
            audio={false}
            className={"webcamera"}
            screenshotQuality={1}
            screenshotFormat="image/jpeg"
            width={"100%"}
            height={"100%"}
            // onUserMedia={handleUserMedia}
            videoConstraints={{
              width: "1000",
              height: "1000",
              facingMode: `${isSelfie ? "user" : "environment"}`,
            }}
          >
            {({ getScreenshot }) => (
              <>
                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}

                <div className={`action-buttons-holder`}>
                  <div className="responsive-div">
                    <div className="action-buttons w-100">
                      <span
                        className="cam-flip-btn-v2"
                        onClick={() => {
                          setSelfie(!isSelfie);
                        }}
                      >
                        <FlipCameraIosIcon />
                      </span>

                      <button
                        className="capture-btn"
                        onClick={() => {
                          const imageSrc = getScreenshot();
                          setImage(imageSrc);
                          setIsCameraOpen(false);
                          onSelect && onSelect();
                        }}
                      >
                        <CameraIcon className="me-2" />
                        Capture photo
                      </button>
                    </div>
                  </div>
                </div>

                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
              </>
            )}
          </Webcam>
          {/* 
          {permissions.camera === "PROMPT" && (
            <PermissionModal initial={true} />
          )} */}
        </div>
      )}

      {captureImage !== "" && !isInput && (
        <div className="crop-screen">
          <CloseIcon
            className="crop-close pointer"
            onClick={() => {
              setImage("");
            }}
          />
          <div className="content-center">
            <ImageCropper
              onImageCropped={(result) => {
                setTempCrop(result);
              }}
              imageToCrop={captureImage}
            />
          </div>
          <div className={`action-buttons-holder`}>
            <div className="responsive-div">
              <div className="action-buttons w-100">
                <button
                  className="capture-btn"
                  onClick={() => {
                    setCropImg(cropImgTemp);
                    setConfirmFilename("capture.jpg");
                    setImage("");
                  }}
                >
                  <CameraIcon className="me-2" />
                  Crop and Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showManual && (
        <div className="manualPopup">
          <div className="manualPopupContiner">
            <CloseIcon
              onClick={() => {
                setShowManual(false);
              }}
            />

            <Grid container spacing={2} className="mt-2">
              <Grid item xs={6}>
                <ButtonComp
                  icon={downloadIco}
                  text={"Download"}
                  action={() => {
                    handleDownload();
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <ButtonComp
                  icon={uploadIco}
                  text={"Upload"}
                  status={
                    tempState !== "" && (
                      <div className="doc-status-holder pointer">
                        <img
                          src={(() => {
                            switch (status) {
                              case true:
                                return approvedIco;
                              case false:
                                return rejectedIco;
                              case null:
                                return uploadedIco;
                              default:
                                return uploadedIco;
                            }
                          })()}
                          alt=""
                          width={40}
                          height={40}
                        />
                      </div>
                    )
                  }
                  action={() => {
                    handleClickOpen();
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {isLoading && manual && <LoadingOver text={"Downloading"} />}
    </>
  );
}
