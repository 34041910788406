import {
  BottomNavigation,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuAppBar from "../../../components/Appbar";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import agreeImg from "../../../assets/loan-agree.svg";
import { acceptLoanOffer, updateLoanData } from "../../../api/agent";
import useApplication from "../../../hooks/useApplication";
import moment from "moment";
import { l } from "../../../util/languageConvertor";
import MixPanelAudit from "../../../util/MixPanelAudit";

const LoanSummary = () => {
  const { bottomNav, otpState, langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [otpData, setOtpData] = otpState;

  const [isLoading, setLoading] = useState(false);
  const [fieldErrors, setErrors] = useState({ dob: "" });
  const [installment, setInstallment] = useState({
    emi: 0,
    edi: 0,
  });

  const { application_id, source } = useParams();
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    setLoading(true);
    MixPanelAudit.intercept('loan_summary_proceed', { application_id });
    try {
      let { data } = await acceptLoanOffer(application_id);
      if (data?.status) {
        navigate(`/create-loan/documents-upload/${application_id}`);
      }
    } catch (err) {
      enqueueSnackbar(l(err?.response?.data?.message, lang), {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const fc = (val) => {
    return Number(val).toLocaleString("en-IN");
  };

  const { getApplicationDetails } = useApplication();

  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    let val =
      (Number(journeyData?.loan_amount) *
        (Number(journeyData.interest_rate) / 100) *
        Number(journeyData?.tenure)) /
      12;

    let startDate = moment(new Date());
    let endDate = moment(new Date()).add(Number(journeyData?.tenure), "months");

    let ediDays = moment(endDate).diff(moment(startDate), "days");

    let numerator =
      Number(journeyData?.loan_amount) +
      Number(journeyData?.loan_amount) *
        (Number(journeyData?.interest_rate) / 100) *
        (Number(journeyData?.tenure) / 12);

    // console.log(numerator);
    setInstallment({
      edi: (numerator / ediDays)?.toFixed(2),
      emi: (numerator / Number(journeyData?.tenure))?.toFixed(2),
    });
  }, [journeyData]);

  useEffect(() => {
    window.onpopstate = () => {
      if (source) {
        navigate("/create-loan/loan-status/" + application_id);
      } else {
        navigate("/draft-applications");
      }
    };
  });

  const batteryType_formater = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Box className="kyc-wrapper w-100" sx={{ pb: 7, pt: 7 }}>
      <MenuAppBar
        home={false}
        text={l("Loan Summary", lang)}
        action={() => {
          if (source) {
            navigate("/create-loan/loan-status/" + application_id);
          } else {
            navigate("/draft-applications");
          }
        }}
      />
      <CssBaseline />
      <div className="main-wrapper">
        <Grid container spacing={2} className="pb-5">
          <Grid item xs={12} sm={12}>
            <div className="loan-summary mt-4">
              <img className="agree-img" src={agreeImg} alt="" />
              <p className="head"></p>
              <div class="d-flex justify-content-between mb-2 pt-4">
                <div className="key">{l("Vehicle Model", lang)}</div>
                <div className="value">{journeyData?.vehicle_model}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div className="key">{l("Battery Brand", lang)}</div>
                <div className="value"> {journeyData?.battery_brand}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div className="key">{l("Battery Type", lang)}</div>
                <div className="value">
                  {" "}
                  {batteryType_formater(journeyData?.battery_name)}
                </div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div className="key">{l("Battery AH", lang)}</div>
                <div className="value"> {journeyData?.battery_ah}</div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div className="key">{l("Vehicle Price On road", lang)}</div>
                <div className="value">
                  ₹{fc(journeyData?.vehicle_price_on_road)}
                </div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div className="key">
                  {l("Vehicle Price Ex(showroom)", lang)}
                </div>
                <div className="value">
                  {" "}
                  ₹{fc(journeyData?.vehicle_price_ex)}
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <div className="loan-summary mt-2">
              <div class="d-flex justify-content-between mb-3 pt-3">
                <div className="key">{l("Loan Amount", lang)}</div>
                <div className="value">₹{fc(journeyData?.loan_amount)}</div>
              </div>
              <div class="d-flex justify-content-between mb-3">
                <div className="key">{l("Down Payment", lang)}</div>
                <div className="value">₹{fc(journeyData?.down_payment)}</div>
              </div>
              <div class="d-flex justify-content-between mb-3">
                <div className="key">{l("Interest Rate", lang)}</div>
                <div className="value">{fc(journeyData?.interest_rate)} %</div>
              </div>
              <div class="d-flex justify-content-between mb-3">
                <div className="key">{l("EMI Amount", lang)}</div>
                <div className="value">₹{fc(installment?.emi)}</div>
              </div>
              <div class="d-flex justify-content-between mb-3">
                <div className="key">{l("Loan Tenure (In Months)", lang)}</div>
                <div className="value">{journeyData?.tenure} Months</div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <div className="loan-summary mt-2">
              <div class="d-flex justify-content-between mb-3">
                <div className="key">{l("PF Rate", lang)}</div>
                <div className="value">
                {journeyData?.processing_charge_rate ? `${fc(journeyData?.processing_charge_rate)} %` : 'N/A'}
                </div>
              </div>

              <div class="d-flex justify-content-between mb-3">
                <div className="key">{l("Processing Fees", lang)}</div>
                <div className="value">
                {journeyData?.processing_charge_amount ? `₹ ${fc(journeyData?.processing_charge_amount)}` : 'N/A'}
                </div>
              </div>

              <div class="d-flex justify-content-between mb-3">
                <div className="key">{l("Hold Back", lang)}</div>
                <div className="value">
                {journeyData?.holdback_amount ? `₹ ${fc(journeyData?.holdback_amount)}` : 'NA'}
                </div>
              </div>

              <div class="d-flex justify-content-between mb-3">
                <div className="key">{l("Subvention %", lang)}</div>
                <div className="value">
                {journeyData?.subvention_amount ? `${Number((journeyData?.subvention_amount / journeyData?.loan_amount) * 100).toFixed(1)} %` : 'NA'}
                </div>
              </div>

              <div class="d-flex justify-content-between mb-3">
                <div className="key">{l("Subvention Amount", lang)}</div>
                <div className="value">
                {journeyData?.subvention_amount ? `₹ ${fc(journeyData?.subvention_amount)}` : 'NA'}
                </div>
              </div>

              <div class="d-flex justify-content-between mb-3">
                <div className="key">{l("GPS Charge", lang)}</div>
                <div className="value">
                {journeyData?.gps_charges ? `₹ ${fc(journeyData?.gps_charges)}` : 'NA'}
                </div>
              </div>
            </div>
          </Grid>

        </Grid>
      </div>

      {!source && (
        <Paper className={`paper-bottom`} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    {l("Loading", lang)}
                  </>
                ) : (
                  l("Proceed", lang)
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      )}
    </Box>
  );
};

export default LoanSummary;
