import React, { forwardRef, useEffect, useContext } from "react";
import {
  adhaarMandate,
  createMandate,
  updateEnachStatus,
} from "../../../../api/agent";
import loadingGif from "../../../../assets/loading.gif";
import digioPic from "../../../../assets/digio.png";
import "../../../../util/digio";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { useState } from "react";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import VerifiedIcon from "@mui/icons-material/Verified";
import { environment } from "../../../../config";
import { l } from "../../../../util/languageConvertor";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { useLocation } from "react-router-dom";

const ENach = forwardRef(({}, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const { journeyState } = useContext(LoanContext);
  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [journeyData, setJourneyData] = journeyState;
  const [enachStatus, setEnachStatus] = useState("pending");

  const { application_id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const digioSubmit = async (mandate_id, customer_identifier) => {
    var options = {
      //default environment staging (sandbox)
      //for production include: “environment” : “production”; here
      callback: async function (t) {
        if (t.error_code != undefined) {
          // console.log(t);
          let { data } = await updateEnachStatus(application_id, false);
          setJourneyData({ ...journeyData, enach_status: false });
        } else {
          // console.log(t);
          let { data } = await updateEnachStatus(application_id, true);
          setJourneyData({ ...journeyData, enach_status: true });
          enqueueSnackbar(l("E-Mandate Successfull!", lang), {
            variant: "success",
          });
          // navigate()
        }
      },
      logo: "https://www.embifi.in/static/media/white.098f96804ded895bdf15.png",
      is_iframe: true, //open in iframe,
      dg_preferred_auth_type: "debit", // debit or net_banking
    };

    if (environment === "production") {
      // console.log("Production Call");
      options["environment"] = "production";
    }

    var digio = new window.Digio(options);
    digio.init();
    digio.submit(mandate_id, customer_identifier);
  };

  const initiate = async () => {
    if (state.eNach === "api") {
      try {
        let response = await createMandate(application_id);
        //
        const { data } = response;
        digioSubmit(data.data.mandate_id, journeyData?.mobile_number);
      } catch (error) {
        // setStatus("failed")
        // console.log(error);

        enqueueSnackbar(l("Failed to create mandate", lang), {
          variant: "error",
        });
        navigate(`/create-loan/documents-upload/${application_id}/1`);
      }
    } else if (state.eNach === "eAdhaar") {
      // console.log("hdhh", 1)
      try {
        let response = await adhaarMandate(application_id);
        //
        const { data } = response;

        digioSubmit(data.data.mandate_id, journeyData?.mobile_number);
      } catch (error) {
        if (error?.response?.data?.message === "Destination Bank Not Active") {
          setJourneyData({ ...journeyData, bank_not_supported: true });
        } else {
          enqueueSnackbar(l("Failed to create mandate", lang), {
            variant: "error",
          });
        }
        navigate(`/create-loan/documents-upload/${application_id}/1`);
      }
    }
  };

  useEffect(() => {
    // console.log(
    //   journeyData?.mobile_number !== "" && journeyData?.enach_status === null
    // );
    if (
      journeyData?.mobile_number !== "" &&
      journeyData?.enach_status === null
    ) {
      initiate();
    } else if (journeyData?.mobile_number === "") {
      enqueueSnackbar(l("Mobile Number is missing", lang), {
        variant: "error",
      });
      navigate(`/create-loan/documents-upload/${application_id}/1`);
    }
  }, [journeyData?.mobile_number]);

  const { getApplicationDetails, getRealStatus } = useApplication();
  useEffect(() => {
    // console.log(getRealStatus(journeyData?.enach_status));
    setEnachStatus(getRealStatus(journeyData?.enach_status));
  }, [journeyData?.enach_status]);

  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  return (
    <>
      {enachStatus === "pending" && (
        <div className="mt-5">
          <p className="text-center" style={{ marginTop: "200px" }}>
            <img src={loadingGif} alt="" width={50} />
          </p>
          <p
            className="text-center"
            style={{ color: "#573A7A", fontSize: "30px" }}
          >
            Creating Mandate
          </p>
          <p className="text-center">
            Powered by <img src={digioPic} width="50"></img>
          </p>
        </div>
      )}

      {enachStatus === "unsuccessfull" && (
        <div className="mt-5">
          <p className="text-center" style={{ marginTop: "120px" }}>
            {/* <img src={loadingGif} alt="" width={50} /> */}
            <DoDisturbIcon style={{ fontSize: "120px", color: "red" }} />
          </p>
          <p
            className="text-center"
            style={{ color: "#573A7A", fontSize: "30px" }}
          >
            Failed
          </p>

          <div className="my-3">
            <Button
              onClick={() => {
                setJourneyData({ ...journeyData, enach_status: null });
                initiate();
              }}
            >
              Try Again
            </Button>
            <Button
              onClick={() => {
                navigate(`/create-loan/documents-upload/${application_id}/1`);
              }}
            >
              Go back
            </Button>
          </div>
          <p className="text-center">
            Powered by <img src={digioPic} width="50"></img>
          </p>
        </div>
      )}

      {enachStatus === "successfull" && (
        <div className="mt-5">
          <p className="text-center" style={{ marginTop: "120px" }}>
            {/* <img src={loadingGif} alt="" width={50} /> */}
            <VerifiedIcon style={{ fontSize: "120px", color: "green" }} />
          </p>
          <p
            className="text-center"
            style={{ color: "#573A7A", fontSize: "30px" }}
          >
            Successfull
          </p>

          <div className="my-3 content-center">
            <Button
              onClick={() => {
                navigate(`/create-loan/documents-upload/${application_id}/1`);
              }}
            >
              Go back
            </Button>
          </div>
          <p className="text-center">
            Powered by <img src={digioPic} width="50"></img>
          </p>
        </div>
      )}
    </>
  );
});

export default ENach;
