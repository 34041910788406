import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
// import CssBaseline from "@mui/material/CssBaseline";
// import BottomNavigation from "@mui/material/BottomNavigation";
// import BottomNavigationAction from "@mui/material/BottomNavigationAction";
// import RestoreIcon from "@mui/icons-material/Restore";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ArchiveIcon from "@mui/icons-material/Archive";
// import Paper from "@mui/material/Paper";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
// import HomeIcon from "@mui/icons-material/Home";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import { GiReceiveMoney } from "react-icons/gi";
// import { AiOutlineHome } from "react-icons/ai";
// import { CgProfile } from "react-icons/cg";
// import MenuAppBar from "../../components/Appbar";
// import Home from "./Home";
// import KycPop from "../../components/KycPop";
// import { UserContext } from "../../Contexts/UserContext";
// import Profile from "./Profile";
// import LoanScreen from "./Loan";
import {
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import {
  fetchAllApplications,
  fetchCompletedApplications,
  fetchDraftApplications,
  fetchRecentApplications,
  getCustomerData,
  searchApplication,
} from "../../api";
import moment from "moment";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { CustomerContext } from "../../Contexts/CustomerContext";
// import SearchIcon from "@mui/icons-material/Search";
// import emptyIco from "../../assets/empty.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import SelectCom from "../../components/Select";
import profile_icon_1 from "../../assets/profile_icon_1.svg";
// import profile_icon_2 from "../../assets/profile_icon_2.svg";
// import profile_icon_3 from "../../assets/profile_icon_3.svg";
// import profile_icon_4 from "../../assets/profile_icon_4.svg";
// import camera_icon from "../../assets/camera_icon.svg";
import useApplication from "../../hooks/useApplication";
import { fetchApplicationData } from "../../api/agent";
import { l } from "../../util/languageConvertor";
// import { LoanContext } from "../../Contexts/LoanJourneyContext";





export default function BorrowerProfile() {
  const location = useLocation();
  const ref = useRef(null);
  const selectRef = useRef(null);
  const [borrower, setBorrower] = useState({});
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [status, setStatus] = useState("All");
  const [searchTxt, setSearchTxt] = useState("");
  const [letSearch, setLetSearch] = useState(false);
  const [time, setTime] = useState("Any time");
  const [filter, setFilter] = useState("All Applications");
  const [isDownloaded, setDownloaded] = useState(false);

  const navigate = useNavigate();

  const { showPermissionState, applicationState, pathState, langState } =
    useContext(GlobalContext);
  const [_, setSelectedApplication] = applicationState;
  const [applicationPath, setAppPath] = pathState;
  const [lang, setLang] = langState;

  const { detailState, vehicleState } = useContext(CustomerContext);
  const [vehicleDetails, setVehicleDetails] = vehicleState;




  const fetchData = async () => {
    setLoading(true);
    try {
      let timeQuery = {};
      if (time === "Any time") {
        timeQuery = { type: "All", value: 1 };
      } else {
        timeQuery = {
          type: time.split(" ")[1].toLowerCase(),
          value: time.split(" ")[0],
        };
      }
      let { data } = await fetchAllApplications(
        filter.split(" ")[0],
        timeQuery.type,
        timeQuery.value,
        status
      );

      setApplications(data?.data);
    } catch (error) { }
    setLoading(false);
  };

  const fetchAppData = async (customer) => {
    let { data: response } = await fetchApplicationData(customer?.application_id);
    setBorrower(response?.data)
  }

 

  useEffect(() => {
    if (location?.state?.customer) {
      fetchAppData(location?.state?.customer)
    }
  }, [location])

  useEffect(() => {
    fetchData();
  }, [status, filter, time]);

  const { getApplicationDetails, getStage, getRealStatus } = useApplication();

  // useEffect(() => {
  //   getApplicationDetails(application_id);
  // }, []);

  // useEffect(() => {
  //   window.onpopstate = () => {
  //     // navigate("/home");
  //     navigate(location?.state?.origin);
  //   };
  // });

  let timeOut;

  useEffect(() => {
    if (searchTxt !== "") {
      clearTimeout(timeOut);
      setLetSearch(false);
      timeOut = setTimeout(() => {
        setLetSearch(true);
      }, 1000);
    }
  }, [searchTxt]);

  useEffect(() => {
    searchTxt !== "" && setSearching(true);

    if (letSearch) {
      if (searchTxt !== "") {
        (async () => {
          let timeQuery = {};
          if (time === "Any time") {
            timeQuery = { type: "All", value: 1 };
          } else {
            timeQuery = {
              type: time.split(" ")[1].toLowerCase(),
              value: time.split(" ")[0],
            };
          }

          let { data } = await searchApplication(
            searchTxt,
            filter.split(" ")[0],
            status,
            timeQuery.type,
            timeQuery.value
          );
          if (data?.status) {
            setApplications(data?.data);
          }
        })();
      } else {
        fetchData(status);
      }
      setSearching(false);
    }
  }, [letSearch]);

  return (
    <Box sx={{ pb: 7 }} ref={ref} className="content-center bg-new">
      <Grid container spacing={2} className="p-2 pt-0 pb-5 response-wrapper">
        <Grid item xs={12} sm={12}>
          <div className="page-head-client">
            <ArrowBackIcon
              sx={{ color: "#573A7A" }}
              className="pointer"
              onClick={() => {
                navigate('/collection');
              }}
            />
            <div className="dp-container">
              <div className="dp-box">
                <img src={profile_icon_1} className="dp" />
                {/* <img src={camera_icon} className="dp-change" /> */}
              </div>
              <p className="dp-name">{borrower?.customerData?.pan_details?.name}</p>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12}>
          <div className="client-card">
            <div className="cc-head">
              <p className="m-0 cc-title">{l("Basic Details", lang)}</p>
            </div>
            <div className="cc-metainfo-container">
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("DOB", lang)} :</p>
                <p className="cc-metainfo">{borrower?.customerData?.dob || `N/A`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("Gender", lang)} : </p>
                <p className="cc-metainfo">{borrower?.customerData?.gender || `N/A`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("Mobile Number", lang)} : </p>
                <p className="cc-metainfo">{borrower?.customerData?.mobile_number || `N/A`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("Current Address", lang)} : </p>
                <p className="cc-metainfo">{borrower?.customerData?.aadhaar_details?.address || `N/A`}</p>
              </div>
              {/* <p className="cc-metainfo">Gender : </p>
              <p className="cc-metainfo">Mobile Number : </p>
              <p className="cc-metainfo">Current Address : </p> */}
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12}>
          <div className="client-card">
            <div className="cc-head">
              <p className="m-0 cc-title">{l("Loan Details", lang)}</p>
            </div>
            <div className="cc-metainfo-container">
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("Loan Amount", lang)} : </p>
                <p className="cc-metainfo">{borrower?.loan_amount || `N/A`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("Interest Rate", lang)} : </p>
                <p className="cc-metainfo">{borrower?.annual_interest_rate || `N/A`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("Tenure", lang)} : </p>
                <p className="cc-metainfo">{`${borrower?.tenure_value || `N/A`}  ${borrower?.tenure_type || `N/A`}`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("EMI Amount", lang)} : </p>
                <p className="cc-metainfo">{borrower?.installment_amount || `N/A`}</p>
              </div>
              {/* <p className="cc-metainfo">Loan Amount : </p>
              <p className="cc-metainfo">Interest Rate :</p>
              <p className="cc-metainfo">Tenure : </p>
              <p className="cc-metainfo">EMI Amount : </p> */}
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12}>
          <div className="client-card">
            <div className="cc-head">
              <p className="m-0 cc-title">{l("Collection", lang)}</p>
            </div>
            <div className="cc-metainfo-container">
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("Amount Collected", lang)} : </p>
                <p className="cc-metainfo">{borrower?.total_collected || `N/A`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("Due Amount", lang)} : </p>
                <p className="cc-metainfo">{borrower?.total_repayment || `N/A`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p className="cc-metainfo">{l("Due Date", lang)} : </p>
                <p className="cc-metainfo">{borrower?.due_date || `N/A`}</p>
              </div>
              {/* <p className="cc-metainfo">Amount Collected : </p>
              <p className="cc-metainfo">Due Amount :</p>
              <p className="cc-metainfo">Due Date : </p> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
