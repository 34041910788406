export const cf = (val, sign = true) => {
  val = Number(val);
  if (!isNaN(val)) {
    return sign ? `₹ ${val?.toLocaleString("en-IN")}` : val?.toLocaleString("en-IN");
  } else {
    return "NA";
  }
};

export const convertToHindi = (num, sign = true) => {
  const hindiNums = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
  const strNum = num?.toString();

  // Convert the number to its Hindi equivalent
  let hindiStr = "";
  for (let i = 0; i < strNum.length; i++) {
    hindiStr += hindiNums[parseInt(strNum[i])];
  }

  // Format the number as currency in Indian number system
  let formattedCurrency = "";
  if (hindiStr.length > 3) {
    const lastThreeDigits = hindiStr.slice(-3);
    const remainingDigits = hindiStr.slice(0, hindiStr.length - 3);

    formattedCurrency = remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThreeDigits;
  } else {
    formattedCurrency = hindiStr;
  }

  return sign ? "₹" + formattedCurrency : formattedCurrency;
}

export const CurrencyLangMap = (currLang, value, sign) => {
  switch (currLang) {
    case 'english':
      return cf(value);
    case 'hindi':
      return convertToHindi(value, sign);
    default:
      return cf(value);
  }
}
