export const environment = (()=>{
  if(!process.env.REACT_APP_ENVIRONMENT) return "development"
  if(process.env.REACT_APP_ENVIRONMENT === "development") {
    return window.location.host?.includes("demo.erick") ? 'demo' : 'development'
  }
  return process.env.REACT_APP_ENVIRONMENT
})()
export const baseURL = {
  development: "https://api.lms.embifi.in/api/v1",
  demo: "https://api.lms.embifi.in/api/v1",
  production: window.location.host?.includes("prod-demo.erick")
    ? "https://test.prod.api.lms.embifi.in/api/v1"
    : "https://prod.api.lms.embifi.in/api/v1",
  beta: "https://beta.api.lms.embifi.in/api/v1",
  local: "http://localhost:4001/api/v1",
};
