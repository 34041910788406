import { Button } from "@mui/material";
import React from "react";
import approvedIco from "../../assets/approved.svg";

const ButtonComp = ({ icon, text, status, action, disabled, isDone }) => {

  return (
    <div style={{ position: "relative", opacity: disabled ? 0.5 : 1 }}>
      {isDone && (
        <div className="doc-status-holder pointer">
          <img src={approvedIco} alt="" width={40} height={40} />
        </div>
      )}
      {status}
      <div
        className={`documents-card-v3`}
        style={{
          height: 160,
          opacity: disabled ? 0.4 : 1,
        }}
        onClick={() => {}}
      >
        <div>
          <p className={`mb-0 text-center mt-3`}>
            <img
              src={icon}
              alt=""
              width={70}
              height={70}
              style={{ objectFit: "contain" }}
            />
          </p>
          <div className={`w-100 mt-4 content-center`}>
            <Button
              disabled={disabled}
              className="upload-btn"
              style={{
                height: 25,
              }}
              onClick={() => {
                action();
              }}
            >
              {text}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonComp;
