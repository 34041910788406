export default function CoinCard(props) {
    const { coins, icon, cardText, buttonText, onClick } = props
    return (
        <div className='coin-card'>
            <div class="coin-card-badge">
                <p className='coin-badge-text'>{coins}</p>
                <p className='coin-badge-text'>Coin</p>
                <div class="triangle-up"></div>
            </div>
            <div className="icon-div">
                <img src={icon} alt="homeIco" />
            </div>
            <div className='coin-card-main'>
                <p className='coin-card-main-text'>{cardText}</p>
                {buttonText && <button className='coin-card-main-button' onClick={onClick}>
                    {buttonText}
                </button>}
            </div>
        </div>
    )
}