import React from 'react'

const ATinfo = ({info , currentIndex , totleIndex , bodyStyle , pointerStyle}) => {
  return (
    <div className={`AT_info ${bodyStyle ? bodyStyle : ''}`}>
        <div className='index'>{currentIndex}/{totleIndex}</div>
        <div className='info'>{info}</div>
        <div className={`pointer ${pointerStyle ? pointerStyle : ""}`}></div>
    </div>
  )
}

export default ATinfo