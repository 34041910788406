import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
// import CssBaseline from "@mui/material/CssBaseline";
// import BottomNavigation from "@mui/material/BottomNavigation";
// import BottomNavigationAction from "@mui/material/BottomNavigationAction";
// import RestoreIcon from "@mui/icons-material/Restore";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ArchiveIcon from "@mui/icons-material/Archive";
// import Paper from "@mui/material/Paper";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
// import HomeIcon from "@mui/icons-material/Home";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import { GiReceiveMoney } from "react-icons/gi";
// import { AiOutlineHome } from "react-icons/ai";
// import { CgProfile } from "react-icons/cg";
// import MenuAppBar from "../../../components/Appbar";
// import Home from "../HomeScreens/Home";
// import KycPop from "../../components/KycPop";
// import { UserContext } from "../../Contexts/UserContext";
// import Profile from "../HomeScreens/Profile";
// import LoanScreen from "../HomeScreens/Loan";
import {
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import {
  fetchAllApplications,
  // fetchCompletedApplications,
  // fetchDraftApplications,
  // fetchRecentApplications,
  // getCustomerData,
  searchApplication,
} from "../../api";
import moment from "moment";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { CustomerContext } from "../../Contexts/CustomerContext";
// import SearchIcon from "@mui/icons-material/Search";
// import emptyIco from "../../assets/empty.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import SelectCom from "../../components/Select";
import profile_icon_1 from "../../assets/profile_icon_1.svg";
import profile_icon_2 from "../../assets/profile_icon_2.svg";
import profile_icon_3 from "../../assets/profile_icon_3.svg";
import profile_icon_4 from "../../assets/profile_icon_4.svg";
import camera_icon from "../../assets/camera_icon.svg";
import embifi_logo from "../../assets/embifi_logo.jpg";
import useLogout from "../../hooks/useLogout";
import { l } from "../../util/languageConvertor";


const cardData = [
  { title: "Have a Query?", subTitle: "No issues, check most frequently asked questions", btnLabel: "Resolve Query", icon: profile_icon_1 },
  { title: "Need Expert Advice?", subTitle: "We're there to answer all your concerns.", btnLabel: "Connect Now", icon: profile_icon_2 },
  { title: "Language Issue ?", subTitle: "Now switch to language you are comfortable with.", btnLabel: "Change Language", icon: profile_icon_3 },
  { title: "Long queue for payment ?", subTitle: "Skip the line and pay from anywhere using QR.", btnLabel: "Pay Now", icon: profile_icon_4 },
];

export default function DealerProfile() {
  const ref = useRef(null);
  const selectRef = useRef(null);
  const { logoutUser } = useLogout();

  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [status, setStatus] = useState("All");
  const [searchTxt, setSearchTxt] = useState("");
  const [letSearch, setLetSearch] = useState(false);
  const [time, setTime] = useState("Any time");
  const [filter, setFilter] = useState("All Applications");

  const navigate = useNavigate();

  const { showPermissionState, applicationState, pathState, langState } =
    useContext(GlobalContext);
  const [_, setSelectedApplication] = applicationState;
  const [applicationPath, setAppPath] = pathState;
  const [lang, setLang] = langState;

  const { detailState, vehicleState } = useContext(CustomerContext);
  const [vehicleDetails, setVehicleDetails] = vehicleState;

  const fetchData = async () => {
    setLoading(true);
    try {
      let timeQuery = {};
      if (time === "Any time") {
        timeQuery = { type: "All", value: 1 };
      } else {
        timeQuery = {
          type: time.split(" ")[1].toLowerCase(),
          value: time.split(" ")[0],
        };
      }
      let { data } = await fetchAllApplications(
        filter.split(" ")[0],
        timeQuery.type,
        timeQuery.value,
        status
      );

      setApplications(data?.data);
    } catch (error) { }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [status, filter, time]);

  // useEffect(() => {
  //   window.onpopstate = () => {
  //     navigate("/home/loan");
  //   };
  // });

  let timeOut;

  useEffect(() => {
    if (searchTxt !== "") {
      clearTimeout(timeOut);
      setLetSearch(false);
      timeOut = setTimeout(() => {
        setLetSearch(true);
      }, 1000);
    }
  }, [searchTxt]);

  useEffect(() => {
    searchTxt !== "" && setSearching(true);

    if (letSearch) {
      if (searchTxt !== "") {
        (async () => {
          let timeQuery = {};
          if (time === "Any time") {
            timeQuery = { type: "All", value: 1 };
          } else {
            timeQuery = {
              type: time.split(" ")[1].toLowerCase(),
              value: time.split(" ")[0],
            };
          }

          let { data } = await searchApplication(
            searchTxt,
            filter.split(" ")[0],
            status,
            timeQuery.type,
            timeQuery.value
          );
          if (data?.status) {
            setApplications(data?.data);
          }
        })();
      } else {
        fetchData(status);
      }
      setSearching(false);
    }
  }, [letSearch]);

  return (
    <Box sx={{ pb: 7 }} ref={ref} className="content-center">
      <Grid container spacing={2} className="p-0 pt-0 pb-5 response-wrapper">
        <Grid item xs={12} sm={12}>
          <div className="page-head-profile">
            <ArrowBackIcon
              sx={{ color: "#573A7A" }}
              className="pointer"
              onClick={() => {
                navigate("/home");
              }}
            />
            <div className="dp-container">
              <div className="dp-box">
                <img src={embifi_logo} className="dp" />
                <img src={camera_icon} className="dp-change" />
              </div>
              <p className="dp-name">{l("Demo", lang)}</p>
              <p className="dp-phone">+91 9899101094</p>
            </div>
          </div>
          <div style={{
            backgroundColor: '#fff',
            height: '7em'
          }} />
        </Grid>

        <Grid item xs={12} sm={12}>
          {cardData.map((edx, index) => {
            return (
              <Container
                className="profile-info-card-container"
                onClick={() => {
                  // setSelectedApplication(val?.id);
                  // fetchStage(val?.id);
                  setAppPath("ALL");
                  // navigate("/view/profile/" + val?.id);
                }}
                key={index}
              >
                <div className="profile-info-card">
                  <div>
                    <p className="pc-title">{l(edx.title, lang)}</p>
                    <p className="pc-subtitle">{l(edx.subTitle, lang)}</p>
                    <button className="pc-btn">{l(edx.btnLabel, lang)}</button>
                  </div>
                  <div>
                    <img src={edx.icon} />
                  </div>
                </div>
              </Container>
            );
          })
          }
        </Grid>
        <div className="d-flex justify-content-center w-100 my-3">
          <button className="logout-btn-new" onClick={logoutUser}>{l("Logout", lang)}</button>
        </div>
      </Grid>
    </Box>
  );
}
