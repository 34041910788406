import React from "react";
import { Grid } from "@mui/material";
import { l } from "../../../../util/languageConvertor";
import ATinfo from "../components/ATinfo";

const ATLoanCreation1 = ({ lang, posStyle }) => {
  return (
    <div className="AT_Container">
      <ATinfo
        currentIndex={1}
        totleIndex={2}
        info={"This is cost to customer (includes RTO & Insurance)"}
        bodyStyle={posStyle?.ATIbody}
        pointerStyle={posStyle?.ATIpointer}
      />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <label className="form-label mt-2" style={{ fontSize: 13 }}>
            {l("Vehicle Price On Road", lang)}{" "}
            <span className="mand-star">*</span>
          </label>
        </Grid>
        <Grid item xs={6} sm={6}>
          <input
            type="text"
            className="form-input w-100 input-blue"
            required
            placeholder={l("Vehicle Price On Road", lang)}
            inputMode="numeric"
            autoComplete="family-name"
            maxLength="7"
            disabled
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ATLoanCreation1;
