import {
  BottomNavigation,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Slide,
} from "@mui/material";
import React, { forwardRef, useContext } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CatureUpload from "./Capture";
import { useState } from "react";
import {
  deleteDoc,
  getSpecificDoc,
  submitCombineDoc,
  uploadDoc,
} from "../../api/agent";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { baseURL, environment } from "../../config";
import axios from "axios";
import LoadingOver from "../LoadingOver";
import { useSnackbar } from "notistack";
import { l } from "../../util/languageConvertor";
import { GlobalContext } from "../../Contexts/GlobalContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MultiDocUpload = ({ state, setState, onClose }) => {
  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const { application_id } = useParams();
  const [count, setCount] = useState(state?.count);
  const [docList, setDocList] = useState([]);
  const [isCompleted, setCompleted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [openConfirm, setConfirm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = async (props) => {
    setLoading(true);
    try {
      let payload = {
        page: props?.page,
        collection: state?.collection,
        category: state?.category,
        applicationId: application_id,
        file: {
          name: state?.name,
          data: props?.file,
        },
      };
      let { data } = await uploadDoc(payload);
      getDoc();
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleDelete = async (fileLink) => {
    setLoading(true);
    try {
      let payload = {
        applicationId: application_id,
        fieldName: state?.name,
        fileLink,
        collection: state?.collection,
      };

      let { data } = await deleteDoc(payload);
      console.log(docList);
      getDoc();
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleCombine = async (fileLink) => {
    setLoading(true);
    try {
      let payload = {
        applicationId: application_id,
        document_name: state?.name,
        collection: state?.collection,
      };

      let { data } = await submitCombineDoc(payload);
      onClose();
      setState({ show: false });
      enqueueSnackbar(l(`Submitted Successfully`, lang), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "error",
      });
    }

    setLoading(false);
  };

  const getDoc = async () => {
    try {
      setLoading(true);
      let { data } = await getSpecificDoc(
        application_id,
        state?.name,
        state?.collection
      );

      setCompleted(data?.data?.[0]?.completed);

      if (data?.status) {
        let Docs = [];
        for (let i = 0; i < count; i++) {
          Docs[i] = { isEmpty: true };
        }

        // data?.data?.map((val, i) => {

        // });
        setImageLoading(true);
        // console.log(data?.data);
        for await (const val of data?.data) {
          Docs[val?.page - 1] = val;
          Docs[val?.page - 1].image = await getImage(val?.key);
        }
        setImageLoading(false);

        setDocList(Docs);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const getImage = async (key) => {
    const nbfc = localStorage.getItem("nbfc");

    let { data } = await axios.get(
      `${baseURL[environment]}/common/view?key=${key}`,
      {
        responseType: "blob",
        headers: { application: "AGENT-ERICK", "nbfc-id": nbfc },
        withCredentials: true,
      }
    );

    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(data);
    });
  };

  useEffect(() => {
    // alert("hi")
    getDoc();
  }, [application_id]);

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        // onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className="overlay-screen w-100">
          <Box sx={{ pb: 1 }} className="w-100 response-wrapper w-100">
            <div className="tab-fixed">
              <Grid container className="tabs-wrapper">
                <Grid
                  className="app-bar"
                  item
                  xs={12}
                  sm={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ArrowBackIcon
                    className="pointer ms-2"
                    sx={{ color: "black" }}
                    onClick={() => {
                      // navigate("/draft-applications");
                      if (isCompleted) {
                        setState({ show: false });
                      } else {
                        setConfirm(true);
                      }
                    }}
                  />
                  {!isCompleted && (
                    <Button
                      type="submit"
                      sx={{ mb: 2, mr: 1, color: "purple" }}
                      onClick={handleCombine}
                    >
                      {isLoading ? (
                        <>
                          <CircularProgress
                            className="me-2 mb-1"
                            size={20}
                            style={{ color: "white" }}
                          />
                          Loading
                        </>
                      ) : (
                        l("Combine", lang)
                      )}
                    </Button>
                  )}

                  {isCompleted && (
                    <p
                      className="mb-0 me-3"
                      style={{
                        color: "green",
                      }}
                    >
                      <CheckCircleIcon /> {l("Combine", lang)}
                    </p>
                  )}
                </Grid>
                <Grid className="app-bar" item xs={12} sm={12}>
                  <p className="w-100 tab-header">
                    {state?.name?.replaceAll("_", " ")?.toUpperCase()}
                  </p>
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                marginTop: "120px",
                padding: "20px",
                background: "white",
              }}
              className="multi-card-holder"
            >
              <Grid container spacing={2}>
                {docList.map((val, i) => {
                  return (
                    <Grid item xs={4} sm={3} className="upload-doc-card">
                      <CatureUpload
                        tempState={!val?.isEmpty ? val?.image : ""}
                        text={imageLoading ? <CircularProgress /> : i + 1}
                        pageNo={i + 1}
                        onDelete={() => {
                          handleDelete(val?.fileLink);
                        }}
                        setFile={(file) => {
                          if (file !== "") {
                            handleUpload({
                              file,
                              page: i + 1,
                            });
                          }
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>

            {/* <Paper className="paper-bottom" elevation={3}>
              <BottomNavigation style={{ height: "auto", paddingTop: "3px" }}>
                <div className="button-wrapper w-100">
                  <Button
                    className="btn-submit"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mb: 2 }}
                    onClick={handleCombine}
                  >
                    {isLoading ? (
                      <>
                        <CircularProgress
                          className="me-2 mb-1"
                          size={20}
                          style={{ color: "white" }}
                        />
                        Loading
                      </>
                    ) : (
                      "Combine"
                    )}
                  </Button>
                </div>
              </BottomNavigation>
            </Paper> */}
          </Box>
          {isLoading && <LoadingOver />}
        </div>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={() => {
          setConfirm(false);
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to exit without combining?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirm(false);
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              onClose();
              setState({ show: false });
            }}
          >
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MultiDocUpload;
