import React from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import ATinfo from "../components/ATinfo";
import { l } from "../../../../util/languageConvertor";

const ATCoCustomer = ({ lang, posStyle }) => {
  return (
    <div className="AT_Container">
      <ATinfo
        currentIndex={2}
        totleIndex={2}
        info={"Please select the person who owns the house where co-customer is living currently"}
        bodyStyle={posStyle?.ATIbody}
        pointerStyle={posStyle?.ATIpointer}
      />
      <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={12}>
          <label className="form-label">
            {l("Current Residential is owned by", lang)}{" "}
            <span className="mand-star">*</span>
          </label>
          <input
            
            className="form-input w-100"
            required
            fullWidth
            disabled
            placeholder={l("Current Residential is owned by", lang)}
            autoComplete="family-name"
          />

        </Grid>
      </Grid>
    </div>
  );
};

export default ATCoCustomer;
