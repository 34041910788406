// ////////////////////////////////////////////////

import { createContext, useEffect, useState } from "react";
import useApplication from "../hooks/useApplication";

export const LoanContext = createContext(null);

const LoanContextProvider = ({ children }) => {
  let initialState = {
    application_id: "",
    anchor_id: "",
    isPanOcr: true,
    pan_number: "",
    pan_image: "",
    aadhaar_front: "",
    aadhaar_back: "",

    full_name: "",
    date_of_birth: "",
    aadhaar_address: "",
    aadhaar_pincode: "",
    is_address_same: false,
    current_address: "",

    current_resident_owned_by: "",
    current_pincode: "",
    // is_property_owned: null,
    mobile_number: "",
    // email_id: "",
    credit_pull_allow: false,
    is_aadhaar_number_matched: "",
    aadhaar_connected_number: "",

    co_customer_is_aadhaar_number_matched: "",
    co_customer_aadhaar_connected_number: "",
    co_customer_type: "CO-APPLICANT",
    co_customer_relation: "",
    co_customer_pan: "",
    co_customer_pan_number: "",
    co_customer_aadhaar_front: "",
    co_customer_aadhaar_back: "",
    co_customer_current_resident_owned_by: "",
    co_customer_id: "",
    co_customer_full_name: "",
    co_customer_date_of_birth: "",
    co_customer_aadhaar_address: "",
    co_customer_aadhaar_pincode: "",
    co_customer_is_address_same: false,
    co_customer_current_address: "",
    co_customer_current_pincode: "",
    co_customer_is_property_owned: null,
    co_customer_mobile_number: "",
    // co_customer_email_id: "",
    co_customer_credit_pull_allow: false,
    co_customer_gender: "",

    vehicle_model: "",
    vehicle_price_on_road: "",
    vehicle_price_ex: "",
    loan_amount: "",
    down_payment: "",
    interest_rate: "18",
    tenure: "12",
    interest_amount: "",
    processing_charge_rate: "",
    processing_charge_amount: "",
    emi_amount: "",
    edi_amount: "",
    OEMData: [],
    battery_type: [],
    oem_name: "",
    oem_id: "",
    battery_name: "",
    subvention_amount: "",
    subvention_percentage: "",
    holdback_amount: "",
    holdback_amount_perfile: "",
    holdback_amount_total: "",

    
    post_disbursement_docs: [],
    field_investigation_docs: [],
    documents: [],

    gender: "",
    reference1_name: "",
    reference1_mob: "",
    reference1_relation: "",
    reference2_name: "",
    reference2_mob: "",
    reference2_relation: "",

    stage: "LOADING",
    percentages: {},
    bank_not_supported: false,
  }


  const [journeyData, setJourneyData] = useState(initialState);

  const clearJourneyState = () => {
    setJourneyData(initialState);
  };

  return (
    <LoanContext.Provider
      value={{
        journeyState: [journeyData, setJourneyData],
        clearJourneyState,
      }}
    >
      {children}
    </LoanContext.Provider>
  );
};

export default LoanContextProvider;

// //////////////////////////////////////////
