export const userData = [
    {
        "applicant_name": "Raj Kumar",
        "application_date": "25-01-2023",
        "application Status": "Disbursed",
        "disbursal_date": "28-01-2023",
        "loan_amount": "1,10,000",
        "interest_rate": "17%",
        "tenure": "15 Months",
        "emi_amount": "8892",
        "total_amount_collected": "17,784",
        "upcoming_due_date": "27-04-2023",
        "upcoming_due_amount": "8,892",
        "last_active_date": "03-04-2023",
        "total_kms_covered": "1783",
        "nach": "Done",
        "rc": "Done",
        "courier": "Done",
        "collections": [
            { "name": "Raj Kumar", "collected_amt": "8,892", "collected_date": "27-03-2023" },
            { "name": "Raj Kumar", "collected_amt": "8,892", "collected_date": "27-02-2023" },
        ]
    },
    {
        "applicant_name": "Mahesh Chhaunkar",
        "application_date": "18-12-2022",
        "application Status": "Disbursed",
        "disbursal_date": "20-12-2022",
        "loan_amount": "95,000",
        "interest_rate": "17%",
        "tenure": "12 Months",
        "emi_amount": "9263",
        "total_amount_collected": "27,789",
        "upcoming_due_date": "19-04-2023",
        "upcoming_due_amount": "9263",
        "last_active_date": "03-04-2023",
        "total_kms_covered": "2,516",
        "nach": "Done",
        "rc": "Pending",
        "courier": "Done",
        "collections": [
            { "name": "Mahesh Chhaunkar", "collected_amt": "9,263", "collected_date": "19-03-2023" },
            { "name": "Mahesh Chhaunkar", "collected_amt": "9,263", "collected_date": "19-02-2023" },
            { "name": "Mahesh Chhaunkar", "collected_amt": "9,263", "collected_date": "19-01-2023" },
        ]
    },
    // {
    //     "applicant_name": "Raj Karan Saroj",
    //     "application_date": "02-04-2023",
    //     "application Status": "Approved",
    //     "disbursal_date": null,
    //     "loan_amount": null,
    //     "interest_rate": null,
    //     "tenure": null,
    //     "emi_amount": null,
    //     "total_amount_collected": null,
    //     "upcoming_due_date": null,
    //     "upcoming_due_amount": null,
    //     "last_active_date": null,
    //     "total_kms_covered": null,
    //     "nach": null,
    //     "rc": null,
    //     "courier": null
    // },
    // {
    //     "applicant_name": "MOHAMMAD KALAM",
    //     "application_date": "21-03-2023",
    //     "application Status": "Rejected",
    //     "disbursal_date": null,
    //     "loan_amount": null,
    //     "interest_rate": null,
    //     "tenure": null,
    //     "emi_amount": null,
    //     "total_amount_collected": null,
    //     "upcoming_due_date": null,
    //     "upcoming_due_amount": null,
    //     "last_active_date": null,
    //     "total_kms_covered": null,
    //     "nach": null,
    //     "rc": null,
    //     "courier": null
    // }
];


export const GpsData = [
    { name: "Raj Kumar", lastActive: "03-04-2023", travelled: "1,783" },
    { name: "Mahesh Chhaunkar", lastActive: "03-04-2023", travelled: "2,516" },
    { name: "Raj Karan Saroj", lastActive: null, travelled: null },
    { name: "MOHAMMAD KALAM", lastActive: null, travelled: null },
];

export const CollectionsData = [
    { "name": "Raj Kumar", "collected_amt": "8,892", "collected_date": "27-03-2023" },
    { "name": "Raj Kumar", "collected_amt": "8,892", "collected_date": "27-02-2023" },
    { "name": "Mahesh Chhaunkar", "collected_amt": "9,263", "collected_date": "19-03-2023" },
    { "name": "Mahesh Chhaunkar", "collected_amt": "9,263", "collected_date": "19-02-2023" },
    { "name": "Mahesh Chhaunkar", "collected_amt": "9,263", "collected_date": "19-01-2023" }
]

