export default {
  details_for_aadhar:
    "केवल तब क्लिक करें जब आपका वर्तमान पता और आधार में लिखा पता सामान हो",
  //   details_for_current_residential: 'यहाँ वहीं पता भरें जहाँ आप वर्तमान में रह रहे हैं',
  RTO_Insurance: "ग्राहक को गाडी की कीमत में आरटीओ और बीमा को जोड़ कर",
  dealer_Profit: "गाडी की कीमत डीलर की लागत + अपने लाभ के बाद",
  field_investigation: "जो व्यक्ति जांच के लिए जा रहा है उसका ही विवरण भरें",
  check_status: "पेंडिंग और रिजेक्ट डॉक्यूमेंट की जानकारी देखें",
  Applicant_KYC_Screen:
    "यहाँ कस्टमर की पैन और आधार की स्पष्ट और ओरिजिनल फोटो डालें",
  Co_Applicant_KYC_Screen:
    "यहाँ को-कस्टमर या गारनटर के पैन और आधार की क्लियर और ओरिजिनल फोटो डालें",
  NBFC_Under_review: "आप अगली प्रक्रिया जल्द ही कर पाएंगे",
  guarantor_is_living: "गारंटर जिस घर में रहते है उसका मालिक कौन है",
  co_customer_living: "यह कस्टमर जिस घर में रहते है उसका मालिक कौन है",
  customer_living: "कस्टमर जिस घर में रहते है उसका मालिक कौन है",
};
