import { createContext, useContext, useEffect, useState } from "react";
import useFindUser from "../hooks/AuthScreens/Agent/useFindUser";
import { useNavigate } from "react-router";
import { GlobalContext } from "./GlobalContext";

export const UserContext = createContext(null);

const UserContextProvider = ({ children }) => {

  const { langState } = useContext(GlobalContext);

  const navigate = useNavigate();
  const { user, setUser, isLoading } = useFindUser();
  const [lang, setLang] = langState;

  const [signUpData, setSignup] = useState({
    mobile: "",
    password: "",
    cpassword: "",
  });

  useEffect(() => {
    if (!["english", "hindi", "hinglish"].includes(user?.language)) {
      setLang("english");
    }else{
      setLang(user?.language);
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isLoading,
        signUpDataState: [signUpData, setSignup],
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
