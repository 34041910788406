import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate()
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <p className="text-center mb-0">You seem lost.</p>
        <p style={{ fontWeight: 600, fontSize: 100 }}>404</p>
        <div className="content-center">
          <Button
            className="btn-submit"
            onClick={() => {
              navigate("/");
            }}
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
}
