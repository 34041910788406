import { langDictionary } from "../constants/langDictionary";


export const l = (val, lang) => {
  let conversion = langDictionary?.[val]?.[lang];
  if (conversion) {
    return conversion;
  } else {
    return val;
  }
};
