import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import docDone from "../../assets/docDone.svg";
import oopsIco from "../../assets/oops.svg";
import { clearRejectList } from "../../api/agent";

const DocPopup = ({ open, type, checkStatus, handleClose, application_id }) => {
  const { docPopupState } = useContext(GlobalContext);
  const [docPopup, setDocPopup] = docPopupState;

  const handleOk = () => {
    if (docPopup?.type === "DONE") {
      setDocPopup({ open: false, type: "" });
      handleClose();
    } else {
      handleMarkSeen();
      handleClose();
      checkStatus();
    }
  };

  const handleMarkSeen = async () => {
    try {
      let { data } = await clearRejectList(application_id);
    } catch (error) {}
  };

  return (
    <Dialog
      open={docPopup?.open}
      className="pop-dialog-card"
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "30px",
        },
      }}
      // onClose={handleClose}
    >
      {docPopup?.type === "DONE" ? (
        <>
          <DialogContent>
            <div className="content-center">
              <img src={docDone} alt="docDone" className="mb-2 mt-3" />
            </div>
            <p className="pop-head text-center">
              All the documents are uploaded for this application
            </p>
            <p className="pop-desc text-center">
              Please wait for document approval for next 24hour
            </p>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogContent>
            <div className="content-center">
              <img src={oopsIco} alt="docDone" className="mb-2 mt-3" />
            </div>
            <p className="pop-head text-center">
              Some documents are need to reupload
            </p>
          </DialogContent>
        </>
      )}
      <div className="content-center">
        <Button className="btn-submit" onClick={handleOk}>
          {docPopup?.type === "DONE" ? "Ok" : "Check Status"}
        </Button>
      </div>
    </Dialog>
  );
};

export default DocPopup;
