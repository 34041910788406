import React, { useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { l } from "../util/languageConvertor";
import { GlobalContext } from "../Contexts/GlobalContext";

export default function CustomInputAutocomplete2({
  options,
  onChange: setVal,
  text,
  className
}) {

  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;


  return (
    <Autocomplete
      sx={{
        "& input": {
          width: "100% !important",
        },
      }}
      id="custom-input-demo"
      options={options}
      disableClearable
      freeSolo
      getOptionLabel={(option) => l(option, lang)}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <input
            placeholder={text ? l(text,lang) : "Select Bank"}
            type="text"
            {...params.inputProps}
            className={"form-input "+className}
          />
        </div>
      )}
      onInputChange={(e) => {
        setVal(e.target.value);
      }}
      onChange={(e, value) => {
        // console.log(value);
        setVal(value);
      }}
    />
  );
}
