import { Button, CircularProgress } from "@mui/material";
import React, { useContext } from "react";

const ConfirmChequeData = ({ data, setData }) => {
  return (
    <div className="kyc-dialog">
      <div className="cheque-dialog dialog">
        {data?.loading ? (
          <>
            <p className="welcome text-center">
              <CircularProgress />
            </p>
            <p className="welcome mt-2 w-100 text-center">Parsing Bank Info</p>
          </>
        ) : (
          <>
            <p className="text-center">Confirm bank account details</p>
            <p className="mb-0 mt-4">
              Acc no: <span style={{fontWeight:'500'}}>{data?.account_no}</span>
            </p>
            <p className="mb-0">
              IFSC: <span style={{fontWeight:'500'}}>{data?.ifsc}</span>
            </p>
            <p className="mb-0">
              Acc type: <span style={{fontWeight:'500'}}>{data?.account_type}</span>
            </p>
            <Button
              className="btn-submit mb-2 mt-4"
              type="submit"
              fullWidth
              variant="contained"
              onClick={()=>{
                setData({
                  ...data,
                  confirm:true,
                  open:false
                })
              }}
            >
              Confirm
            </Button>
            <Button
              type="submit"
              fullWidth
              onClick={()=>{
                setData({
                  ...data,
                  confirm:false,
                  open:false
                })
              }}
            >
              Incorrect Data, Close
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmChequeData;
