import { useState, useCallback, useRef } from "react";

// Custom hook for text-to-speech functionality
function useTextToSpeech() {
  const [isSupported, setIsSupported] = useState("speechSynthesis" in window);
  const [isSpeaking, setIsSpeaking] = useState(true);
  const utteranceRef = useRef(null);

  const speak = useCallback((text, onEnd) => {
    if (isSupported && isSpeaking) {
      const synthesis = window.speechSynthesis;

      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = "hi-IN";
      utterance.rate  = 0.95
      // utterance.voice = synthesis.getVoices()[12];
      utterance.onend = () => {
        if (typeof onEnd === "function") onEnd();
      };
      utteranceRef.current = utterance;

      synthesis.speak(utterance);
    }
  }, [isSpeaking]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;
    if (isSpeaking) synth.resume();
    synth.speak(utteranceRef.current);
    setIsSpeaking(true);
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;
    synth.pause();
    setIsSpeaking(false);
  };
  console.log(utteranceRef.current)
  return {
    isSupported,
    speak,
    isSpeaking,
    setIsSpeaking,
    handlePlay,
    handlePause,
    utterance: utteranceRef.current,
  };
}

export default useTextToSpeech;
