import { Avatar, Button, Dialog, Grid, MenuItem } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import UserAvatar from "../../components/Avatar";
import { UserContext } from "../../Contexts/UserContext";
import faqIcon from "../../assets/faq.svg";
import handshakeIcon from "../../assets/handshakeIcon.svg";
import supportIcon from "../../assets/support.svg";
import { useNavigate } from "react-router-dom";
import { FILE_URL } from "../../util/constants";
import useLogout from "../../hooks/AuthScreens/Agent/useLogout";
import { l } from "../../util/languageConvertor";
import { GlobalContext } from "../../Contexts/GlobalContext";
import LogoutIcon from "@mui/icons-material/Logout";
import translateIco from "../../assets/translateWhite.svg";
import ChangeLanguageDialog from "../../components/V3/ChangeLanguageDialog";
import { useState } from "react";
import moment from "moment/moment";

const Profile = () => {
  const { user } = useContext(UserContext);
  const { langState } = useContext(GlobalContext);
  const { logoutUser } = useLogout();
  const navigate = useNavigate();
  const [lang, setLang] = langState;
  const [openLang, setOpenLang] = useState(false);

  return (
    <>
      <div className="content-center">
        <Grid
          container
          spacing={2}
          className="p-3 mt-3 profile-wrapper response-wrapper"
        >
          <Grid item xs={12} sm={12}>
            <div className="profile-card">
              <Container>
                <Row>
                  <Col xs={4} className={"d-flex justify-content-end"}>
                    {/* <UserAvatar /> */}
                    <Avatar
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: 100, height: 100 }}
                    />
                  </Col>
                  <Col xs={8}>
                    <p className="card-text company mt-3">
                      {user?.business_details?.name}
                    </p>
                    <p className="card-text user">{user?.name}</p>
                    <p className="card-text mob">+91 {user?.mobile_number?.[0]}</p>
                  </Col>
                </Row>
                {/* <Row>
                  <Col className={"d-flex justify-content-end"}>
                    <Button className="btn-view" onClick={()=>navigate('/home/profile-view')}>View Profile</Button>
                  </Col>
                </Row> */}
              </Container>
            </div>
          </Grid>
          {/* <Grid item xs={6} sm={6}>
            <div className="profile-card card-button content-center pt-5 pointer" onClick={() => navigate("/under-construction")}>
              <div>
                <p className="text-center mb-3">
                  <img src={faqIcon} width="40px" />
                </p>
                <p className="text-center card-text user">FAQs</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className="profile-card card-button content-center pt-5 pointer" onClick={() => navigate("/under-construction")}>
              <div>
                <p className="text-center mb-3">
                  <img src={supportIcon} width="60px" />
                </p>
                <p className="text-center card-text user">Support</p>
              </div>
            </div>
          </Grid> */}

          <Grid item xs={12} sm={12}>
            <Button
              className="w-100 home-btn mt-3 d-flex justify-content-between px-5"
              variant="outlined"
              onClick={() => {
                window.open('https://www.embifi.in/lending-partners', '_blank')
              }}
            >
              {l("Our lending partners", lang)}
              <img src={handshakeIcon} />
            </Button>
            <Button
              className="w-100 home-btn mt-3 d-flex justify-content-between px-5"
              variant="outlined"
              onClick={() => {
                setOpenLang(true)
              }}
            >
              {l("Change language", lang)}
              <img src={translateIco} />
            </Button>
            <Button
              className="w-100 home-btn mt-3 d-flex justify-content-between px-5"
              variant="outlined"
              onClick={() => {
                logoutUser();
              }}
            >
              {l("Logout", lang)}
              <LogoutIcon />
            </Button>

            <p className="text-center mt-3">Last Login: {user?.last_login ? moment(user?.last_login).format('MMMM Do YYYY, h:mm:ss a') : "NA"}</p>
            
          </Grid>

          {/* <p className=" text-center logout-btn px-3">
            <Button
              className="btn-logout"
              variant="outlined"
              onClick={() => {
                logoutUser();
              }}
            >
              Logout
            </Button>
          </p> */}
        </Grid>
      </div>

      <ChangeLanguageDialog
        open={openLang}
        handleClose={() => {
          setOpenLang(false);
        }}
      />
    </>
  );
};

export default Profile;
