import mixpanel from "mixpanel-browser";
import { environment } from "../config";

/**
 * Represents a MixPanel transport object.
 * @constructor
 * @param {string} token - The MixPanel API token to use.
 */
export function MixPanelTransport(token) {
  const _ = this;

  // /**
  // not implemented
  //  * Emits data to the MixPanel transport.
  //  * @param {object} dataObject - The data object to emit.
  //  */
  // _.emit = async function (dataObject) {
  //   _.logger.info("logged api audit");
  //
  //   return;
  // };

  mixpanel.init(token, { debug: true });

  /**
   * Sends an event package to MixPanel.
   * @param {object} eventPackage - The event package to send.
   * @param {string} eventPackage.event - The name of the event to track.
   * @param {object} eventPackage.properties - Additional properties to track with the event.
   */
  _.track = function (eventPackage) {
    const { event, properties } = eventPackage;
    // mixpanel.identify(properties?.mobile_number || "visitor");

    /////////////// Un comment this line to track events before Merge /////////////////////

    if (environment === "production" || environment === "beta") {
      mixpanel.track(event, properties);
    }

    // ///////////////////////////////////
  };

  /**
   * Identifies a user with MixPanel.
   * @param {string} id - The identifier to use for the user.
   */
  _.identify = function (id) {
    mixpanel.identify(id);
  };
}
