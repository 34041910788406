import Compressor from "compressorjs";

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
    //   console.log(fileInfo);
  });
};

export const compressImage = (file, beta = false) => {
  if (beta) {
    return new Promise((resolve, reject) => {
      const image = file;
      new Compressor(image, {
        quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // setCompressedFile(res)
          resolve(compressedResult);
        },
      });
    });
  } else {
    // alert("No Compressing image")
    return file;
  }
};

export const dataURItoBlob=(dataURI)=>{
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;

    if(dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for(var i = 0; i < byteString.length; i++)
    {
        ia[i] = byteString.charCodeAt(i);
    }

    var blob = new Blob([ia], {type: mimeString});
    return URL.createObjectURL(blob);
}

export const handleDownloadBase64PDF = (base64,fileName)=>{
  // const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = dataURItoBlob(base64);
  downloadLink.download = fileName;
  downloadLink.click();
}
