import { useContext, useEffect, useRef, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "axios";
import domtoimage from "dom-to-image";
import { Button } from "@mui/material";
import html2canvas from "html2canvas";
import { dummyImage } from "../../constants/dummyImage";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { useSnackbar } from "notistack";

const useImageWithLocation = () => {
  const { imageRenderDataState } = useContext(GlobalContext);
  const [imageRenderData, setImageRenderData] = imageRenderDataState;
  const { enqueueSnackbar } = useSnackbar();

  const handleLocation = async (lat, long) => {
    try {
      let { data: response } = await axios.get(
        `https://api.common-wrapper.embifi.in/v1/map/geocode?lat=${lat}&long=${long}`
      );
      return response?.data?.results?.[0]?.formatted_address;
    } catch (error) {
      return "Location not found";
    }
  };

  //   const handleDownload = () => {
  //     html2canvas(divRef.current, {
  //       allowTaint: true,
  //       useCORS: true,
  //       onclone: (clonedDoc) => {
  //         clonedDoc.getElementById("image-with-location").style.display = "block";
  //       },
  //     }).then((canvas) => {
  //       const imgData = canvas.toDataURL();
  //       console.log(imgData);
  //     });
  //   };

  //   useEffect(() => {
  //     handleDownload();
  //   }, [locationAddress]);

  //   const handleDownload = () => {
  //     domtoimage
  //       .toPng(document.getElementById("image-with-location"), {quality: 1})
  //       .then(function (data) {
  //         console.log(data);
  //       })
  //       .catch(function (error) {
  //         console.error("oops, something went wrong!", error);
  //       });
  //   };

  const getImageWithLocation = async (loc, file) => {
    try {
      let address = await handleLocation(loc?.lat, loc.long);
      setImageRenderData({
        ...imageRenderData,
        location: loc,
        image: file,
        address,
      });
      // console.log(address);
      return new Promise((resolve) => {
        setTimeout(() => {
          (async () => {
            let canvas = await html2canvas(
              document.getElementById("image-with-location"),
              {
                allowTaint: true,
                useCORS: true,
                onclone: (clonedDoc) => {
                  clonedDoc.getElementById(
                    "image-with-location"
                  ).style.display = "block";
                },
              }
            );
            const imgData = await canvas.toDataURL();
            resolve(imgData);
          })();
        }, 500);
      });
    } catch (err) {
      // console.log(err);
      enqueueSnackbar("Something went wrong", { variant: "error" });
      return "";
    }
  };

  return {
    getImageWithLocation,
  };
};

export const RenderCanvas = () => {
  const { imageRenderDataState } = useContext(GlobalContext);
  const [imageRenderData, setImageRenderData] = imageRenderDataState;
  const [imageDimension, setImageDimension] = useState({
    height: 100,
    width: 200,
  });

  function getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image();
      i.onload = function () {
        resolved({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  }

  return (
    <>
      <div
        id="image-with-location"
        style={{ position: "relative", display: "none" }}
      >
        <img src={imageRenderData?.image} width={"100%"} />
        <div
          className="location-holder"
        >
          <div className="location-div py-2">
            <p>
              <span>
                {" "}
                <LocationOnIcon sx={{ fontSize: 15 }} /> Coordinates:
              </span>{" "}
              {imageRenderData?.location?.lat},{" "}
              {imageRenderData?.location?.long}
            </p>
            <p style={{ maxWidth: 300, fontSize: "100%" }}>
              <span>Address:</span> : {imageRenderData?.address}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default useImageWithLocation;
