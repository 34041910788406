import { useRef } from "react";
import file_upload from "../../assets/file_upload_icon.svg";
import { getBase64 } from "../../util/convertFileBase";

export default function FileUploadComp(props) {
    const { onChange, badge, seperate } = props
    const ref = useRef('');
    return (
        // <div className="fu-container">
        //     <div style={{position: 'relative' }}>
        //         <div style={{
        //             position: "absolute",
        //             right: "8px",
        //             top: "-5px"
        //         }}>
        //             <img src={badge} />
        //         </div>
        //         <img src={props?.icon} style={{ width: '2em' }} onClick={() => {
        //             ref.current.click()
        //         }}
        //         />
        //     </div>
        //     <input ref={ref} type={"file"}
        //         // class="custom-file-input"
        //         accept="application/pdf , image/png, image/jpg, image/jpeg"
        //         hidden
        //         onChange={onChange}
        //     />
        // </div>

        <div className="fu-container" onClick={props?.onClick}>
            <div style={{ position: 'relative', display: 'inline-flex' }}>
                <img src={props?.icon} style={{ width: '2em' }} onClick={() => ref.current.click()} />
                <div style={{ position: "absolute", right: "-0.2em", display: 'flex', alignItems: 'flex-start' }}>
                    <img src={badge} />
                </div>
            </div>
            
            <input ref={ref} type={"file"}
                accept="application/pdf , image/png, image/jpg, image/jpeg"
                hidden
                onChange={onChange}
                disabled={seperate}
            />
        </div>
    );
}