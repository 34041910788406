import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../Contexts/UserContext";
import { addName, sendOTP, signIn, verifyAuth } from "../api";
import { useSnackbar } from "notistack";
import { GlobalContext } from "../Contexts/GlobalContext";
import { l } from "../util/languageConvertor";

export default function useAuth() {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { otpState , langState} = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const [error, setError] = useState(null);
  const [otpData, setOtpData] = otpState;

  const loginUser = async (mobile) => {


    let payload = {
      data:{
        mobile_number:mobile
      }
    }
    try {
      let { data } = await sendOTP(payload,'signin');
      if (data?.status) {

        setOtpData((st) => ({
          ...st,
          mobile: mobile,
          path: "home",
          method:"signup",
          vId:data?.data?.verificationId
        }));
        navigate(`/otp/${data?.data?.verificationId}`);
        
      }
    } catch (error) {
      enqueueSnackbar(l(error.response.data.errors[0].message,lang), {variant:"error"})
    }
  };

  const signUpUser = async (payload,mobile) => {
    try {
      let { data } = await sendOTP(payload,'signup');
      if (data?.status) {
        setOtpData((st) => ({
          ...st,
          mobile: mobile,
          path: "home",
          method:"signup",
          vId:data?.data?.verificationId
        }));
        navigate(`/otp/${data?.data?.verificationId}`);
      }
    } catch (error) {
      enqueueSnackbar(l(error.response.data.errors[0].message,lang), {variant:"error"})
    }
  }

  const completeSignUp = async (name, pincode, setLoading) => {

    let payload = {
      name,
      business_pincode: pincode,
    };

    setLoading(true)

    try {
      let { data } = await addName(payload);
      if (data?.status) {
        setUser((st)=>({...st, name}));
        navigate("/home");
      }
    } catch (error) {
      enqueueSnackbar(l(error.response.data.errors[0].message,lang), {variant:"error"})
    }

    setLoading(false)

  };

  return {
    loginUser,
    completeSignUp,
    signUpUser,
    error,
  };
}
