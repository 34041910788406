import { Button } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Contexts/UserContext";
import LogoutIcon from "@mui/icons-material/Logout";
import useLogout from "../hooks/useLogout";

const KycPop = () => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { logoutUser } = useLogout();
  return (
    <div className="kyc-dialog">
      <div className="d-flex justify-content-end mt-3 me-4">
        <LogoutIcon
          onClick={() => {
            logoutUser();
            setUser(null);
          }}
        />
      </div>
      <div className="dialog response-wrapper">
        <p className="welcome">Welcome</p>
        <p className="name">{user?.name}!</p>

        <p className="desc">Please submit KYC to complete your Profile</p>
        <p className="text-end w-100">
          <Button
            variant="contained"
            onClick={() => {
              navigate("/kyc");
            }}
          >
            Continue
          </Button>
        </p>
      </div>
    </div>
  );
};

export default KycPop;
