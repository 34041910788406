import { createContext, useState } from "react";

export const kycContext = createContext(null);

const KycContextProvider = ({ children }) => {

  const [isAadharVerified, setAadharVerfiy] = useState(false);
  const [poaFront, setproofFront] = useState("");
  const [poaBack, setproofBack] = useState("");

  const [pennyDrop, setPennyDrop] = useState({
    loading:false,
    status:false
  })

  const [accountDetails, setAccountDetails] = useState({
    acntName: "",
    acntNum: "",
    conAcntNum: "",
    bank: "",
    type: "",
    ifsc: "",
  });

  const [kycData, setKycData] = useState({
    businessName: "",
    businessAddress: "",
    selfie: "",
    sitePhoto: "",
    isAadharNum: true,
    aadhar_number: "",
    aadhar_front: "",
    aadhar_back: "",
    isPanNum: true,
    panNumber: "",
    panFront: "",
    selfieLocation: {},
    siteLocation: {},
  });

  const [gstData, setGstData] = useState({
    gstin:"",
    tradeLicense:""
  })

  return (
    <kycContext.Provider
      value={{
        kycDataState: [kycData, setKycData],
        aadharVerifState: [isAadharVerified, setAadharVerfiy],
        poaFrontState: [poaFront, setproofFront],
        poaBackState: [poaBack, setproofBack],
        accountDetailsState: [accountDetails, setAccountDetails],
        gstDataState:[gstData, setGstData],
        pennyState: [pennyDrop, setPennyDrop] 
      }}
    >
      {children}
    </kycContext.Provider>
  );
};

export default KycContextProvider;
