import { Box, Dialog, DialogContent, Grid, Slide } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../Contexts/GlobalContext";
import { UserContext } from "../Contexts/UserContext";
import successGif from "../assets/successGif.gif";
import { fiSendOtp, sentOtp, verifyOtp, verifyOtp2 } from "../api/agent";
import { l } from "../util/languageConvertor";
import { LoanContext } from "../Contexts/LoanJourneyContext";
import LoadingOver from "../components/LoadingOver";
// import { verifyLogin } from "../api/agent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OtpLogin = ({ open, handleClose, onComplete }) => {
  const { otpState, resetState, showRewardState, langState } =
    useContext(GlobalContext);
  const { setUser, signUpDataState } = useContext(UserContext);

  const [vId, setVid] = useState("");
  const [isOver, setOver] = useState(false);
  const [lang, setLang] = langState;

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [isReward, showReward] = showRewardState;
  const [isRunningCRE, setLoadingCRE] = useState(false);

  const navigate = useNavigate();

  const [otpData, setOtpData] = otpState;
  const [isResetVerified, setResetVerified] = resetState;
  const [isVerified, setVerified] = useState(false);
  const [interval, setIntervalId] = useState(null);
  const [showResend, setShowResend] = useState(false);

  let inputs = [];

  const [otp, setOtp] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [timer, setTimer] = useState("05:00");
  const { enqueueSnackbar } = useSnackbar();

  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const timerRef = useRef(null);
  const verification = useRef(null);

  useEffect(() => {
    if (!open) {
      clearInterval(interval);
      setIntervalId(null);
      setShowResend(false);
      setOver(false);
      setOtp("");
    } else {
      input1.current.value = "";
      input2.current.value = "";
      input3.current.value = "";
      input4.current.value = "";
      clearInterval(interval);
      startTimer(5 * 60);
    }
  }, [open]);

  useEffect(() => {
    setVid(otpData.vId);
  }, [otpData]);

  const handleInput = (target) => {
    if (/^\d+$/.test(target.value)) {
      getOTP();
      let nextInput = target.nextElementSibling;

      if (nextInput && target.value) {
        nextInput.focus();
        nextInput.value && nextInput.select();
      }
    } else {
      target.value = "";
    }
  };

  const handleBackspace = (target) => {
    getOTP();
    return target.value
      ? (target.value = "")
      : target.previousElementSibling?.focus();
  };

  const handleArrowLeft = (target) => {
    const previousInput = target.previousElementSibling;
    return !previousInput ? undefined : previousInput.focus();
  };
  const handleArrowRight = (target) => {
    const nextInput = target.nextElementSibling;
    return !nextInput ? undefined : nextInput.focus();
  };

  const handlePaste = (event) => {
    let pasteText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    const inputs = [
      input1.current,
      input2.current,
      input3.current,
      input4.current,
    ];

    inputs.forEach((input, index) => {
      input.value = pasteText[index] || ``;
      if (pasteText[index]) {
        input?.focus();
      }
    });
    getOTP();
    event.preventDefault();
  };

  useEffect(() => {
    setOtp("");
    autoFetch();
  }, []);

  const autoFetch = async () => {
    const signal = new AbortController();
    setTimeout(() => {
      signal.abort();
    }, 1 * 60 * 1000);

    if ("OTPCredential" in window) {
      try {
        if (navigator.credentials) {
          try {
            await navigator.credentials
              .get({ abort: signal, otp: { transport: ["sms"] } })
              .then((content) => {
                if (content && content.code) {
                  input1.current.value = content.code[0];
                  input2.current.value = content.code[1];
                  input3.current.value = content.code[2];
                  input4.current.value = content.code[3];

                  setOtp(content.code);
                }
              })
              .catch((e) => console.log(e));
          } catch (e) {
            return;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    // if ("OTPCredential" in window) {
    //   const ac = new AbortController();

    //   navigator.credentials
    //     .get({
    //       otp: { transport: ["sms"] },
    //       signal: ac.signal,
    //     })
    //     .then((otp) => {
    //       alert(otp);

    //       if (otp.length === 4) {
    //         input1.current.value = otp[0];
    //         input2.current.value = otp[1];
    //         input3.current.value = otp[2];
    //         input4.current.value = otp[3];

    //         setOtp(otp);
    //       }
    //       ac.abort();
    //     })
    //     .catch((err) => {
    //       // alert("Some error")
    //       ac.abort();
    //     });
    // }
  };

  const getOTP = () => {
    const inputs = [
      input1.current.value,
      input2.current.value,
      input3.current.value,
      input4.current.value,
    ];
    let temp = inputs[0] + inputs[1] + inputs[2] + inputs[3];
    setOtp(temp);
  };

  useEffect(() => {
    if (validateOtp()) {
      submitOTP();
    }
  }, [otp]);

  const validateOtp = () => {
    if (otp.length === 4 && /^\d+$/.test(otp)) {
      return true;
    }
    return false;
  };

  const requestOTP = async () => {
    if (otpData.method === "signup") {
      try {
        let payload = {
          data: {
            mobile_number: otpData.mobile,
          },
        };

        let { data } = await sentOtp(payload, "signin");

        if (data?.status) {
          setOtpData((st) => ({
            ...st,
            mobile: otpData.mobile,
            path: "home",
            method: "signup",
            vId: data?.data?.verificationId,
          }));
        }
      } catch (error) {
        enqueueSnackbar(error.response.data.errors[0].message, {
          variant: "error",
        });
      }
    } else if (
      otpData.method === "create-customer" ||
      otpData.method === "create-Cocustomer"
    ) {
      try {
        let payload = {
          applicationId: otpData?.application_id,
          method:
            otpData.method === "create-customer"
              ? "CREDIT_PULL_CONSENT_OTP"
              : "CO_CUSTOMER_CREDIT_PULL_CONSENT_OTP",
        };

        let { data } = await sentOtp(payload, "signin");

        if (data?.status) {
          setOtpData((st) => ({
            ...st,
            mobile: otpData.mobile,
            path:
              otpData.method === "create-customer"
                ? "create-loan/status"
                : "create-loan/status-cocustomer",
            method: otpData.method,
            vId: data?.data?.verificationId,
          }));
        }
      } catch (error) {
        enqueueSnackbar(error.response.data.errors[0].message, {
          variant: "error",
        });
      }
    } else if (otpData.method === "field-investigation") {
      let { data } = await fiSendOtp(
        otpData?.mobile,
        otpData?.application_id,
        otpData?.other_data
      );
      setOtpData({
        ...otpData,
        vId: data?.verificationId,
      });
    }
  };

  const submitOTP = async () => {
    setLoading(true);
    let payload = {
      otp,
      verificationId: vId,
    };

    if (
      otpData.method === "create-customer" ||
      otpData.method === "create-customer"
    ) {
      setTimeout(() => {
        setLoading(false);
        setLoadingCRE(true);
      }, 2000);
    }

    try {
      let { data } = await verifyOtp(payload);

      setLoading(false);
      setLoadingCRE(false);

      if (data?.status) {
        if (otpData.method === "signup") {
          setUser(data.data);
          navigate(`/${otpData.path}`);
        }
        if (otpData.method === "create-customer") {
          if (data?.is_coin_credited) {
            showReward({
              show: true,
              coin: data?.coin,
            });
          }
          navigate(`/${otpData.path}/${otpData?.application_id}`);
        }
        if (otpData.method === "create-Cocustomer") {
          if (data?.is_coin_credited) {
            showReward({
              show: true,
              coin: data?.coin,
            });
          }
          navigate(`/${otpData.path}/${otpData?.application_id}`);
        }
        if (otpData.method === "field-investigation") {
          setJourneyData({
            ...journeyData,
            loaded: true,
          });
          setOtpData({
            ...otpData,
            verified: true,
            open: false,
          });
          onComplete(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setLoadingCRE(false);
      setOtpData({ ...otpData, vId: "", verified: false, open: false });
      enqueueSnackbar(error.response?.data?.message, {
        variant: "error",
      });
    }
  };

  const handleResend = async () => {
    clearInterval(interval);
    setIntervalId(null);
    startTimer(5 * 60);
    setShowResend(false);
    setOver(false);
    setOtp("");
    input1.current.value = "";
    input2.current.value = "";
    input3.current.value = "";
    input4.current.value = "";
    await requestOTP();
    enqueueSnackbar(l("OTP resent successfully", lang), { variant: "success" });
  };

  function startTimer(duration, display) {
    var timer = duration,
      minutes,
      seconds;
    setIntervalId(
      setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        setTimer(minutes + ":" + seconds);

        if (Number(minutes) < 4) {
          setShowResend(true);
        }

        if (--timer === 0) {
          clearInterval(interval);
          setIntervalId(null);
          setTimer("00:00");
          setOver(true);
        }
      }, 1000)
    );
  }

  const handleInputSingle = (e, element) => {
    if (e.target.value !== "") return false;
    switch (e.key.toString().toLowerCase()) {
      case "backspace":
        return handleBackspace(e.target);
      case "arrowleft":
        return handleArrowLeft(e.target);
      case "arrowright":
        return handleArrowRight(e.target);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      {isLoading && (
        <div className="strip w-100" style={{ position: "absolute", top: 0 }}>
          &nbsp;
        </div>
      )}
      <DialogContent>
        {!isVerified && (
          <div className="verification-wrapper mt-0">
            <Grid container spacing={2} className="pb-2">
              <Grid item xs={12} sm={12}>
                <Box className="form-box">
                  <p className="mb-5 otp-text">
                    {l("An otp is sent your Mobile Number", lang)}
                    <b> {otpData?.mobile}</b>
                  </p>
                  <form class="verification w-100" ref={verification}>
                    <input
                      ref={input1}
                      type="number"
                      autoComplete="off"
                      class="verification__input  verification__input--1"
                      id="verification-input-1"
                      placeholder="-"
                      maxLength="1"
                      inputMode="numeric"
                      onKeyDown={(e) => handleInputSingle(e, inputs[0])}
                      onInput={(e) => handleInput(e.target)}
                      onPaste={(e) => handlePaste(e)}
                      disabled={isLoading}
                      autoFocus
                    />
                    <input
                      ref={input2}
                      type="number"
                      autoComplete="off"
                      class="verification__input  verification__input--2"
                      id="verification-input-2"
                      placeholder="-"
                      maxLength="1"
                      inputMode="numeric"
                      onKeyDown={(e) => handleInputSingle(e, inputs[1])}
                      onInput={(e) => handleInput(e.target)}
                      onPaste={(e) => handlePaste(e)}
                      disabled={isLoading}
                    />
                    <input
                      ref={input3}
                      type="number"
                      autoComplete="off"
                      class="verification__input  verification__input--3"
                      id="verification-input-3"
                      placeholder="-"
                      maxLength="1"
                      inputMode="numeric"
                      onKeyDown={(e) => handleInputSingle(e, inputs[2])}
                      onInput={(e) => handleInput(e.target)}
                      onPaste={(e) => handlePaste(e)}
                      disabled={isLoading}
                    />
                    <input
                      ref={input4}
                      type="number"
                      class="verification__input  verification__input--4"
                      id="verification-input-4"
                      autoComplete="off"
                      placeholder="-"
                      maxLength="1"
                      inputMode="numeric"
                      onKeyDown={(e) => handleInputSingle(e, inputs[3])}
                      onInput={(e) => handleInput(e.target)}
                      onPaste={(e) => handlePaste(e)}
                      disabled={isLoading}
                    />
                  </form>
                  <div className="verification_footer">
                    <p className="info">
                      {l("This Session will End in", lang)} {timer}.
                      <br />
                      {showResend && (
                        <>
                          Didn’t receive a code?{" "}
                          <span
                            className="pointer"
                            style={{ fontWeight: "500", color: "blue" }}
                            onClick={() => {
                              handleResend();
                            }}
                          >
                            Resend Code
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>
        )}

        {isVerified && (
          <div className="verification-div">
            <img src={successGif} width={"100%"} />
            <p className="text-center verified-text">Otp verified</p>
          </div>
        )}
        {isRunningCRE && <LoadingOver text={"Checking Eligibility"} />}
      </DialogContent>
    </Dialog>
  );
};

export default OtpLogin;
