import { Button, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { kycContext } from "../Contexts/KycContext";
import { UserContext } from "../Contexts/UserContext";
import { GlobalContext } from "../Contexts/GlobalContext";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import instructImg from "../assets/instruction.jpg";

const PermissionModal = ({ initial }) => {
  const navigate = useNavigate();

  const { showPermissionState, permissionState } = useContext(GlobalContext);
  const [permissions, setPermissions] = permissionState;
  const [showPermission, setShowPermission] = showPermissionState;

  const [camera, setCamera] = useState("");
  const [location, setLocation] = useState("");

  useEffect(() => {
    setCamera(getClass(permissions.camera));
    setLocation(getClass(permissions.location));
  }, [permissions]);

  const getClass = (value) => {
    switch (value) {
      case "DENIED":
        return "Denied";
      case "GRANTED":
        return "Allowed";
      case "PROMPT":
        return "Pending";
      default:
        return "Unsupported";
    }
  };

  return !initial ? (
    <>
      <div className="kyc-dialog">
        <div className="dialog permission-responsive">
          <p className="welcome text-center">Permissions Required</p>
          <p className="desc">We need the following permissions to function</p>
          <p className="desc w-100">
            <CameraAltIcon className="me-2" />
            Camera: <span className={camera}>{camera}</span>
          </p>
          <p className="desc w-100">
            <LocationOnIcon className="me-2" />
            Location: <span className={location}>{location}</span>
          </p>
          <p className="steps-head mb-3 mt-4">What to do next?</p>
          <img className="perm-instruction-img" src={instructImg} width="100%" alt="instruction" />
          <p className="desc w-100">
            Goto Sites settings and turn on permissions for camera and location
          </p>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="kyc-dialog">
        <div className="dialog permission-responsive">
          <p className="welcome text-center">Permissions Required</p>
          <p className="desc w-100">
            Please allow the following permissions to continue
          </p>
          <span className="desc w-100">
            <CameraAltIcon className="me-2" />
            Camera
          </span>
          <span className="desc w-100">
            <LocationOnIcon className="me-1 ms-3" />
            Location
          </span>
          <p className="mt-3">
            Click <b>"Allow"</b> when the pop-up shows
          </p>
          <Button
            className="w-100 mt-2"
            variant="contained"
            onClick={() => {
              showPermission.action();
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default PermissionModal;
