import { useEffect } from "react";
import { useRef, useState } from "react";
import InputMask from "react-input-mask";

function TimeInput(props) {
  
  let inputRef = useRef();
  useEffect(()=>{
    if(props?.ref){
      inputRef = props?.ref
    }
  },[props])

  let mask = "dD/mM/ytYY";
  let formatChars = {
    y: "[1-2]",
    t: "(9)",
    Y: "[0-9]",
    d: "[0-3]",
    D: "[0-9]",
    m: "[0-1]",
    M: "[1-9]",
  };

  let beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;

    let dateParts = value.split("/");
    let dayPart = dateParts[0];
    let monthPart = dateParts[1];
    let yearPart = dateParts[2];
    
  
    // Conditional mask for the 2nd digit of day based on the first digit
    if (dayPart?.startsWith("3"))
      formatChars["D"] = "[0-1]"; // To block 39, 32, etc.
    else if (dayPart.startsWith("0"))
      formatChars["D"] = "[1-9]"; // To block 00.
    else formatChars["D"] = "[0-9]"; // To allow 05, 15, 25  etc.

    // Conditional mask for the 2nd digit of month based on the first digit
    if (monthPart?.startsWith("1"))
      formatChars["M"] = "[0-2]"; // To block 15, 16, etc.
    else formatChars["M"] = "[1-9]"; // To allow 05, 06  etc - but blocking 00.

    if (yearPart?.startsWith("1")) formatChars["t"] = "(9)";
    else formatChars["t"] = "[0-9]";

    return { value, selection: newState.selection };
  };
  return (
    <InputMask
      ref={inputRef}
      inputMode="numeric"
      className={`form-input w-100 ${props?.blue && 'input-blue'}`}
      mask={mask}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      formatChars={formatChars}
      placeholder="dd/mm/yyyy"
      beforeMaskedValueChange={beforeMaskedValueChange}
    ></InputMask>
  );
}

export default TimeInput;
