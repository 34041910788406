import {
  BottomNavigation,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Dialog,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { l } from "../../util/languageConvertor";
import MenuAppBar from "../../components/Appbar";
import { GlobalContext } from "../../Contexts/GlobalContext";
// import TimeInput from "../../Components/DateInput";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConfettiExplosion from "react-confetti-explosion";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const radioStyle = {
  color: "#573A7A",
  "&.Mui-checked": {
    color: "#573A7A",
  },
};

const labelStyle = {
  color: "gray",
};

const inventoryData = [
  {
    name: "Citylife : XV 850",
    qty: 10,
    sales: 30,
    amount: 170000,
  },
  {
    name: "Citylife : XV",
    qty: 6,
    sales: 12,
    amount: 0,
  },
  {
    name: "Yatri: Deluxe",
    qty: 12,
    sales: 6,
    amount: 350000,
  },
  {
    name: "Yatri: Deluxe SS",
    qty: 7,
    sales: 10,
    amount: 0,
  },
];

const Inventory = forwardRef((props, ref) => {
  const { bottomNav, otpState, langState } = useContext(GlobalContext);
  const [otpData, setOtpData] = otpState;
  const [lang, setLang] = langState;
  const [qty, setQty] = useState(1);
  const [showExplode, setShowExplode] = useState(false);
  const [orderData, setOrderData] = useState({
    open: false,
    data: {},
  });

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/home/menu");
  };

  const handleOrder = (data) => {
    setOrderData({
      open: true,
      data,
    });
  };

  const handleClose = () => {
    setOrderData({
      open: false,
      data: {},
    });
    setQty(1);
  };

  const handleFinalSubmit = () => {
    setShowExplode(true);
    setTimeout(() => {
      setOrderData({
        ...orderData,
        open: false,
        data: {},
      });
      setQty(1)
      setShowExplode(false);
    }, 3000);
  };

  return (
    <>
      {showExplode && (
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 500000,
          }}
        >
          <ConfettiExplosion />
        </div>
      )}
      <div className="content-center">
        <Box
          className="kyc-wrapper"
          sx={{ pb: 7, pt: 7, position: "relative" }}
          ref={ref}
        >
          <MenuAppBar
            home={false}
            text={<p className="inventory_app_text">Inventory</p>}
            action={handleBack}
          />
          <CssBaseline />

          <div className="main-wrapper">
            <Grid container spacing={2}>
              {inventoryData?.map((val) => {
                return (
                  <Grid item xs={12} sm={12}>
                    <Paper>
                      <div className="inventory_card">
                        <p className="inventory_brand_name">{val?.name}</p>

                        <p className="inventory_features">
                          <span className="inventory_features_head">
                            Inventory as of now
                          </span>
                          <span className="inventory_value">{val?.qty}</span>
                        </p>
                        <p className="inventory_features">
                          <span className="inventory_features_head">
                            Sales in last 30 days
                          </span>
                          <span className="inventory_value">{val?.sales}</span>
                        </p>
                        <p className="inventory_features">
                          <span className="inventory_features_head">
                            Expected Amount Credited in next 7 days
                          </span>
                          <span className="inventory_value">{val?.amount}</span>
                        </p>
                        <Button
                          variant="outlined"
                          className="inventory_order_button"
                          onClick={() => {
                            handleOrder(val);
                          }}
                        >
                          Order now
                        </Button>
                      </div>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Box>
      </div>

      <Dialog open={orderData?.open}>
        <div className="inventory_quantiy_card">
          {showExplode? (
            <>
                <p className="text-center mb-0 mt-3"><CheckCircleIcon sx={{fontSize:100, color:'green'}}/></p>
               <p className="text-center mb-4 mt-2" style={{fontSize:20}}>Order Successfull!</p>
            </>
          ) : (
            <>
              <p className="inventory_quanity_head">Select the Quanity</p>
              <p className="inventory_quanity_text">{qty}</p>
              <div className="content-center">
                <Slider
                  sx={{ width: "75%" }}
                  aria-label="Temperature"
                  value={qty}
                  max={orderData?.data?.qty}
                  min={1}
                  color="secondary"
                  onChange={(event, newValue) => {
                    setQty(newValue);
                  }}
                />
              </div>
              <div className="content-center">
                <Button
                  className="inventory_quanity_button inventory_quanity_button inventory_button_cancel me-2"
                  variant="contained"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="inventory_quanity_button"
                  variant="contained"
                  onClick={() => {
                    handleFinalSubmit();
                  }}
                >
                  Proceed
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
});

export default Inventory;
