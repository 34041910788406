import {
  BottomNavigation,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ageValidate, validateDob } from "../../../validations/dateValidations";
import { createCoCustomer, createCustomer } from "../../../api";
import MenuAppBar from "../../../components/Appbar";
import TimeInput from "../../../components/DateInput";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import { updateAddress, verifyPincode } from "../../../api/agent";
import useApplication from "../../../hooks/useApplication";
import CustomInputAutocomplete from "../../../components/AutoComplete";
import {
  isBlank,
  isEmailValid,
  isMobValid,
  isStartSpace,
} from "../../../validations/fieldValidations";
import PsychometricTest from "./PsychometricTest";
import RewardFloating from "../../../components/RewardFloating";
import RewardWallet from "../../../components/V2/Reward/RewardWallet";
import { l } from "../../../util/languageConvertor";
import WebView from "../../../components/V3/WebView";
import useErrorMessage from "../../../hooks/useErrorMessage";
import ErrorMessage from "../../../components/V3/ErrorMessage";
import { UserContext } from "../../../Contexts/UserContext";
import ApptourTxt from "../../../constants/Apptours";
import AppTour from "../AppTour/AppTour.js";
import ATPanDetails1 from "../AppTour/PanDetails/ATPanDetails1";
import ATPanDetails2 from "../AppTour/PanDetails/ATPanDetails2";

import NumberAlert from "../../../components/NumberAlert";
import MixPanelAudit from "../../../util/MixPanelAudit";
import BankListComponent from "../../../components/Autocomplete/BankListAuto";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const radioStyle = {
  color: "#573A7A",
  "&.Mui-checked": {
    color: "#573A7A",
  },
};

const labelStyle = {
  color: "gray",
};

const PanDetails = forwardRef((props, ref) => {
  const { bottomNav, otpState, langState, globalLoaderState } =
    useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const [otpData, setOtpData] = otpState;
  const [lang, setLang] = langState;
  const { showMessage, clearMessage } = useErrorMessage();
  const [globalLoader, setGlobalLoader] = globalLoaderState;
  const [showappTour, setShowAppTour] = useState(true);
  const [apptourindex, SetApptourIndex] = useState(0);
  const [showconnectedNumberAlert, setShowConnectedNumberAlert] =
    useState(null);
  const res_width = window.innerWidth

  const refs = {
    dob: useRef(null),
    aadhaar_address: useRef(null),
    aadhaar_pincode: useRef(null),
    current_address: useRef(null),
    current_pincode: useRef(null),
    // is_property_owned: useRef(null),
    is_aadhaar_property_owned: useRef(null),
    mobile_number: useRef(null),
    gender: useRef(null),
    nachable_bank_name: useRef(null),
    // email: useRef(null),
  };

  const [isPsycho, setPsycho] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fieldErrors, setErrors] = useState({ dob: "" });

  const { application_id } = useParams();
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [showReward, setShowReward] = useState(false);
  const [showRewardInfo, setShowRewardInfo] = useState(false);
  const [webviewData, setWebView] = useState({ open: false, url: "" });
  const [openBank, setOpenBank] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleInput = (e, setState) => {
    let result = validateDob(e.target.value);
    setState((st) => ({ ...st, date_of_birth: result }));
  };

  const validDate = (date, key) => {
    if (date.length === 0 || date?.includes("_")) {
      return "Provide a valid date";
    } else {
      // if (key === "dob") {
      //   if (date.length > 0 && !date?.includes("_")) {
      //     return ageValidate(journeyData.date_of_birth);
      //   }
      // }

      return "";
    }
  };

  const isValid = async () => {
    let valid = true;
    let errors = {
      dob: validDate(journeyData.date_of_birth, "dob"),
      aadhaar_address: isBlank(journeyData?.aadhaar_address),
      aadhaar_pincode: await isPinValid(journeyData?.aadhaar_pincode),
      current_address:
        ![null, undefined, ""].includes(journeyData?.is_address_same) &&
        journeyData?.is_address_same
          ? ""
          : isBlank(journeyData?.current_address),
      current_pincode:
        ![null, undefined, ""].includes(journeyData?.is_address_same) &&
        journeyData?.is_address_same
          ? ""
          : await isPinValid(journeyData?.current_pincode),
      // is_property_owned:
      //   ![null, undefined, ""].includes(journeyData?.is_address_same) &&
      //   journeyData?.is_address_same
      //     ? ""
      //     : [null, undefined, ""].includes(journeyData?.is_property_owned)
      //     ? "Select any option"
      //     : "",
      is_aadhaar_property_owned: [null, undefined, ""].includes(
        journeyData?.is_aadhaar_property_owned
      )
        ? "Select any option"
        : "",
      mobile_number: isMobValid(journeyData?.mobile_number),
      gender: [null, undefined, ""].includes(journeyData?.gender)
        ? "Select gender"
        : "",
      // email: isEmailValid(journeyData?.email_id),
      current_resident_owned_by: [null, undefined, ""].includes(
        journeyData?.current_resident_owned_by
      )
        ? "Select any option"
        : "",
      nachable_bank_name: isBlank(journeyData?.nachable_bank_name),
    };

    // console.log(errors);

    setErrors((prev) => ({ ...prev, ...errors }));

    // if (state.full_name === "" || state.pan_number === "") valid = false;
    let first = false;
    for (let i in errors) {
      if (errors[i].length > 0) {
        if (!first) {
          first = true;
          refs[i]?.current?.focus();
          window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
        }
        valid = false;
      }
    }

    return valid;
  };

  const isPinValid = async (pincode, key) => {
    try {
      let { data } = await verifyPincode(pincode);
      if (Object.keys(data).length !== 0) {
        return "";
      } else {
        return "Invalid pincode";
      }
    } catch (error) {
      return "Pincode validation failed";
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    MixPanelAudit.intercept('customer_check_eligibility', {
      application_id,
    });
    if (await isValid()) {
      if (journeyData?.credit_pull_allow) {
        let payload = {
          dob: journeyData?.date_of_birth,
          aadhaar_pincode: journeyData?.aadhaar_pincode + "",
          aadhaar_address: journeyData?.aadhaar_address,
          same_address_as_aadhaar: journeyData?.is_address_same,
          // is_property_owned: journeyData?.is_address_same
          //   ? journeyData?.is_aadhaar_property_owned
          //   : journeyData?.is_property_owned,
          is_aadhaar_address_owned: journeyData?.is_aadhaar_property_owned,
          mobile_number: journeyData?.mobile_number + "",
          // email: journeyData?.email_id,
          credit_consent: journeyData?.credit_pull_allow,
          residential_address: journeyData?.current_address + "",
          residential_pincode: journeyData?.current_pincode + "",
          gender: journeyData?.gender,
          customer_id: journeyData?.customer_id,
          is_current_resident_owned_by: journeyData?.current_resident_owned_by,
          nachable_bank_name: journeyData?.nachable_bank_name,
        };

        // console.log("PanDetails-payload", payload);

        try {
          let { data } = await updateAddress(payload, application_id);
          if (data?.status) {
            setOtpData((st) => ({
              ...st,
              mobile: journeyData?.mobile_number,
              path: "create-loan/status",
              method: "create-customer",
              vId: data?.verificationId,
              application_id,
            }));
            navigate(`/sent-otp/${data?.verificationId}`);
          }
        } catch (err) {
          let error = "";
          if (err?.response?.data?.message) {
            if (err?.response?.data?.message === "Bad request parameters") {
              error = "Please re-check all the inputs";
            } else {
              error = err?.response?.data?.message;
            }
          } else {
            error = "Something went wrong";
          }

          showMessage(l(error, lang));
          // enqueueSnackbar(l(error, lang), { variant: "error" });
        }
      } else {
        // showMessage(l("Please accept the credit pull consent", lang));
        enqueueSnackbar(l("Please accept the credit pull consent", lang));
      }
    }
    setLoading(false);
  };

  const handleBack = async () => {
    navigate("/draft-applications");
  };

  const { getApplicationDetails } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/draft-applications");
    };
  });
// console.log("res_width" , res_width , res_width > 425 ? "-80%" : '-120%')

  // console.log(
  //   "journeyData?.aadhaar_connected_number",
  //   !journeyData?.aadhaar_connected_number,
  //   ["API Error", null, ""].includes(journeyData?.aadhaar_connected_number)
  // );
  return !isPsycho ? (
    // return true ? (
    <>
      <Box
        className="kyc-wrapper"
        sx={{ pb: 7, pt: 7, position: "relative" }}
        ref={ref}
      >
        <RewardFloating
          isOpen={showReward && !journeyData?.is_psychometric_done}
          showInfo={showRewardInfo}
          action={() => setShowRewardInfo(false)}
          onClick={() => {
            setPsycho(true);
            setShowReward(false);
          }}
        />

        <MenuAppBar
          bigText={"Applicant's Basic Details"}
          home={false}
          action={handleBack}
        />
        <CssBaseline />
        <ErrorMessage />
        <div className="main-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Name (As per PAN)", lang)}
              </label>
              <input
                className="form-input w-100"
                autoComplete="off"
                name="firstName"
                fullWidth
                disabled
                placeholder="Enter your first name"
                autoFocus
                value={journeyData.full_name}
              />
              <span className="error"></span>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">{l("PAN Number", lang)}</label>
              <input
                className="form-input w-100"
                required
                fullWidth
                disabled
                placeholder="Enter your PAN number"
                autoComplete="off"
                value={journeyData.pan_number}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">
                {l("Date of Birth", lang)} <span className="mand-star">*</span>
              </label>

              <TimeInput
                ref={refs.dob}
                value={journeyData.date_of_birth}
                onChange={(e) => {
                  clearMessage();
                  setErrors({ ...fieldErrors, dob: "" });
                  handleInput(e, setJourneyData);
                }}
                onBlur={() => {
                  if (journeyData.date_of_birth === "__/__/____")
                    setJourneyData((st) => ({ ...st, date_of_birth: "" }));
                  setErrors((prev) => ({
                    ...prev,
                    date_of_birth: validDate(journeyData.date_of_birth, "dob"),
                  }));
                }}
              ></TimeInput>

              {/* <input
                    className="form-input w-100"
                    required
                    id="dob"
                    disabled
                    placeholder=""
                    name="dob"
                    autoComplete="off"
                    // value={pPanDetails.dob}
                  /> */}

              <span className="error">{fieldErrors.dob}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                {l("Gender", lang)} <span className="mand-star">*</span>
              </label>
              <RadioGroup
                ref={refs.gender}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={journeyData.gender}
                onChange={(e) => {
                  clearMessage();
                  setErrors({ ...fieldErrors, gender: "" });
                  setJourneyData({
                    ...journeyData,
                    gender: e.target.value,
                  });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"Male"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("Male", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"Female"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("Female", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">{fieldErrors.gender}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                {l("Select Nachable bank account", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <div className="d-flex" style={{ position: "relative" }}>
                <input
                  ref={refs?.nachable_bank_name}
                  className="form-input w-100 form-input-bank"
                  style={{ cursor: "pointer" }}
                  required
                  fullWidth
                  // disabled
                  placeholder="Select Nachable bank account"
                  value={journeyData.nachable_bank_name}
                  onClick={() => {
                    refs?.nachable_bank_name?.current?.blur();
                    setOpenBank(true);
                  }}
                />
                <div style={{ position: "absolute", right: 10, top: 10 }}>
                  <ArrowDropDownIcon />
                </div>
              </div>
              <BankListComponent
                open={openBank}
                handleClose={() => {
                  setOpenBank(false);
                  refs?.nachable_bank_name?.current?.blur();
                }}
                value={journeyData.nachable_bank_name}
                onChange={(value) => {
                  clearMessage();
                  setErrors({ ...fieldErrors, nachable_bank_name: "" });
                  setJourneyData({
                    ...journeyData,
                    nachable_bank_name: value?.label,
                  });
                }}
              />

              <span className="error">{fieldErrors?.nachable_bank_name}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Aadhaar Address", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <textarea
                ref={refs.aadhaar_address}
                className="form-input-area w-100"
                required
                fullWidth
                placeholder={l("Aadhaar Address", lang)}
                autoComplete="off"
                value={journeyData.aadhaar_address}
                onChange={(e) => {
                  clearMessage();
                  setErrors({ ...fieldErrors, aadhaar_address: "" });
                  setJourneyData({
                    ...journeyData,
                    aadhaar_address: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.aadhaar_address}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Aadhaar Pincode", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <input
                ref={refs.aadhaar_pincode}
                className="form-input w-100"
                required
                fullWidth
                placeholder={l("Aadhaar Pincode", lang)}
                autoComplete="off"
                value={journeyData.aadhaar_pincode}
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value.length > 6) return false;
                  clearMessage();
                  setErrors({ ...fieldErrors, aadhaar_pincode: "" });
                  setJourneyData({
                    ...journeyData,
                    aadhaar_pincode: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.aadhaar_pincode}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                {l("Is this aadhaar property owned by customer?", lang)}
                <span className="mand-star">*</span>
              </label>
              <RadioGroup
                ref={refs.is_aadhaar_property_owned}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={
                  journeyData.is_aadhaar_property_owned !== null
                    ? journeyData.is_aadhaar_property_owned
                      ? "yes"
                      : "no"
                    : null
                }
                onChange={(e) => {
                  MixPanelAudit.intercept("current_address_same_aadhaar", {
                    val: e.target?.value === "yes" ? true : false,
                    type: journeyData?.co_customer_type === "CO-APPLICANT"
                        ? "co_applicant"
                        : "co_gaurantor",
                    application_id,
                  });
                  setJourneyData({
                    ...journeyData,
                    is_aadhaar_property_owned:
                      e.target?.value === "yes" ? true : false,
                  });
                  clearMessage();
                  setErrors({
                    ...fieldErrors,
                    is_aadhaar_property_owned: "",
                  });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("Yes", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"no"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("No", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">
                {fieldErrors.is_aadhaar_property_owned}
              </span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={<Checkbox checked={journeyData?.is_address_same} />}
                label={l("Same As Aadhaar Address", lang)}
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    is_address_same: e.target.checked,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Current Residential address", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <textarea
                ref={refs.current_address}
                className="form-input-area w-100"
                required
                fullWidth
                placeholder={l("Current Residential address", lang)}
                autoComplete="off"
                disabled={journeyData?.is_address_same}
                value={
                  journeyData?.is_address_same
                    ? journeyData?.aadhaar_address
                    : journeyData?.current_address
                }
                onChange={(e) => {
                  clearMessage();
                  setErrors({ ...fieldErrors, current_address: "" });
                  setJourneyData({
                    ...journeyData,
                    current_address: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.current_address}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Current Residential pincode", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <input
                ref={refs.current_pincode}
                className="form-input w-100"
                required
                fullWidth
                disabled={journeyData?.is_address_same}
                placeholder={l("Current Residential pincode", lang)}
                autoComplete="off"
                value={
                  journeyData?.is_address_same
                    ? journeyData?.aadhaar_pincode
                    : journeyData?.current_pincode
                }
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value.length > 6) return false;
                  clearMessage();
                  setErrors({ ...fieldErrors, current_pincode: "" });
                  setJourneyData({
                    ...journeyData,
                    current_pincode: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.current_pincode}</span>
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Current Residential is owned by", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <CustomInputAutocomplete
                text={l("Current Residential is owned by", lang)}
                options={[
                  "Self",
                  "Husband",
                  "Wife",
                  "Mother",
                  "Father",
                  "Son",
                  "Daughter",
                  "Others",
                ]}
                onChange={(val) => {
                  setErrors({ ...fieldErrors, current_resident_owned_by: "" });
                  setJourneyData({
                    ...journeyData,
                    current_resident_owned_by: val,
                  });
                }}
              />
              <span className="error">
                {fieldErrors.current_resident_owned_by}
              </span>
            </Grid>

            {/* <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                {l("Is current address property owned by customer?", lang)}
                <span className="mand-star">*</span>
              </label>
              <RadioGroup
                ref={refs.is_property_owned}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                disabled={journeyData?.is_address_same}
                value={
                  journeyData?.is_address_same
                    ? journeyData.is_aadhaar_property_owned !== null
                      ? journeyData.is_aadhaar_property_owned
                        ? "yes"
                        : "no"
                      : null
                    : journeyData.is_property_owned !== null
                    ? journeyData.is_property_owned
                      ? "yes"
                      : "no"
                    : null
                }
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    is_property_owned: e.target?.value === "yes" ? true : false,
                  });
                  clearMessage();
                  setErrors({ ...fieldErrors, is_property_owned: "" });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("Yes", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"no"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("No", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">{fieldErrors.is_property_owned}</span>
            </Grid> */}

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                {l("Mobile Number", lang)} <span className="mand-star">*</span>
              </label>
              {showconnectedNumberAlert !== false ? (
                <>
                  <div
                    className="w-100"
                    style={{
                      padding: "15px",
                      color: "rgba(126, 136, 173, 1)",
                      curser: "pointer",
                      backgroundColor: "#f3f8fd",
                      borderRadius: "3px",
                      border: "none",
                      height: "50px",
                    }}
                    onClick={() => {
                      setShowConnectedNumberAlert(true);
                    }}
                  >
                    {!journeyData?.aadhaar_connected_number ||
                    ["API Error", null, ""].includes(
                      journeyData?.aadhaar_connected_number
                    )
                      ? "0000-000-000"
                      : `0000-000-${journeyData?.aadhaar_connected_number?.slice(
                          -3
                        )}`}
                  </div>
                </>
              ) : (
                <>
                  <input
                    ref={refs.mobile_number}
                    type="text"
                    className="form-input w-100"
                    required
                    fullWidth
                    placeholder={
                      !journeyData?.aadhaar_connected_number ||
                      ["API Error", null, ""].includes(
                        journeyData?.aadhaar_connected_number
                      )
                        ? `0000-000-000`
                        : `0000-000-${journeyData?.aadhaar_connected_number?.slice(
                            -3
                          )}`
                    }
                    name="mobile-number"
                    inputMode="numeric"
                    autoComplete="off"
                    maxLength={10}
                    value={journeyData.mobile_number}
                    onChange={(e) => {
                      if (
                        e.target.value !== "" &&
                        !/^\d+$/.test(e.target.value)
                      ) {
                        return false;
                      }
                      if (e.target.value.length > 10) return false;
                      setErrors({ ...fieldErrors, mobile_number: "" });
                      clearMessage();
                      setJourneyData((st) => ({
                        ...st,
                        mobile_number: e.target.value,
                      }));
                    }}
                    onKeyUp={
                      (e) => {}
                      // setFieldErrors((st) => ({
                      //   ...st,
                      //   mobile: isMobValid(e.target.value),
                      // }))
                    }
                  />
                  <span className="error">{fieldErrors.mobile_number}</span>
                </>
              )}
            </Grid>

            {/* <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">{l("Email Id", lang)}</label>
              <input
                ref={refs.email}
                type="text"
                className="form-input w-100 px-3"
                required
                placeholder={l("Email Id", lang)}
                style={{ fontSize: "11.5px" }}
                name="email"
                autoComplete="off"
                value={journeyData.email_id}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, email: "" });
                  clearMessage();
                  setJourneyData((st) => ({
                    ...st,
                    email_id: e.target.value,
                  }));
                }}
                // onKeyUp={(e) => {
                //   setErrors((st) => ({
                //     ...st,
                //     email: isEmailValid(e.target.value),
                //   }));
                // }}
              />
              <span className="error">{fieldErrors.email}</span>
            </Grid> */}

            <Grid item xs={12} sm={12}>
              <FormControlLabel
                className="mb-2"
                control={<Checkbox color="success" />}
                onChange={(e) => {
                  clearMessage();
                  setJourneyData({
                    ...journeyData,
                    credit_pull_allow: e.target.checked,
                  });
                  // if (!showReward) {
                  //   setShowReward(true);
                  //   setShowRewardInfo(true);
                  // }
                }}
                checked={journeyData?.credit_pull_allow}
                label={
                  <span className="checkbox-txt" style={{ fontSize: 12 }}>
                    I hereby authorize Embifi to pull my credit report in
                    accordance with the{" "}
                    <Link
                      onClick={(e) => {
                        setWebView({
                          open: true,
                          url: "https://www.embifi.in/terms",
                        });
                        e.preventDefault();
                      }}
                    >
                      Terms of Use
                    </Link>{" "}
                    and the{" "}
                    <Link
                      onClick={(e) => {
                        setWebView({
                          open: true,
                          url: "https://www.embifi.in/privacy-policy",
                        });
                        e.preventDefault();
                      }}
                    >
                      {l("Privacy Policy", lang)}
                    </Link>{" "}
                    of the Company
                  </span>
                }
              />
            </Grid>
          </Grid>
        </div>

        {/* {!journeyData?.is_psychometric_done && showReward && (
          <div style={{ position: 'absolute', background: 'rgba(0,0,0,0.5)',width: "100%", height: '100%' }}>
            <RewardFloating
              isOpen={journeyData?.credit_pull_allow}
              // action={handlePsycho}
              action={() => setShowReward(false)}
              // action={() => alert('false')}
              onClick={() => {
                setPsycho(true);
                setShowReward(false);
              }}
            />
          </div>
        )} */}

        <Paper className={`paper-bottom`} ref={bottomNav} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    {l("Loading", lang)}
                  </>
                ) : (
                  l("Check Eligibility", lang)
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>

      {!globalLoader && !user?.seen_app_tour?.CUSTOMER_BASIC_DETAILS_SCREEN && (
        <AppTour
          screenName={"CUSTOMER_BASIC_DETAILS_SCREEN"}
          texts={[ApptourTxt.details_for_aadhar, ApptourTxt.customer_living]}
          index={apptourindex}
          setIndex={SetApptourIndex}
          show={showappTour}
          screenContent={[
            <ATPanDetails1
              lang={lang}
              posStyle={{
                ATIbody: "panDetailsAT1",
                ATIpointer: "panDetailsATP",
              }}
            />,
            <ATPanDetails2
              lang={lang}
              posStyle={{
                ATIbody: "panDetailsAT2",
                ATIpointer: "panDetailsATP",
              }}
            />,
          ]}
          setShow={setShowAppTour}
        />
      )}
      <NumberAlert
        type={
          !journeyData?.aadhaar_connected_number ||
          ["API Error", null, ""].includes(
            journeyData?.aadhaar_connected_number
          )
        }
        show={showconnectedNumberAlert}
        setShow={setShowConnectedNumberAlert}
        connectedNumber={journeyData?.aadhaar_connected_number?.slice(-3)}
      />

      <WebView
        open={webviewData?.open}
        url={webviewData?.url}
        handleClose={() => {
          setWebView({ open: false, url: "" });
        }}
      />
    </>
  ) : (
    <PsychometricTest
      onClick={() => {
        setShowReward(true);
        setPsycho(false);
      }}
    />
  );
});

export default PanDetails;
