import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../Contexts/UserContext";
import { useContext } from "react";
import { logout } from "../../../api/agent";
import { GlobalContext } from "../../../Contexts/GlobalContext";

export default function useLogout() {
  let history = useNavigate();

  const { setUser } = useContext(UserContext);
  const { globalLoaderState } = useContext(GlobalContext);
  const [globalLoader, setGlobalLoader] = globalLoaderState;

  const logoutUser = async () => {
    setGlobalLoader(true);
    try {
      await logout({
        fcm_token: localStorage?.getItem("tck") || "",
      });
      setUser(null);
    } catch (err) {
      console.log(err);
    }
    setGlobalLoader(false);
  };

  return {
    logoutUser,
  };
}
