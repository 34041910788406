import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useErrorMessage from "../../hooks/useErrorMessage";

const ErrorMessage = () => {
  const { errorDataState } = useContext(GlobalContext);
  const [errorData, setErrorData] = errorDataState;
  const navigate = useNavigate();
  const { clearMessage } = useErrorMessage();

  useEffect(() => {
    if (errorData?.show) {
      window.scrollTo(0, 0);
    }
  }, [errorData?.show]);

  useEffect(() => {
    if (errorData?.show) {
      window.scrollTo(0, 0);
    }
  }, [errorData?.show]);

  useEffect(() => {
    clearMessage();
  }, [navigate]);

  return (
    <div className={`content-center mb-0 ${!errorData?.show && "d-none"}`}>
      <div className="w-100 error-message-wrapper">
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <p
                className="mb-0"
                style={{
                  marginTop: 20,
                  background: "#FAD3D9",
                  width: "100%",
                  textAlign: "center",
                  padding: "20px 30px",
                  color: "red",
                  wordBreak: "break-word",
                }}
              >
                {errorData?.message}
              </p>
            </Grid>
          </Grid>
        </>
      </div>
    </div>
  );
};

export default ErrorMessage;
