import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/main.scss";
import "./sass/responsive.scss";
import { SnackbarProvider } from "notistack";
import { Fade } from "@mui/material";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import GlobalContextProvider from "./Contexts/GlobalContext";
import GlobalLoader from "./components/V3/GlobalLoader";
import "./demo/style/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // Syncup line
  // <React.StrictMode>

  <SnackbarProvider
    maxSnack={2}
    TransitionComponent={Fade}
    autoHideDuration={2000}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
  >
    <GlobalContextProvider>
      <App />
      <GlobalLoader />
    </GlobalContextProvider>
  </SnackbarProvider>
  // </React.StrictMode>
);


