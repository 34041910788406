import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import customerIco from "../../../../assets/borrowerIco.svg";
import coCustomerIco from "../../../../assets/coCustomerIco.svg";
import ButtonComp from "../../../../components/V3/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import infoIco from "../../../../assets/infoIco.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { esignLoanAgreement } from "../../../../api/agent";
import { useContext } from "react";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import { useSnackbar } from "notistack";
import { environment } from "../../../../config";
import LoadingOver from "../../../../components/LoadingOver";
import { l } from "../../../../util/languageConvertor";
import { GlobalContext } from "../../../../Contexts/GlobalContext";

const EsignContent = ({ setContent, handleClose }) => {
  const [info, setInfo] = useState(true);
  const { journeyState } = useContext(LoanContext);
  const { langState } = useContext(GlobalContext);
  const [journeyData, setJourneyData] = journeyState;
  const [lang, setLang] = langState;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);

  const handleEsign = async (type) => {
    let redirectUrl;
    if (environment === "local") {
      redirectUrl =
        "http://" +
        window.location.host +
        `/create-loan/agreement-status/${journeyData?.application_id}/${type}/${journeyData?.co_customer_exist ? "5":"4"}`;
    } else if (environment === "development") {
      redirectUrl = `https://www.dev.erick.embifi.in/create-loan/agreement-status/${journeyData?.application_id}/${type}/${journeyData?.co_customer_exist ? "5":"4"}`;
    }  else if (environment === "demo") {
      redirectUrl = `https://www.demo.erick.embifi.in/create-loan/agreement-status/${journeyData?.application_id}/${type}/${journeyData?.co_customer_exist ? "5":"4"}`;
    }
    else {
      if(window.location.host?.includes("prod-demo.erick")){
        redirectUrl = `https://www.prod-demo.erick.embifi.in/create-loan/agreement-status/${journeyData?.application_id}/${type}/${journeyData?.co_customer_exist ? "5":"4"}`;
      }else{
        redirectUrl = `https://www.erick.embifi.in/create-loan/agreement-status/${journeyData?.application_id}/${type}/${journeyData?.co_customer_exist ? "5":"4"}`;
      }
    }
    setLoading(true);
    try {
      let payload = {
        application_id: journeyData?.application_id,
        // application_id: "EMAPL09052300000464",
        redirectUrl,
      };
      if (type === "customer") {
        payload = { ...payload, customer: true };
      } else {
        payload = { ...payload, co_customer: true };
      }
      let { data } = await esignLoanAgreement(payload);
      window.open(data?.webUrl, "_self");
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.message || "Something went wrong!",
        {
          variant: "error",
        }
      );
    }
      setLoading(false);
  };

  return (
    <>
      {!info ? (
        <>
          <DialogTitle id="alert-dialog-title">
            <ArrowBackIcon
              className="pointer"
              onClick={() => {
                setContent("initial");
                setInfo(true);
              }}
            />{" "}
            E-sign
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 5,
                cursor: "pointer",
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={2}
              className={`${
                !journeyData?.co_customer_exist && "content-center"
              } mt-2`}
            >
              <Grid item xs={6}>
                <ButtonComp
                  icon={customerIco}
                  isDone={journeyData?.esign_status?.customer?.signed}
                  text={"Borrower"}
                  action={() => {
                    handleEsign("customer");
                  }}
                />
              </Grid>
              {journeyData?.co_customer_exist && (
                <Grid item xs={6}>
                  <ButtonComp
                    disabled={!journeyData?.esign_status?.customer?.signed}
                    icon={coCustomerIco}
                    isDone={journeyData?.esign_status?.co_customer?.signed}
                    text={l("Co-Borrower",lang)}
                    action={() => {
                      handleEsign("co-customer");
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title">
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 5,
                cursor: "pointer",
              }}
            >
              <CloseIcon onClick={() => handleClose()} />
            </div>
          </DialogTitle>
          <DialogContent>
            <p className="text-center">
              <img src={infoIco} />
            </p>
            <p
              className="text-center"
              style={{
                fontWeight: 700,
                fontSize: 25,
              }}
            >
              {l("NOTE",lang)}
            </p>
            <p className="text-center">
            {l("Make sure Aadhaar and phone no. of applicant are linked",lang)}
            </p>
            <div className="content-center">
              <Button className="btn-submit" onClick={() => setInfo(false)}>
              {l("Proceed",lang)}
              </Button>
            </div>
          </DialogContent>
        </>
      )}
      {isLoading && <LoadingOver text={"Redirecting"} />}
    </>
  );
};

export default EsignContent;
