import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuAppBar from "../../../components/Appbar";
import successIco from "../../../assets/success-badge.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import ConfettiExplosion from "react-confetti-explosion";
import { l } from "../../../util/languageConvertor";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import UploadCard from "../../../components/V3/UploadCard";
import rcIcon from "../../../assets/rcIcon.svg";
import recieptIcon from "../../../assets/recieptIcon.svg";
import moment from "moment";
import { uploadDocument } from "../../../api/agent";
import useApplication from "../../../hooks/useApplication";
import LoadingOver from "../../../components/LoadingOver";
import DocumentCard from "../../../components/V3/DocumentCard";
import MixPanelAudit from "../../../util/MixPanelAudit";
import ErrorMessage from "../../../components/V3/ErrorMessage";
import useErrorMessage from "../../../hooks/useErrorMessage";
import { UserContext } from "../../../Contexts/UserContext";

// import TimeInput from "../../Components/DateInput";

const bodyStyle = `
body {
    background: rgb(30,22,171);
    background: linear-gradient(333deg, rgba(30,22,171,1) 0%, rgba(23,23,189,1) 35%, rgba(0,212,255,1) 100%) fixed;
}
`;

export const PostDisbursement = forwardRef(({ text, action }, ref) => {
  const { bottomNav, langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [isLoading, setLoading] = useState(false);
  const { showMessage, clearMessage } = useErrorMessage();
  const { application_id } = useParams();
  const toggleButton = localStorage.getItem("schedule_tab_show");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getApplicationDetails } = useApplication();

  const [rc, setRc] = useState("");
  const [courierRc, setCourierRc] = useState("");
  const { user } = useContext(UserContext);

  const handleBack = () => {
    navigate("/draft-applications");
  };

  const fc = (val) => {
    if (!val || isNaN(val)) return "NA";
    return Number(val).toLocaleString("en-IN");
  };

  const style = {
    reviewTxt: {
      fontSize: "30px",
      fontWeight: "500",
    },
    tagTxt: {
      padding: "0 20px",
    },
    coNote: {
      backgroundColor: "#ffffff3d",
      padding: "20px",
      borderRadius: "10px",
      fontWeight: "300",
    },
  };

  const handleUpload = async (file, name) => {
    setLoading(true);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name,
              file,
            },
          ],
        },
        application_id,
        `?override=true`
      );
      getApplicationDetails(application_id, true);
    } catch (error) {
      showMessage(
        l(error?.response?.data?.message || "Some error while uploading", lang)
      );
    }
    setLoading(false);
  };

  return (
    <>
      <Box className="w-100" sx={{ pb: 7, pt: 3 }} ref={ref}>
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div className="mt-4">
          <ErrorMessage />
        </div>
        <div className="main-wrapper w-100">
          <>
            <Grid container spacing={2} className="mt-1">
              <Grid item xs={12} sm={12} className="mb-3">
                <p className="post-heading">
                  {l("Loan Amount credited", lang)}
                </p>

                <div className="post-loan-card">
                  <div className="row-item mb-3">
                    <span>{l("Date of disbursal", lang)}</span>
                    <span>
                      {journeyData?.disbursed_date
                        ? moment(new Date(journeyData?.disbursed_date)).format(
                            "DD-MM-YYYY"
                          )
                        : "NA"}
                    </span>
                  </div>
                  <div className="row-item mb-3">
                    <span>{l("Amount", lang)}</span>
                    <span>{fc(journeyData?.disbursed_amount)}</span>
                  </div>
                  <div className="row-item">
                    <span>{l("UTR No.", lang)}</span>
                    <span>{journeyData?.utr || "NA"}</span>
                  </div>
                </div>
              </Grid>

              {/* <Grid item xs={12} sm={12} className="mb-3">
                <p className="post-heading">Document needed</p>
                <UploadCard
                  tempState={""}
                  text={"Upload RC"}
                  iconImg={rcIcon}
                  isDone={
                    journeyData?.documents?.registration_certificate?.length > 0
                  }
                  setFile={(file) => {
                    handleUpload(file, "registration_certificate");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} className="mb-3">
                <UploadCard
                  tempState={""}
                  text={"Upload Courier receipt"}
                  iconImg={recieptIcon}
                  isDone={journeyData?.documents?.courier_receipt?.length > 0}
                  setFile={(file) => {
                    handleUpload(file, "courier_receipt");
                  }}
                />
              </Grid> */}

              <Grid item xs={12} sm={12} className="py-0 pt-1">
                <p className="post-heading">{l("Document needed", lang)}</p>
              </Grid>
              <Grid item xs={6} sm={6} className="mb-3 pt-0">
                <DocumentCard
                  text={l("Upload RC", lang)}
                  icon={rcIcon}
                  isRequired={true}
                  tempState={
                    journeyData?.documents?.registration_certificate?.[0]
                      ?.fileLink || ""
                  }
                  isButton={true}
                  isUpload={
                    journeyData?.documents?.registration_certificate?.length > 0
                  }
                  status={
                    journeyData?.documents?.registration_certificate?.[0]
                      ?.isApproved
                  }
                  // isLoading={loading === "co_customer_dl"}
                  setFile={(file) => {
                    handleUpload(file, "registration_certificate");
                  }}
                  onClickCard={() => {
                    clearMessage();
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} className="mb-3 pt-0">
                <DocumentCard
                  text={l("Upload Courier receipt", lang)}
                  icon={recieptIcon}
                  isRequired={true}
                  tempState={
                    journeyData?.documents?.courier_receipt?.[0]?.fileLink || ""
                  }
                  isCourier={true}
                  isUpload={journeyData?.documents?.courier_receipt?.length > 0}
                  status={
                    journeyData?.documents?.courier_receipt?.[0]?.isApproved
                  }
                  setFile={(file) => {
                    handleUpload(file, "courier_receipt");
                  }}
                  onClickCard={() => {
                    clearMessage();
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} className="py-0 pt-1">
                <p className="post-heading">
                  {l("Post disbursed overview", lang)}
                </p>
              </Grid>

              {toggleButton === "true" && (
                <Grid item xs={6} sm={6}>
                  <div
                    className="d-flex card-home pointer justify-content-center align-items-center"
                    style={{ height: "80px" }}
                    onClick={() => {
                      // alert('hello')
                      // navigate(`/create-loan/documents-upload/${application_id}`);
                      MixPanelAudit.intercept("schedule_click", {
                        application_id,
                        distinct_id: user?.mobile_number[0],
                        agent_name: user?.name
                      });
                      navigate(
                        `/create-loan/collection-schedule/${application_id}`
                      );
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <p className="heading mb-0" style={{ fontSize: "1em" }}>
                        {l("Schedule", lang)}
                      </p>
                    </div>
                    <div className="button-holder"></div>
                  </div>
                </Grid>
              )}

              <Grid item xs={6} sm={6}>
                <div
                  className="d-flex card-home pointer justify-content-center align-items-center"
                  style={{ height: "80px" }}
                  onClick={() => {
                    navigate(
                      `/create-loan/loan-summary/${application_id}/post`
                    );
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <p className="heading mb-0" style={{ fontSize: "1em" }}>
                      {l("Loan Summary", lang)}
                    </p>
                  </div>
                  <div className="button-holder"></div>
                </div>
              </Grid>

              {/* <Grid item xs={6} sm={6}>
                <div
                  className="d-flex card-home pointer justify-content-center align-items-center"
                  style={{ height: "80px" }}
                  onClick={() => {

                  }}
                >
                  <div className="d-flex justify-content-center">
                    <p className="heading mb-0" style={{ fontSize: "1em"}}>
                      {l("Loan Summary", lang)}
                    </p>
                  </div>
                  <div className="button-holder"></div>
                </div>
              </Grid> */}
            </Grid>
          </>
        </div>
      </Box>
      {isLoading && <LoadingOver text={l("Uploading", lang) + "..."} />}
    </>
  );
});

export default PostDisbursement;
