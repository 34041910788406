import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuAppBar from "../../../../components/Appbar";
import waitIcon from "../../../../assets/disbursalCongrats.svg";
import waitNormal from "../../../../assets/wait.svg";

import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { l } from "../../../../util/languageConvertor";
import MixPanelAudit from "../../../../util/MixPanelAudit";
// import TimeInput from "../../Components/DateInput";

const Waiting = forwardRef((props, ref) => {
  const { application_id } = useParams();
  const { bottomNav, langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleBack = () => {
    navigate("/draft-applications");
  };

  const style = {
    congratulationTxt: {
      fontSize: "40px",
      fontWeight: "900",
      color: "#F27E0A",
    },
    reviewTxt: {
      fontSize: "30px",
      fontWeight: "900",
      color: "#151940",
    },
    tagTxt: {
      padding: "0 30px",
      color: "#151940",
      fontWeight: 500,
      marginTop: "40px",
    },
  };

  return (
    <>
      <Box className="" ref={ref}>
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div
          className="main-wrapper"
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "90vh",
          }}
        >
          <div>
            <p className="text-center">
              <img
                src={props?.type === "NORMAL" ? waitNormal : waitIcon}
                alt="wait"
              />
            </p>
            {props?.type !== "NORMAL" && (
              <p className="text-center" style={style?.congratulationTxt}>
                {l("Congratulations", lang)}
              </p>
            )}
            <p className="text-center" style={style?.tagTxt}>
              {l(
                props?.text ||
                  "All the documents for this application are approved please wait for 24hrs for loan get credited on your account",
                lang
              )}
            </p>
          </div>
        </div>

        <Paper className={`paper-bottom`} ref={bottomNav} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                // props?.type === "NORMAL"
                onClick={() => {
                  MixPanelAudit.intercept("nbfc_under_review_refresh", {
                    
                  });
                  window.location.reload();
                }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    {l("Loading", lang)}
                  </>
                ) : (
                  l("Refresh", lang)
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
});

export default Waiting;
