import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Buffer } from "buffer";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraIcon from "@mui/icons-material/Camera";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { FiCamera, FiImage, FiX } from "react-icons/fi";
import { GrGallery } from "react-icons/gr";
import MixPanelAudit from "../../util/MixPanelAudit";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Webcam from "react-webcam";
import { compressImage, getBase64 } from "../../util/convertFileBase";
import { CircularProgress, Grid } from "@mui/material";
import ImageCropper from "../Crop";
import PermissionModal from "../PermissionModal";
import usePermission from "../../hooks/usePermission";
import { useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CropScreen from "../CropScreen";
import { l } from "../../util/languageConvertor";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function UploadCard({
  setFile,
  error,
  errorTxt,
  onSelect,
  text,
  isRequired,
  tempState,
  disabled,
  iconImg,
  isDone,
}) {
  const { showPermissionState, langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [open, setOpen] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isSelfie, setSelfie] = useState(false);
  const [captureImage, setImage] = useState("");
  const [confirmFilename, setConfirmFilename] = useState("");
  const [isInput, setInput] = useState(false);
  const [pdfFile, setPdf] = useState("");
  const [loading, setLoading] = React.useState(true);
  const { permissions, getUserLocation } = usePermission();
  const [showPermission, setShowPermission] = showPermissionState;
  const [isCameraLoaded, setCameraLoaded] = useState(false);
  const [isMobile, setMobile] = useState(false);

  const fileInput = useRef();
  const cameraInput = useRef();
  const sliderBottom = useRef();
  const backdrop = useRef();

  const [cropImgTemp, setTempCrop] = useState("");
  const [croppedImg, setCropImg] = useState("");

  const handleClickOpen = (capture) => {
    // setOpen(true);

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      fileInput.current.click();
      setInput(true);
    } else {
      setIsCameraOpen(true);
      // setShowPermission((st) => ({ ...setConfirmFilename, state: false }));
      // sliderBottom.current?.classList.remove("close");
      // backdrop.current?.classList.remove("hide");
    }
  };

  const handleClose = () => {
    // setOpen(false);
    // setTimeout(() => {
    //   sliderBottom.current.classList.add("close");
    //   backdrop.current.classList.add("hide");
    // }, 100);
  };

  const handleRecapture = () => {
    setConfirmFilename("");
    setInput(false);
    setPdf("");
    setImage("");
    setCropImg("");
    setFile && setFile("");
  };

  useEffect(() => {
    if (!open) {
      setIsCameraOpen(false);
      setImage("");
    }
  }, [open]);

  useEffect(() => {
    if (croppedImg !== "") {
      fileInput.current.value = "";
      setFile && setFile(croppedImg);
    }
  }, [croppedImg]);

  useEffect(() => {
    if (pdfFile !== "") {
      fileInput.current.value = "";
      setFile && setFile(pdfFile);
    }
  }, [pdfFile]);

  const handleUserMedia = () => setTimeout(() => setLoading(false), 1_000);

  useEffect(() => {
    setMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  }, []);

  return (
    <>
      <>
        {tempState === "" ? (
          <>
            <div className="upload-card-v3">
              <p className="heading">
                {text} {isRequired && <span className="mand-star">*</span>}
              </p>
              {!iconImg ? (
                <p className="desc-text">{l("Max image size", lang)}: 5 Mb</p>
              ) : (
                <img src={iconImg} alt="icon" className="icon-img" />
              )}
              {!isDone && (
                <div className="button-holder">
                  {isMobile && (
                    <Button
                      className="btn"
                      onClick={() => {
                        cameraInput.current.click();
                        setInput(true);
                      }}
                    >
                      <CameraAltOutlinedIcon sx={{ fontSize: 16, mr: 0.5 }} />
                      {l("Camera", lang)}
                    </Button>
                  )}
                  <Button
                    className="btn ms-3"
                    onClick={() => {
                      fileInput.current.click();
                      setInput(true);
                    }}
                  >
                    <FileUploadOutlinedIcon sx={{ fontSize: 16, mr: 0.5 }} />
                    {l("Upload", lang)}
                  </Button>
                </div>
              )}
              {isDone && (
                <div className="button-holder">
                  <span
                    style={{
                      color: "#fff",
                      backgroundColor: "#4caf50",
                      padding: "5px 10px",
                      borderRadius: "30px",
                    }}
                  >
                    <CheckCircleIcon sx={{ fontSize: 18 }} /> Uploaded
                  </span>
                </div>
              )}
            </div>

            <input
              ref={cameraInput}
              accept="image/*"
              capture="environment"
              type="file"
              class="custom-file-input"
              // accept="application/pdf , image/png, image/jpg, image/jpeg"
              onChange={async (e) => {
                console.log("camera");
                MixPanelAudit.intercept("Doc_Capture", {
                  "Doc Name": text,
                });
                onSelect && onSelect();
                if (e.target.files[0]["type"].split("/")[0] == "image") {
                  if (e.target.files[0].type.match("image/svg")) {
                    alert("Not supported file");
                    return false;
                  }
                  setInput(false);
                  setImage(
                    await getBase64(
                      await compressImage(e.target.files[0], true)
                    )
                  );
                } else {
                  alert("Image only");
                  handleRecapture();
                }

                e.target.value = "";
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
              hidden
            />

            <input
              ref={fileInput}
              type="file"
              class="custom-file-input"
              // accept="application/pdf , image/png, image/jpg, image/jpeg"
              accept="image/*"
              onChange={async (e) => {
                MixPanelAudit.intercept("Doc_Upload", {
                  "Doc Name": text,
                });
                console.log("file");
                onSelect && onSelect();
                if (e.target.files[0]["type"].split("/")[0] == "image") {
                  if (e.target.files[0].type.match("image/svg")) {
                    alert("Not supported file");
                    return false;
                  }
                  setInput(false);
                  // console.log(await getBase64(await compressImage(e.target.files[0])));
                  // console.log(await getBase64(e.target.files[0]));
                  setImage(
                    await getBase64(
                      await compressImage(e.target.files[0], true)
                    )
                  );
                } else {
                  alert("Image only");
                  handleRecapture();
                }
                e.target.value = "";
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
              hidden
            />

            <CropScreen
              open={captureImage !== "" && !isInput}
              image={captureImage}
              onClose={() => {
                setImage("");
              }}
              onCrop={(file) => {
                // console.log(file);
                setCropImg(file);
                setConfirmFilename("capture.jpg");
                setImage("");
              }}
              onRecapture={() => {
                handleRecapture();
                fileInput.current.click();
              }}
            />
          </>
        ) : (
          <>
            {!isInput && (
              <>
                <div
                  className={`w-100 bg-dark d-flex justify-content-center ${
                    error && "capture-error"
                  }`}
                  style={{ borderRadius: "8px", overflow: "hidden" }}
                >
                  <img
                    src={tempState}
                    alt=""
                    width={"100%"}
                    height={"200px"}
                    style={{ objectFit: "contain" }}
                  />
                </div>
                {errorTxt && (
                  <span className="error mt-2 mb-3">
                    <ErrorOutlineIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {errorTxt}
                  </span>
                )}
              </>
            )}

            {isInput && (
              <>
                <p className="text-center">
                  <DescriptionIcon style={{ fontSize: "50px" }} />
                </p>
                <p
                  className="text-center"
                  style={{
                    fontSize: "10px",
                    color: "gray",
                    padding: "0 50px",
                  }}
                >
                  {confirmFilename}
                </p>
              </>
            )}
            <p className="mb-0 text-center mt-2">
              <span className="recapture-btn" onClick={handleRecapture}>
                <CameraAltIcon className="me-2" /> Press to Re-capture/Upload
              </span>
            </p>
          </>
        )}
      </>
    </>
  );
}
