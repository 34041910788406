import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import downloadIco from "../../../../assets/downloadAgree.svg";
import uploadIco from "../../../../assets/uploadAgree.svg";
import ButtonComp from "../../../../components/V3/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import infoIco from "../../../../assets/infoIco.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import { baseURL, environment } from "../../../../config";
import ContinueUpload from "../../../../components/V2/ContinueUpload";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import MultiDocUpload from "../../../../components/V2/MultiDocUpload";
import useApplication from "../../../../hooks/useApplication";
import { getEsignDocumentBase, uploadDocument } from "../../../../api/agent";
import { l } from "../../../../util/languageConvertor";
import LoadingOver from "../../../../components/LoadingOver";
import { handleDownloadBase64PDF } from "../../../../util/convertFileBase";
const ManualContent = ({ setContent, handleClose, defaultContentType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { getApplicationDetails } = useApplication();
  const { journeyState } = useContext(LoanContext);
  const { langState, docPopupState } = useContext(GlobalContext);

  const [journeyData, setJourneyData] = journeyState;
  const [docPopup, setDocPopup] = docPopupState;
  const [lang, setLang] = langState;

  const [info, setInfo] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingOver, setLoadingOver] = useState(false);
  const [multiData, setMultiData] = useState({ show: false });

  const handleDownload = async () => {
    try {
      setLoadingOver(true);
      // let key = `loan_agreement_unsigned/${journeyData?.application_id}`;
      let key = `loan_agreement_unsigned/EMAPL09052300000464`;
      let { data } = await getEsignDocumentBase({
        application_id: journeyData?.application_id,
      });

      if (data?.status) {
        // console.log(`data:application/pdf;base64,${data?.base64}`);
        const linkSource = `data:application/pdf;base64,${data?.base64}`;
        const fileName = "Loan-agreement.pdf";
        handleDownloadBase64PDF(linkSource, fileName);

        // const downloadLink = document.createElement("a");
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();
      }
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.message || "Error downloading agreement",
        { variant: "error" }
      );
    }
    setLoadingOver(false);
  };

  const handleUpload = async (file, name) => {
    setLoading(name);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name,
              file,
            },
          ],
        },
        journeyData?.application_id,
        `?override=true`
      );
      if (data?.mandatory_completed) {
        setDocPopup({ open: true, type: "DONE" });
      }
      getApplicationDetails(journeyData?.application_id, true);
    } catch (error) {}
    setLoading("");
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {defaultContentType !== "manual" && (
          <ArrowBackIcon
            className="pointer"
            onClick={() => {
              setContent("initial");
              setInfo(true);
            }}
          />
        )}{" "}
        {defaultContentType !== "manual" && l("Manual", lang)}
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 5,
            cursor: "pointer",
          }}
        >
          <CloseIcon
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={6}>
            <ButtonComp
              icon={downloadIco}
              text={"Download"}
              action={() => {
                handleDownload();
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {/* <ButtonComp icon={uploadIco} text={"Upload"} /> */}

            <ContinueUpload
              icon={uploadIco}
              name={
                <Button
                  className="upload-btn mt-3"
                  style={{
                    height: 25,
                  }}
                >
                  Upload
                </Button>
              }
              onClick={() => {
                setMultiData({
                  ...multiData,
                  show: true,
                  count: 30,
                  collection: "loanapplications",
                  category: "pre_disbursement",
                  name: "loan_agreement",
                });
              }}
              handleUpload={(file) => {
                handleUpload(file, "loan_agreement");
              }}
              documents={journeyData?.documents?.loan_agreement}
              started={journeyData?.documents?.loan_agreement?.[0]}
              completed={journeyData?.documents?.loan_agreement?.[0]?.completed}
              status={journeyData?.documents?.loan_agreement?.[0]?.isApproved}
              pages={journeyData?.documents?.loan_agreement?.length}
              isMulti={
                journeyData?.documents?.loan_agreement?.[0]?.meta_page_document
              }
              setState={setMultiData}
              isLoading={loading === "loan_agreement"}
              extraHeight={160}
            />
          </Grid>
        </Grid>
      </DialogContent>

      {multiData?.show && (
        <MultiDocUpload
          state={multiData}
          setState={setMultiData}
          onClose={() => {
            getApplicationDetails(journeyData?.application_id, true);
          }}
        />
      )}

      {loadingOver && <LoadingOver text={"Downloading"} />}
    </>
  );
};

export default ManualContent;
