import React from 'react'
import TwentyFiveCoinReward from '../assets/25-coin-reward.gif'
import FiftyCoinReward from '../assets/50-coin-reward.gif'
import SeventyFiveCoinReward from '../assets/75-coin-reward.gif'
import HundredCoinReward from '../assets/100-coin-reward.gif'
import { Card, Button } from '@mui/material';


export default function ShowRewardCoinGif(props) {

  const ValueToGifNameMap = {
    25: TwentyFiveCoinReward,
    50: FiftyCoinReward,
    75: SeventyFiveCoinReward,
    100: HundredCoinReward
  }

  return (
    <Card variant="outlined" style={{ width: "100%", position: 'absolute', height: '100vh', background: 'rgba(0,0,0,0.5)', display: 'grid', justifyItems: 'center', alignItems: 'center', zIndex: '10000' }}>
      <Card variant="outlined" style={{ width: "100%", background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img style={{ width: "70%" }} src={ValueToGifNameMap[props.RewardValue]} alt="You Recieved reward coin..." />
      </Card>
      {/* <Button onClick={()=>props?.action()} sx={{ background: 'blue', color: '#fff', position: 'absolute', marginTop: '13em' }}>Got It</Button> */}
      {/* <Button onClick={()=>{props?.close();props?.psychoDone();}} sx={{ background: 'blue', color: '#fff', position: 'absolute', marginTop: '13em' }}>Got It</Button> */}
      <Button sx={{ margin: '0 auto' }} disabled ></Button>
    </Card>
  )
}
