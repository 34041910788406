import {
  Button,
  CssBaseline,
  Dialog,
  Grid,
  Stack,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchQr,
  physicalMandate,
  uploadDocument,
  uploadMandate,
  adhaarMandate,
  reUpdateBankDetails,
  updateBankDetails,
} from "../../../../api/agent";
import MenuAppBar from "../../../../components/Appbar";
import UploadCard from "../../../../components/V3/UploadCard";
import { baseURL, environment } from "../../../../config";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import { getBase64 } from "../../../../util/convertFileBase";
import { UserContext } from "../../../../Contexts/UserContext";
import ReplayIcon from "@mui/icons-material/Replay";
import { l } from "../../../../util/languageConvertor";
import MixPanelAudit from "../../../../util/MixPanelAudit";
import { ReactComponent as BankSupportIcon } from "../../../../assets/tabs/banksupportIco.svg";
import { ReactComponent as PaymentIco } from "../../../../assets/tabs/paymentIco.svg";
import BankListComponent from "../../../../components/Autocomplete/BankListAuto";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { isBlank, isIFSCValid } from "../../../../validations/fieldValidations";
import DoneIcon from "../../../../assets/Done.svg";
import DocumentCard from "../../../../components/V3/DocumentCard";
import chequeIco from "../../../../assets/tabs/chequeIco.svg";
import bsvIco from "../../../../assets/tabs/bsvIco.svg";
import passbookIco from "../../../../assets/tabs/passbookIco.svg";
import useErrorMessage from "../../../../hooks/useErrorMessage";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ErrorMessage from "../../../../components/V3/ErrorMessage";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const radioStyle = {
  color: "#573A7A",
  "&.Mui-checked": {
    color: "#573A7A",
  },
};

const messageInitial = {
  type: "none",
  message: null,
};

const labelStyle = {
  color: "gray",
};

const qrDataInitial = {
  open: false,
  qr_code: "",
};

const bankDataInitial = {
  nachable_bank_name: "",
  account_no: "",
  ifsc_code: "",
  account_type: "",
};

const initialShowUploadDocPopup = {
  show: false,
  isFirstPennyDrop: true,
};

const CardComp = ({ heading, subtext, Buttons }) => {
  return (
    <div className="payment-card">
      <div className="upload-card-v3" style={{ height: "160px" }}>
        <p className="heading mb-0">{heading}</p>
        <p className="desc-text" style={{ color: "#6A6A6A" }}>
          {subtext}
        </p>
        <div className="button-holder">{Buttons}</div>
      </div>
    </div>
  );
};

const PaymentMethod = ({ loader }) => {
  const navigate = useNavigate();
  const fileInput = useRef();
  const bankNameRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { application_id } = useParams();
  const { journeyState } = useContext(LoanContext);
  const { user } = useContext(UserContext);
  const { bottomNav, showRewardState, langState, docPopupState } =
    useContext(GlobalContext);
  const [docPopup, setDocPopup] = docPopupState;
  const [lang, setLang] = langState;
  const [journeyData, setJourneyData] = journeyState;
  const [isReward, showReward] = showRewardState;
  const [isUploaded, setIsUploaded] = useState(false);
  // const [isEnachDone, setIsEnachDone] = useState(false);
  const [isDownloaded, setDownloaded] = useState(false);
  const [qrData, setQrData] = useState(qrDataInitial);
  const [isQrLoading, setQrLoading] = useState(false);
  const [openBank, setOpenBank] = useState(false);
  const [bankData, setBankData] = useState(bankDataInitial);
  const [editStage, setEditStage] = useState("details");
  const [topMessage, setTopMessage] = useState(messageInitial);
  const [showUploadDocPopup, setShowUploadDocPopup] = useState(
    initialShowUploadDocPopup
  );

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setDocLoading] = useState(false);
  const { showMessage, clearMessage } = useErrorMessage();

  const [error, setError] = useState({
    nachable_bank_name: "",
    account_no: "",
    ifsc_code: "",
    account_type: "",
  });

  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    enach: "pending",
    upi: "pending",
  });
  const [open, setOpen] = React.useState(false);
  const [openCheque, setOpenCheque] = useState(false);

  const handleClickOpen = () => {
    // console.log("open");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenCheque = () => {
    // console.log("open");
    setOpenCheque(true);
  };

  const handleCloseCheque = () => {
    setOpenCheque(false);
  };

  const handleBack = async () => {
    navigate(`/draft-applications`);
  };

  const { getApplicationDetails, getStage, getRealStatus } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    setStatus({
      ...status,
      enach: getRealStatus(journeyData?.enach_status, "enach"),
      upi: getRealStatus(journeyData?.upi_status, "upi"),
    });
  }, [journeyData?.enach_status, journeyData?.upi_status]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/draft-applications");
    };
  });

  useEffect(() => {
    if (journeyData?.penny_drop_data?.verified) setEditStage("document");
  }, [journeyData?.penny_drop_data?.verified]);

  useEffect(() => {
    setIsUploaded(journeyData?.physical_nach_uploaded);
  }, [journeyData?.physical_nach_uploaded]);

  const handlePhysicalMandate = async () => {
    setLoading(true);
    try {
      console.log(journeyData?.physical_nach_status);
      if (journeyData?.physical_nach_status) {
        console.log(`physical_nach/${application_id}`);
        await handleDownload(
          `nach_form_unsigned/${application_id}`,
          `nach_form_${application_id}.pdf`
        );
        setDownloaded(true);
      } else {
        // console.log("Hereee");
        let { data } = await physicalMandate(application_id);
        await handleDownload(
          `nach_form_unsigned/${application_id}`,
          `nach_form_${application_id}.pdf`
        );
        setJourneyData({ ...journeyData, physical_nach_status: true });
        setDownloaded(true);
      }
      MixPanelAudit.intercept("Physical Mandate Downloaded", {
        agent_name: user?.name,
        application_id,
      });
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleDownload = async (key, fileName) => {
    await fetch(`${baseURL[environment]}/common/view?key=${key}`, {
      method: "get",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        application: "AGENT-ERICK-V2",
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement("a");
        aElement.setAttribute("download", fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        // aElement.setAttribute('href', href);
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      });
  };

  // const uploadNach = async (file) => {
  //   console.log(journeyData?.mandate_id);
  //   fileInput.current.value = "";
  //   try {
  //     // console.log(await getBase64(file));
  //     let base64 = await getBase64(file);
  //     let { data } = await uploadMandate({
  //       document_include_g:true,
  //       mandate_id: journeyData?.mandate_id,
  //       application_id: application_id,
  //       file_data: base64,
  //     });
  //     enqueueSnackbar(data?.message, { variant: "success" });
  //   } catch (error) {
  //     enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
  //   }
  // };

  const uploadNach = async (file) => {
    // setLoading(name);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name: "nach_form",
              file: await getBase64(file),
            },
          ],
        },
        application_id,
        `?override=true`
      );
      getApplicationDetails(application_id, true);
    } catch (error) {}
    // setLoading("");
  };

  const handleQr = async () => {
    setQrLoading(true);
    try {
      let { data } = await fetchQr({
        application_id,
      });
      setQrData({
        open: true,
        qr_image:
          data?.insta_collect?.virtual_account
            ?.upi_qrcode_remote_file_location ||
          data?.data?.virtual_account?.upi_qrcode_remote_file_location,
      });
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "error",
      });
      // console.log("handleQr", error);
    }
    setQrLoading(false);
  };

  const StatusChip = ({ text, color }) => {
    return (
      <span
        style={{
          background: color,
          color: "white",
          padding: "5px 20px",
          borderRadius: 20,
        }}
      >
        {text}
      </span>
    );
  };

  const isValid = async () => {
    let valid = true;

    let errors = {
      account_no: isBlank(bankData?.account_no),
      account_type: isBlank(bankData?.account_type || ""),
      ifsc_code: isIFSCValid(bankData?.ifsc_code),
      nachable_bank_name: isBlank(bankData?.nachable_bank_name),
    };

    if (bankData?.nachable_bank_name === "Others") {
      errors = {
        ...errors,
        nachable_bank_name: "Select a bank",
      };
    }

    setError((prev) => ({ ...prev, ...errors }));

    // let first = false;
    for (let i in errors) {
      if (errors[i].length > 0) {
        // if (!first) {
        //   first = true;
        //   refs[i]?.current?.focus();
        //   window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
        // }
        valid = false;
      }
    }

    return valid;
  };

  const handleSubmit = async () => {
    if (!(await isValid())) {
      return;
    }
    setLoading(true);

    let payload = {
      bank_id: journeyData?.penny_drop_data?.verified
        ? journeyData?.bank_details?.bank_id
        : undefined,
      bank_name: bankData?.nachable_bank_name,
      account_number: bankData?.account_no,
      ifsc_code: bankData?.ifsc_code.toUpperCase(),
      account_type: bankData?.account_type,
    };

    try {
      let data;
      let isFirstPennyDrop;
      if (journeyData?.penny_drop_data?.verified) {
        isFirstPennyDrop = false;
        data = await reUpdateBankDetails(payload);
      } else {
        isFirstPennyDrop = true;
        data = await updateBankDetails(payload, application_id);
      }
      if (data?.status) {
        if (data?.pennyDropFailed !== null) {
          if (!data?.pennyDropFailed) {
            setTopMessage({
              message: "Penny drop successfull",
              type: "success",
            });
            setShowUploadDocPopup({
              show: true,
              isFirstPennyDrop,
            });
            setEditStage("document");
          } else {
            setTopMessage({
              type: "error",
              message: "Penny Drop Failed!",
            });
            // setDisplayErrorMSG({
            //   message: 'Penny drop failed',
            //   variant: 'error',
            // });
          }
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      //  console.log('-----', error);
      // setDisplayErrorMSG({
      //   message: error?.response?.data?.message,
      //   variant: 'error',
      // });
      setTopMessage({
        type: "error",
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Operation failed",
      });
    }
    await getApplicationDetails(application_id, true);
    setLoading(false);
  };

  const handleUpload = async (file, name, overide = true, index) => {
    setDocLoading(name);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name,
              file,
            },
          ],
        },
        application_id,
        overide === true
          ? `?override=true`
          : typeof overide === "string" && overide.includes("cheques_")
          ? `?overrideFileName=${overide}`
          : ""
      );
      if (data?.mandatory_completed) {
        setDocPopup({ open: true, type: "DONE" });
      }
      getApplicationDetails(application_id, true);
    } catch (error) {
      console.log("error :-", error);
      if ([400, 500].includes(error?.response?.data?.code)) {
        showMessage(l(error?.response?.data?.message, lang));
      } else if (error?.response?.status === 400) {
        showMessage(error?.response?.data);
      }
    }
    setDocLoading("");
  };

  const handleBankDetails = () => {
    setBankData({
      ...bankData,
      nachable_bank_name: journeyData?.penny_drop_data?.verified
        ? journeyData?.bank_details?.bank_name
        : journeyData?.nachable_bank_name,
      account_no: journeyData?.bank_details?.account_number,
      ifsc_code: journeyData?.bank_details?.ifsc_code,
      account_type: journeyData?.bank_details?.account_type,
    });
  };

  useEffect(() => {
    handleBankDetails();
  }, [journeyData?.bank_details, journeyData?.penny_drop_data]);

  return (
    <>
      <MenuAppBar
        home={false}
        bigText={"Pre-Disbursement"}
        action={() => {
          navigate(`/draft-applications`);
        }}
        shadow={true}
      />
      <CssBaseline />

      <div className="p-2">
        {topMessage?.message !== null && (
          <div
            className="w-100 content-center"
            style={
              topMessage?.type === "error"
                ? {
                    background: "#FAD3D9",
                    color: "#EE0000",
                    padding: 20,
                  }
                : {
                    background: "#D7FFE2",
                    color: "#43B300",
                    padding: 20,
                  }
            }
          >
            {topMessage?.message}
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // alignItems: 'flex-end',
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontWeight: 600 }}>{l("Bank Details", lang)}</div>

          {journeyData?.penny_drop_data?.verified &&
            (editStage !== "details" ? (
              <Button
                style={{
                  textTransform: "none",
                }}
                onClick={() => {
                  setEditStage("details");
                }}
              >
                <EditOutlinedIcon sx={{ fontSize: 15, mr: 0.5 }} />{" "}
                {l("Edit", lang)}
              </Button>
            ) : (
              <Button
                style={{
                  textTransform: "none",
                  fontWeight: 500,
                }}
                sx={{ color: "red" }}
                onClick={() => {
                  setTopMessage(messageInitial);
                  handleBankDetails();
                  setEditStage("document");
                }}
              >
                <CloseOutlinedIcon sx={{ fontSize: 15, mr: 0.5 }} />{" "}
                {l("Cancel", lang)}
              </Button>
            ))}
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} className="pt-0 mt-4">
            <label className="form-label">
              {l("Select Nachable bank account", lang)}{" "}
              <span className="mand-star">*</span>
            </label>
            <div className="d-flex" style={{ position: "relative" }}>
              <input
                ref={bankNameRef}
                className="form-input w-100 white-bg form-input-bank"
                style={{ cursor: "pointer" }}
                required
                fullWidth
                disabled={editStage !== "details"}
                placeholder="Select Nachable bank account"
                value={bankData?.nachable_bank_name}
                onClick={() => {
                  bankNameRef?.current?.blur();
                  setOpenBank(true);
                }}
              />
              <div style={{ position: "absolute", right: 10, top: 10 }}>
                <ArrowDropDownIcon />
              </div>
            </div>
            <BankListComponent
              open={openBank}
              value={journeyData?.nachable_bank_name}
              skippable={false}
              handleClose={() => {
                setOpenBank(false);
                bankNameRef?.current?.blur();
              }}
              onChange={(value) => {
                // clearMessage();
                setTopMessage(messageInitial);
                setError({ ...error, nachable_bank_name: "" });
                setBankData({
                  ...bankData,
                  nachable_bank_name: value?.label,
                });
              }}
            />

            <span className="error">{error?.nachable_bank_name}</span>
          </Grid>

          <Grid item xs={12} sm={12}>
            <label className="form-label">
              {l("Account Number", lang)}
              <span className="mand-star">*</span>
            </label>
            <input
              type="text"
              // ref={refs?.account_no}
              className="form-input w-100 white-bg"
              required
              placeholder={l("Account Number", lang)}
              autoComplete="family-name"
              value={bankData.account_no}
              disabled={editStage !== "details"}
              onChange={(e) => {
                if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                  return false;
                }
                setTopMessage(messageInitial);
                setError({ ...error, account_no: "" });
                setBankData({
                  ...bankData,
                  account_no: e.target.value,
                });
              }}
              inputMode="numeric"
            />
            <span className="error">{error?.account_no}</span>
          </Grid>

          <Grid item xs={12} sm={12}>
            <label className="form-label">
              {l("IFSC Code", lang)}
              <span className="mand-star">*</span>
            </label>
            <input
              // ref={refs?.ifsc_code}
              type="text"
              className="form-input w-100 white-bg"
              required
              placeholder={l("IFSC Code", lang)}
              autoComplete="family-name"
              value={bankData.ifsc_code}
              disabled={editStage !== "details"}
              maxLength={11}
              onChange={(e) => {
                setTopMessage(messageInitial);
                setError({ ...error, ifsc_code: "" });
                setBankData({
                  ...bankData,
                  ifsc_code: (e.target.value || "").toUpperCase(),
                });
              }}
            />
            <span className="error">{error.ifsc_code}</span>
          </Grid>

          <Grid item xs={12} sm={12} className="pt-0 mt-4">
            <label className="form-label">
              {l("Account Type", lang)} <span className="mand-star">*</span>
            </label>
            <RadioGroup
              className="mb-1"
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={bankData?.account_type}
              disabled={editStage !== "details"}
              onChange={(e) => {
                setTopMessage(messageInitial);
                setError({ ...error, account_type: "" });
                setBankData({
                  ...bankData,
                  account_type: e.target.value,
                });
              }}
            >
              <Grid container spacing={2}>
                <Grid item>
                  <FormControlLabel
                    value={"Savings"}
                    disabled={editStage !== "details"}
                    control={<Radio sx={radioStyle} />}
                    label={
                      <span style={{ fontSize: "10px" }}>
                        {l("Savings", lang)}
                      </span>
                    }
                    sx={labelStyle}
                  />
                </Grid>

                <Grid item>
                  <FormControlLabel
                    value={"Current"}
                    disabled={editStage !== "details"}
                    control={<Radio sx={radioStyle} />}
                    label={
                      <span style={{ fontSize: "10px" }}>
                        {l("Current", lang)}
                      </span>
                    }
                    sx={labelStyle}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
            <span className="error">{error.account_type}</span>
          </Grid>

          <Grid item xs={12} sm={12}>
            {editStage === "details" ? (
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                disabled={isLoading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {isLoading ? "Loading..." : l("Check Penny Drop", lang)}
              </Button>
            ) : (
              <span
                style={{
                  padding: "10px 20px",
                  background: "#EDEDED",
                  color: "#04BD00",
                }}
              >
                Peny Drop Success{" "}
                <CheckOutlinedIcon sx={{ fontSize: 20, ml: 0.5 }} />
              </span>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          className={`mt-2 ${editStage === "document" ? "" : "disabled-grid"}`}
          style={{
            opacity: editStage === "document" ? 1 : 0.2,
          }}
        >
          <Grid item xs={12} sm={12} className="pb-0">
            <div style={{ fontWeight: 600 }}>{l("NACH", lang)}</div>
          </Grid>
          {[true, null, undefined].includes(
            journeyData?.nbfcData?.payment_method?.UPI_Autopay
          ) && (
            <Grid item xs={12} sm={12} >
              <CardComp
                heading={l("UPI Autopay", lang)}
                subtext={l("Registration Using VPA", lang)}
                Buttons={
                  <>
                    {["pending", "unsuccessfull"].includes(status?.upi) && (
                      <Button
                        className="btn"
                        onClick={() => {
                          MixPanelAudit.intercept(
                            "payment_method_upi_autopay",
                            {
                              agent_name: user?.name,
                              application_id,
                            }
                          );
                          navigate(
                            `/create-loan/payment-method/upi/${application_id}`
                          );
                        }}
                      >
                        {status?.upi === "unsuccessfull"
                          ? l("Try Again", lang)
                          : l("Register", lang)}
                      </Button>
                    )}
                    {["Link Sent"].includes(status?.upi) && (
                      <>
                        <ReplayIcon
                          className="me-2 pointer"
                          onClick={() => {
                            MixPanelAudit.intercept(
                              "payment_method_upi_autopay",
                              {
                                agent_name: user?.name,
                                application_id,
                              }
                            );
                            navigate(
                              `/create-loan/payment-method/upi/${application_id}`
                            );
                          }}
                        />
                        <StatusChip
                          text={l("Link Sent", lang)}
                          color="#c29644"
                        />
                      </>
                    )}
                    {["successfull"].includes(status?.upi) && (
                      <StatusChip text={l("Success", lang)} color="#4caf50" />
                    )}
                  </>
                }
              />
            </Grid>
          )}

          {[true, null, undefined].includes(
            journeyData?.nbfcData?.payment_method?.["E-Nach"]
          ) && (
            <Grid item xs={12} sm={12}>
              <CardComp
                heading={l("E-Nach", lang)}
                subtext={l("Debit card/ Net Banking/ Aadhaar", lang)}
                Buttons={
                  <>
                    {["pending", "unsuccessfull"].includes(status?.enach) && (
                      <Button
                        className="btn"
                        onClick={
                          () => {
                            MixPanelAudit.intercept("payment_method_enach", {
                              agent_name: user?.name,
                              application_id,
                            });

                            if (journeyData?.nbfcData?.nbfc_id === "NY00002") {
                              console.log("eNach:- debit");
                              navigate(
                                `/create-loan/payment-method/enach/${application_id}`,
                                { state: { eNach: "api" } }
                              );
                            } else {
                              handleClickOpen();
                            }
                          }

                          //   () => {
                          //   navigate(
                          //     `/create-loan/payment-method/enach/${application_id}`, {state: {eNach : "api"} }
                          //   );
                          // }
                        }
                      >
                        {status?.enach === "unsuccessfull"
                          ? l("Try Again", lang)
                          : l("Register", lang)}
                      </Button>
                    )}
                    {["successfull"].includes(status?.enach) && (
                      <StatusChip text={l("Success", lang)} color="#4caf50" />
                    )}
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      sx={{
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "343px",
                            height: "130px",
                            display: "flex", // Set your width here
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "0.5rem",
                            // marginTop:"10px"
                          },
                        },
                      }}
                    >
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 0,
                          color: "#000",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>

                      <Stack direction="row" spacing={2} marginTop={2}>
                        <Button
                          onClick={() => {
                            MixPanelAudit.intercept(
                              "payment_method_enach_debit",
                              {
                                agent_name: user?.name,
                                application_id,
                              }
                            );
                            navigate(
                              `/create-loan/payment-method/enach/${application_id}`,
                              { state: { eNach: "api" } }
                            );
                            // e.stopPropagation();
                          }}
                          sx={{
                            backgroundColor: "#BE9FE1",
                            width: "149px",
                            height: "60px",
                            padding: "10px, 0px, 10px, 0px",
                            color: "#1D1A22",
                            // fontFamily: "Roboto",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontStyle: "normal",
                            borderRadius: "0.5rem",
                            "&:hover": { backgroundColor: "#BE9FE1" },
                            textTransform: "capitalize",
                          }}
                        >
                          Debit/NetBanking&nbsp;&nbsp;&nbsp; E-Nach
                        </Button>
                        <Button
                          onClick={() => {
                            MixPanelAudit.intercept(
                              "payment_method_enach_aadhaar",
                              {
                                agent_name: user?.name,
                                application_id,
                              }
                            );
                            navigate(
                              `/create-loan/payment-method/enach/${application_id}`,
                              { state: { eNach: "eAdhaar" } }
                            );
                          }}
                          sx={{
                            backgroundColor: "#BE9FE1",
                            width: "149px",
                            height: "60px",
                            padding: "10px, 0px, 10px, 0px",

                            color: "#1D1A22",
                            // fontFamily: "Roboto",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRadius: "0.5rem",
                            "&:hover": { backgroundColor: "#BE9FE1" },
                            textTransform: "capitalize",
                          }}
                        >
                          Aadhaar Based&nbsp;&nbsp;&nbsp; E-Nach
                        </Button>
                      </Stack>
                    </Dialog>
                    {/* {["successfull"].includes(status?.enach) && (
                        <StatusChip text={l("Success", lang)} color="#4caf50" />
                      )} */}
                  </>
                }
              />
            </Grid>
          )}

          {[true, null, undefined].includes(
            journeyData?.nbfcData?.payment_method?.Physical_Nach
          ) && (
            <Grid item xs={12} sm={12}>
              <CardComp
                heading={l("Physical Nach", lang)}
                subtext={l("Registration via NPCI", lang)}
                Buttons={
                  isUploaded ? (
                    <>
                      <ReplayIcon
                        className="me-2 pointer"
                        onClick={() => {
                          setIsUploaded(false);
                          setDownloaded(true);
                        }}
                      />
                      <StatusChip text={l("Uploaded", lang)} color="#4caf50" />
                    </>
                  ) : (
                    <>
                      <Button
                        className="btn"
                        disabled={isLoading}
                        onClick={() => {
                          MixPanelAudit.intercept("payment_method_phynach", {
                            agent_name: user?.name,
                            application_id,
                          });
                          handlePhysicalMandate();
                        }}
                      >
                        {!isLoading
                          ? isDownloaded
                            ? l("Download Again", lang)
                            : l("Download", lang)
                          : l("Loading", lang)}
                      </Button>
                      {isDownloaded && (
                        <>
                          <input
                            ref={fileInput}
                            type="file"
                            class="custom-file-input"
                            accept="image/*"
                            onChange={async (e) => {
                              MixPanelAudit.intercept(
                                "payment_method_phynach_upload",
                                {
                                  agent_name: user?.name,
                                  application_id,
                                }
                              );
                              if (!e.target.files[0]?.type.match(/image.*/)) {
                                enqueueSnackbar("Supports Image files only", {
                                  variant: "error",
                                });
                                return false;
                              }
                              uploadNach(e.target.files[0]);
                            }}
                            hidden
                          />
                          <Button
                            className="btn ms-3"
                            onClick={() => {
                              fileInput.current.click();
                            }}
                          >
                            {l("Upload", lang)}
                          </Button>
                        </>
                      )}
                    </>
                  )
                }
              />
            </Grid>
          )}

          {/* {[true, null, undefined].includes(
            journeyData?.nbfcData?.payment_method?.Pay_via_QR
          ) && (
            <Grid item xs={12} sm={12}>
              <CardComp
                heading={l("Pay via QR", lang)}
                subtext="Scan QR for every Payment"
                Buttons={
                  <>
                    <Button
                      className="btn ms-3"
                      onClick={() => {
                        MixPanelAudit.intercept("payment_method_qr", {
                          agent_name: user?.name,
                          application_id,
                        });
                        handleQr();
                      }}
                      disabled={isQrLoading}
                    >
                      {isQrLoading ? l("Loading", lang) : l("View QR", lang)}
                    </Button>
                  </>
                }
              />
            </Grid>
          )} */}

          {/* <Grid item xs={12} sm={12}>
            <CardComp
              heading={l("Aadhaar Based E-Nach", lang)}
              // subtext={l("Debit card/ Net Banking", lang)}
              Buttons={
                <>
                  {["pending", "unsuccessfull"].includes(status?.enach) && (
                    <Button
                      className="btn"
                      onClick={() => {
                        navigate(
                          `/create-loan/payment-method/enach/${application_id}`,
                          { state: { eNach: "eAdhaar" } }
                        );
                      }}
                    >
                      {status?.enach === "unsuccessfull"
                        ? l("Try Again", lang)
                        : l("Register", lang)}
                    </Button>
                  )}
                  {["successfull"].includes(status?.enach) && (
                    <StatusChip text={l("Success", lang)} color="#4caf50" />
                  )}
                </>
              }
            />
          </Grid> */}
        </Grid>
        {/* Documentssss */}
        <Grid
          container
          spacing={3}
          className={`mt-2 ${editStage === "document" ? "" : "disabled-grid"}`}
          style={{
            opacity: editStage === "document" ? 1 : 0.2,
          }}
        >
          <Grid item xs={12} sm={12} className="pb-0">
            <div style={{ fontWeight: 600 }}>{l("Documents", lang)}</div>
          </Grid>

          <Grid item xs={6} sm={6}>
            {/* <DocumentCard tempState={""} text={"Cheques"} icon={chequeIco} /> */}
            <div
              className="documents-card-v3 pointer"
              onClick={() => {
                handleClickOpenCheque();
              }}
            >
              <div>
                <p className="mb-2 text-center">
                  <img
                    src={chequeIco}
                    alt=""
                    width={60}
                    height={60}
                    style={{ objectFit: "contain" }}
                  />
                </p>
                <p className="heading mb-0">
                  {l("Cheques", lang)} <span className="mand-star">*</span>
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("BSV", lang)}
              isRequired={true}
              icon={bsvIco}
              tempState={
                journeyData?.documents?.bsv?.slice(-1)[0]?.fileLink || ""
              }
              isUpload={journeyData?.documents?.bsv?.length > 0}
              status={journeyData?.documents?.bsv?.slice(-1)[0]?.isApproved}
              data={journeyData?.documents?.bsv?.slice(-1)[0]}
              isLoading={loading === "bsv"}
              setFile={(file) => {
                handleUpload(file, "bsv");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Bank Passbook", lang)}
              isRequired={true}
              icon={passbookIco}
              tempState={
                journeyData?.documents?.bank_passbook?.slice(-1)[0]?.fileLink ||
                ""
              }
              isUpload={journeyData?.documents?.bank_passbook?.length > 0}
              status={
                journeyData?.documents?.bank_passbook?.slice(-1)[0]?.isApproved
              }
              data={journeyData?.documents?.bank_passbook?.slice(-1)[0]}
              isLoading={loading === "bank_passbook"}
              setFile={(file) => {
                handleUpload(file, "bank_passbook");
              }}
            />
          </Grid>
        </Grid>
      </div>

      <Dialog
        open={qrData?.open}
        onClose={() => {
          setQrData(qrDataInitial);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <img src={qrData?.qr_image} alt="QR" />
        <Button
          color="secondary"
          onClick={() => window.open(qrData?.qr_image, "_blank")}
        >
          Download
        </Button>
      </Dialog>

      {!loader && <Dialog
        open={journeyData?.bank_not_supported}
        onClose={() => {
          setJourneyData({ ...journeyData, bank_not_supported: true });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
            padding: 20,
          }}
        >
        <BankSupportIcon/>
          <div
            style={{
              textAlign: "center",
              fontWeight: "600",
              color: "rgba(87, 58, 122, 1)",
              fontSize: 20,
            }}
          >
            {l(
              "Customer’s Bank does not support Aadhaar E-NACH, Please take sign for Physical NACH",
              lang
            )}
          </div>
          <Button
            // color="secondary"
            style={{
              backgroundColor: "rgba(87, 58, 122, 1)",
              paddingVertical: 10,
              borderRadius: 10,
              textAlign: "center",
              fontWeight: "600",
              color: "#fff",
              fontSize: 20,
            }}
            onClick={() => {
              console.log("onClick");
              setJourneyData({ ...journeyData, bank_not_supported: false });
            }}
          >
            {l("OK", lang)}
          </Button>
        </div>
      </Dialog>}
      <Dialog
        open={showUploadDocPopup?.show}
        className="pop-dialog-card"
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "30px",
          },
        }}
        fullWidth
        maxWidth={"xs"}
        onClose={() => setShowUploadDocPopup(initialShowUploadDocPopup)}
      >
        <DialogContent>
          <div className="content-center">
            <img src={DoneIcon} alt="docDone" className="mb-2 mt-3" />
          </div>
          <p className="pop-desc text-center">
            {l(
              "Please do Nach registration, upload documents mentioned on this page",
              lang
            )}{" "}
            {!showUploadDocPopup?.isFirstPennyDrop && (
              <span style={{ color: "#F27E0A" }}>{l("again", lang)}</span>
            )}
          </p>
        </DialogContent>

        <div
          className="content-center"
          onClick={() => setShowUploadDocPopup(initialShowUploadDocPopup)}
        >
          <Button className="btn-submit">{l("OK", lang)}</Button>
        </div>
      </Dialog>

      <Dialog
        open={openCheque}
        onClose={handleCloseCheque}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">{l("Cheques", lang)}</DialogTitle>
        <DialogContent>
          <ErrorMessage />
          <div className="doc-modal mt-5">
            <Grid container spacing={2}>
              {[1, 1, 1, 1, 1, 1].map((val, ind) => (
                <Grid item xs={6} sm={6}>
                  <DocumentCard
                    text={l("Cheque", lang) + ` ${ind + 1}`}
                    isRequired={true}
                    icon={chequeIco}
                    tempState={
                      journeyData?.documents?.cheques?.[ind]?.fileLink || ""
                    }
                    isUpload={journeyData?.documents?.cheques?.[ind]}
                    status={journeyData?.documents?.cheques?.[ind]?.isApproved}
                    data={journeyData?.documents?.cheques?.[ind]}
                    isLoading={loading === "cheques"}
                    setFile={(file) => {
                      clearMessage();
                      handleUpload(
                        file,
                        "cheques",
                        journeyData?.documents?.cheques?.[ind]?.isApproved ===
                          false && journeyData?.documents?.cheques.length > 0
                          ? journeyData?.documents?.cheques?.[ind].name
                          : false
                      );
                    }}
                    disabled={
                      ind === 0
                        ? false
                        : !journeyData?.documents?.cheques?.length ||
                          journeyData?.documents?.cheques?.length < ind
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCheque}>{l("Close", lang)}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentMethod;
