import React from "react";

import ATinfo from "../components/ATinfo";
import { l } from "../../../../util/languageConvertor";
import { Button } from "@mui/material";

const ATdocumentUpload = ({ lang, posStyle }) => {
  return (
    <div className="AT_status_container">
      <ATinfo
        currentIndex={1}
        totleIndex={1}
        info={"Dealer can check the status of the documents"}
        bodyStyle={posStyle?.ATIbody}
        pointerStyle={posStyle?.ATIpointer}
      />
    
        <Button
          color="secondary"
          style={{
            backgroundColor: "white",
            borderRadius: 0,
          }}
          className="checkstatusbutt_AT"
        >
          {l("Check Status", lang)}
        </Button>
     
    </div>
  );
};

export default ATdocumentUpload;
