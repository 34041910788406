import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { GlobalContext } from "../Contexts/GlobalContext";

export default function useErrorMessage() {
  const { errorDataState } = useContext(GlobalContext);
  const [errorData, setErrorData] = errorDataState;
  const showMessage = async (message, type = "error") => {
    setErrorData({
      ...errorData,
      show: true,
      message,
      type,
    });
  };

  const clearMessage = async () => {
    setErrorData({
      ...errorData,
      show: false,
      message: "",
      type: "",
    });
  };

  return {
    showMessage,
    clearMessage
  };
}
