import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import {
  CircularProgress,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Skeleton,
  InputLabel,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { CustomerContext } from "../../Contexts/CustomerContext";
import SearchIcon from "@mui/icons-material/Search";
import emptyIco from "../../assets/empty.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  fetchAllApplications,
  fetchDashboardData,
  getCollectionsData,
  searchApplication,
} from "../../api/agent";
import RightArrow from "../../assets/arrow-right.svg";
import useApplication from "../../hooks/useApplication";
// import { getCollections, getTransactions } from "../../../api";

import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import { TimeOptions } from "../../../constants/Filter";
import { CollectionsData, userData } from "../constants/UserData";
import { cf } from "../util/CurrencyFormatter";
import { l } from "../../util/languageConvertor";

const drawerBleeding = 20;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const FilterCard = ({ count, onClick, text, filter, value }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        borderRadius: "7px",
        padding: "6px 10px",
        // width:'17em',
        backgroundColor: filter === value ? "#573A7A" : "#F9F3FF",
        color: filter === value ? "#fff" : "#573A7A",
      }}
      onClick={() => onClick(value)}
    >
      <span
        style={{
          fontSize: "11px",
        }}
      >
        {text}
      </span>
    </div>
  );
};

const CollectionCard = ({ amount, text }) => {
  return (
    <Grid
      sx={{
        backgroundColor: "#EDDCFF",
        borderRadius: "10px",
      }}
      xs={5}
      className="p-2 m-2"
    >
      <div
        style={{ display: "flex", justifyContent: "center", fontSize: "20px" }}
      >
        {amount}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", fontSize: "10px" }}
      >
        {text}
      </div>
    </Grid>
  );
};

export default function Collection(props) {
  const [open, setOpen] = React.useState(false);
  const ref = useRef(null);
  const location = useLocation();
  const enqueueSnackbar = useSnackbar();
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [status, setStatus] = useState("All");
  const [searchTxt, setSearchTxt] = useState("");
  const [letSearch, setLetSearch] = useState(false);
  const [time, setTime] = useState("Any time");
  const [filter, setFilter] = useState("All Applications");
  const [dashboardData, setDashboardData] = useState("");
  const [collectionType, setCollectionType] = useState("summary_view");
  const [collectionData, setCollectionData] = useState([]);
  const [collectionCardData, setCollectionCardData] = useState({
    totalCollected: 0,
    totalTransactions: 0,
  });

  const navigate = useNavigate();

  const { showPermissionState, applicationState, pathState, langState } =
    useContext(GlobalContext);
  const [_, setSelectedApplication] = applicationState;
  const [applicationPath, setAppPath] = pathState;
  const [lang, setLang] = langState;

  const { detailState, vehicleState } = useContext(CustomerContext);
  const [vehicleDetails, setVehicleDetails] = vehicleState;

  const fetchData = async () => {
    setLoading(true);
    try {
      let timeQuery = {};
      // if (time === "Any time") {
      //   timeQuery = { type: "All", value: 1 };
      // } else {
      //   timeQuery = {
      //     type: time.split(" "`)[1].toLowerCase(),
      //     value: time.split(" ")[0],
      //   };
      // }
      let { data } = await fetchAllApplications(
        filter.split(" ")[0]?.toLowerCase(),
        1,
        500
      );

      // console.log(data?.data[0]?.result?.data);

      setApplications(data?.data[0]?.result?.data);
    } catch (error) { }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  useEffect(() => {
    // let data = [];
    // userData.forEach((x)=> {
    //     x.collections.forEach(y=> {
    //         data.push({...x, collections: [y]})
    //     })
    // })
    // // console.log(data)
    // data.forEach(x=> {
    //    const a =  x.collections[0].collected_date.split("-");
    //    let k = a[1];
    //    a[1] = a[0];
    //    a[0] = k;
    //    x.collections[0].collected_date = a.join("-");
    // })
    // data.sort((x,y)=> {
    //     return  Number(new Date(y.collections[0].collected_date)) - Number(new Date(x.collections[0].collected_date))
    // })
    // setCollectionData(data);
    // console.log(data);

    let sortedArray = [];
    sortedArray = CollectionsData.sort((a, b) =>
      moment(a.collected_date, "DD-MM-YYYY").diff(
        moment(b.collected_date, "DD-MM-YYYY")
      )
    );

    setCollectionData(sortedArray);
    // console.log(sortedArray, '===')
  }, [CollectionsData]);

  useEffect(() => {
    window.onpopstate = () => {
      if (location?.state?.origin) {
        navigate(location?.state?.origin);
      } else {
        navigate("/home/menu");
      }
    };
  });

  let timeOut;

  useEffect(() => {
    if (searchTxt !== "") {
      clearTimeout(timeOut);
      setLetSearch(false);
      timeOut = setTimeout(() => {
        setLetSearch(true);
      }, 1000);
    }
  }, [searchTxt]);

  useEffect(() => {
    searchTxt !== "" && setSearching(true);

    if (letSearch) {
      if (searchTxt !== "") {
        (async () => {
          let timeQuery = {};
          // if (time === "Any time") {
          //   timeQuery = { type: "All", value: 1 };
          // } else {
          //   timeQuery = {
          //     type: time.split(" ")[1].toLowerCase(),
          //     value: time.split(" ")[0],
          //   };
          // }

          let { data } = await searchApplication(searchTxt, 500);
          if (data?.status) {
            setApplications(data?.data[0]?.result?.data);
          }
        })();
      } else {
        fetchData(status);
      }
      setSearching(false);
    }
  }, [letSearch]);

  const handleChangeFilter = (val) => {
    setFilter(val);
  };

  const getDashboardData = async () => {
    try {
      setLoading(true);
      let { data } = await fetchDashboardData();
      // console.log(data);
      setDashboardData(data?.data);
    } catch (error) {
      enqueueSnackbar(error?.message || "Error loading dashboard data", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const fetchColData = async () => {
    // setLoading(true);
    try {
      let { data } = await getCollectionsData();
      // console.log("collection data : ", data);
      setCollectionData(data?.data[0]?.result?.data);
      setCollectionCardData({
        totalCollected: data?.collectionData?.totalCollected,
        totalTransactions: data?.collectionData?.totalTransactions,
      });
    } catch (error) {
      console.log("collection error : ", error);
    }
    // setLoading(false);
  };

  const { getStage } = useApplication();

  const checkScroll = () => {
    if (window.scrollY) {
      ref.current.style.display = "none";
    } else {
      ref.current.style.display = "flex";
    }
  };

  // window.addEventListener("scroll", function () {
  //     if (window.pageYOffset >= 0 && window.pageYOffset <= 99) {
  //         ref.current.style.display = `flex`;
  //         ref.current.style.transform = `scale(${1 - Number(window.pageYOffset) / 100})`;
  //     } else {
  //         ref.current.style.display = `none`;
  //     }
  // });

  useEffect(() => {
    fetchColData();
    getDashboardData();
    // window.addEventListener('scroll', checkScroll)
    // return () => window.removeEventListener('scroll', checkScroll)
  }, []);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const container =
    props?.window !== undefined
      ? () => props?.window().document.body
      : undefined;

  return (
    <div className="content-center bg-new">
      {/* <div > */}

      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <Box sx={{ pb: 7 }} className="response-wrapper">
        <Grid
          style={{
            boxShadow: "none",
            border: "none",
            position: "sticky",
            top: 0,
            backgroundColor: "#F9F8FF",
          }}
          container
          spacing={2}
          className="p-3 pt-0"
        >
          <Grid item xs={12} sm={12}>
            <div className="page-head-collection">
              <ArrowBackIcon
                sx={{ color: "#573A7A" }}
                className="pointer"
                onClick={() => {
                  if (location?.state?.origin) {
                    navigate(location?.state?.origin);
                  } else {
                    navigate("/home/menu");
                  }
                }}
              />{" "}
              {l("Collections", lang)}
              <Grid
                sx={{ display: "flex", justifyContent: "space-around" }}
                item
                xs={12}
                sm={12}
              >
                <Grid item xs={12} sm={12} className="search-spacing">
                  <div style={{ position: "relative" }}>
                    <SearchIcon
                      className="search-ico"
                      style={{ color: "gray" }}
                      onClick={() => { }}
                    />

                    <input
                      type={"text"}
                      placeholder="Search Name"
                      className="form-input input-blue input-blue-search w-100"
                      value={searchTxt}
                      onChange={(e) => {
                        setSearchTxt(e.target.value);
                      }}
                      style={{ fontSize: "15px" }}
                    />
                  </div>
                </Grid>

                {/* <Grid sx={{
                                    //remove opacity 0 when using this please
                                    // opacity: 0
                                }} item xs={3} sm={3}>

                                    <FormControl size="small" xs={4} fullWidth>
                                        <InputLabel sx={{
                                            color: "#573A7A",
                                        }} id="demo-simple-select-label">Time</InputLabel>
                                        <Select size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            sx={{
                                                borderRadius: "10px"
                                            }}
                                            // value={age}
                                            label="Time"
                                            onChange={(e) => setTime(e.target.value)}
                                        >
                                            {
                                                TimeOptions.map((edx, index) => <MenuItem value={edx} key={index}>{edx}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid> */}

                {/* <Grid sx={{
                                    //remove opacity 0 when using this please
                                    // opacity: 0,
                                    border: "1px solid #573A7A",
                                    borderRadius: 2,
                                    fontSize: "15px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "37px"
                                }} item xs={2} sm={2} onClick={toggleDrawer(true)}>
                                    View
                                </Grid> */}
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
                xs={12}
                ref={ref}
              >
                <CollectionCard
                  amount={cf(collectionCardData?.totalCollected)}
                  text={l("Total Collection", lang)}
                />
                {/* <CollectionCard
                                    amount={"0"}
                                    text={"Delay"}
                                /> */}
              </Grid>
            </div>
          </Grid>

          {/* <Grid xs={12} className="all-application-filter-collection" fullWidth>
                        <FilterCard
                            count={dashboardData !== "" ? dashboardData?.totalApplications : "NA"}
                            onClick={val => handleChangeFilter(val)}
                            value={"All Applications"}
                            text={"All Applications"}
                            filter={filter}
                        />
                        <FilterCard
                            count={dashboardData !== "" ? dashboardData?.draftApplications : "NA"}
                            onClick={val => handleChangeFilter(val)}
                            value={"Approved Applications"}
                            text={"Due Today"}
                            filter={filter}
                        />
                        <FilterCard
                            count={dashboardData !== "" ? dashboardData?.approvedApplications : "NA"}
                            value={"Disbursed Applications"}
                            onClick={val => handleChangeFilter(val)}
                            text={"Delay"}
                            filter={filter}
                        />

                        <FilterCard
                            count={dashboardData !== "" ? dashboardData?.activeLoans : "NA"}
                            value={"Closed"}
                            onClick={val => handleChangeFilter(val)}
                            text={"Closed"}
                            filter={filter}
                        />

                    </Grid> */}
        </Grid>
        <Grid container spacing={2} className="p-3 mt-2 pt-0">
          <Grid item xs={12} sm={12}>
            {isSearching && (
              <p className="w-100 text-center mt-2 mb-2">
                <CircularProgress size={25} />
              </p>
            )}

            {!isLoading ? (
              <>
                {collectionType === "summary_view"
                  ? collectionData?.length !== 0 &&
                  collectionData
                    ?.filter((edx) => {
                      let search = edx?.customerData?.is_corporate ? edx?.customerData?.corporate_pan_details?.name : edx?.customerData?.pan_details?.name
                      if (search?.toLowerCase()?.includes(searchTxt?.toLowerCase())) {
                        return edx
                      }
                    })
                    ?.map((val) => {
                      return (
                        <>
                          <Container
                            className="load-collapse-row collapse-shadow pointer"
                            onClick={() => {
                              // getStage(
                              //     val?.creation_stage,
                              //     val?.application_id,
                              //     val?.coCustomerData ? true : false
                              // );
                            }}
                          >
                            <Row className="mt-2">
                              <Col className="font-blue">
                                <div
                                  className="w-100"
                                  onClick={() => {
                                    navigate("/borrower-profile", {
                                      state: {
                                        customer: val,
                                        origin: "/collection",
                                      },
                                    });
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {val?.customerData?.is_corporate
                                      ? val?.customerData?.corporate_pan_details
                                        ?.name
                                      : val?.customerData?.pan_details?.name ||
                                      "-----"}
                                  </div>
                                  {/* <div style={{
                                                                        fontWeight: 400,
                                                                        fontSize: "10px",
                                                                    }}>
                                                                        Due Date :
                                                                        {moment(val?.nextDueDate).format('DD/MM/YYYY') ?? '-----'}
                                                                    </div> */}
                                </div>
                              </Col>

                              <Col className="font-blue align-right">
                                <div className="w-100">
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {cf(val?.totalCollected) ?? "-----"}
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <img src={RightArrow} />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {filter === "Rejected Applications" && (
                              <Row className="">
                                <Col style={{ fontSize: 10, color: "red" }}>
                                  {val?.embifi_approval_details?.remark}
                                </Col>
                              </Row>
                            )}
                          </Container>
                        </>
                      );
                    })
                  : collectionData?.map((edx) => {
                    return (
                      <Container className="load-collapse-row collapse-shadow pointer">
                        <Row className="mt-2">
                          <Col className="font-blue">
                            <div className="w-100">
                              <div
                                style={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                }}
                              >
                                {/* {edx?.name ?? "-----"} */}
                                {edx?.customerData?.is_corporate
                                  ? edx?.customerData?.corporate_pan_details
                                    ?.name
                                  : edx?.customerData?.pan_details?.name ||
                                  "-----"}
                              </div>
                              {/* <div style={{
                                                                    fontWeight: 400,
                                                                    fontSize: "10px",
                                                                }}>
                                                                    Date : {edx?.collected_date ?? "-----"}
                                                                </div> */}
                            </div>
                          </Col>
                          <Col className="font-blue align-right">
                            <div className="w-100">
                              <div
                                style={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                ₹ {edx?.collected_amt}
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                Cash
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {filter === "Rejected Applications" && (
                          <Row className="">
                            <Col style={{ fontSize: 10, color: "red" }}>
                              {edx?.embifi_approval_details?.remark}
                            </Col>
                          </Row>
                        )}
                      </Container>
                    );
                  })}
                {collectionData.length === 0 && (
                  <p className="text-center mt-5">
                    <img src={emptyIco} />
                    <p style={{ color: "gray" }}>{l("No Applications here", lang)}</p>
                  </p>
                )}{" "}
              </>
            ) : (
              <>
                {["", "", ""].map((val) => {
                  return (
                    <>
                      <Container className="load-collapse-row collapse-blue pointer">
                        <Row className="mt-2">
                          <Col className="font-blue">
                            <Skeleton width={"100%"} />
                          </Col>
                          <Col className="font-blue">
                            <Skeleton width={"100%"} />
                          </Col>
                          <Col className="font-blue">
                            <Skeleton width={"100%"} />
                          </Col>
                        </Row>
                      </Container>
                    </>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            // bottom: 100,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
            backgroundColor: "red",
          }}
        >
          <Puller />
          {/* <Typography sx={{ p: 2, color: 'text.secondary' }}>51 results</Typography> */}
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          {(
            <Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  fontWeight: collectionType === "summary_view" ? "600" : "200",
                  padding: "15px",
                }}
                onClick={() => {
                  setCollectionType("summary_view");
                  setOpen(false);
                }}
              >
                Summary View
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  fontWeight: collectionType === "transactions" ? "600" : "200",
                  padding: "15px",
                }}
                onClick={() => {
                  setOpen(false);
                  setCollectionType("transactions");
                }}
              >
                Transactions
              </Grid>
            </Grid>
          ) || <Skeleton variant="rectangular" height="100%" />}
        </StyledBox>
      </SwipeableDrawer>
    </div>
  );
}

Collection.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
