import React from "react";
import { Grid } from "@mui/material";
import { l } from "../../../../util/languageConvertor";
import ATinfo from "../components/ATinfo";

const ATFieldInvestigation = ({ lang, posStyle }) => {
  return (
    <div className="AT_Container">
      <ATinfo
        currentIndex={1}
        totleIndex={1}
        info={"Fill the details of the person who is going for the investigation"}
        bodyStyle={posStyle?.ATIbody}
        pointerStyle={posStyle?.ATIpointer}
      />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <label className="form-label mt-2" style={{ fontSize: 13 }}>
            {l("Agent Name", lang)} <span className="mand-star">*</span>
          </label>
        </Grid>
        <Grid item xs={6} sm={6}>
          <input
            type="text"
            className="form-input w-100 px-3 white-bg"
            required
            placeholder={l("Agent Name", lang)}
            style={{ fontSize: "14px" }}
            name="name"
            autoComplete="family-name"
            disabled
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <label className="form-label mt-2" style={{ fontSize: 13 }}>
          {l("Agent phone Number", lang)}{" "} <span className="mand-star">*</span>
          </label>
        </Grid>
        <Grid item xs={6} sm={6}>
          <input
            type="text"
            className="form-input w-100 px-3 white-bg"
            required
            placeholder={l("Agent phone Number", lang)}
            style={{ fontSize: "14px" }}
            name="name"
            autoComplete="family-name"
            disabled
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ATFieldInvestigation;
