import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOver from "../../../../components/LoadingOver";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import Congrats from "./Congrats";
import Failed from "./Failed";
import Rejected from "./Rejected";
import UnderReview from "./UnderReview";

const CustomerStatus = () => {
  const navigate = useNavigate();
  const { application_id } = useParams();

  const [status, setStatus] = useState("loading");
  const [loading, setLoading] = useState(true);

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  useEffect(() => {
    switch (journeyData?.stage) {
      case "CUSTOMER_CREDIT_ENGINE_FAILED":
        setStatus("review");
        break;
      case "CUSTOMER_UNDER_REVIEW":
        setStatus("review");
        break;
      case "CUSTOMER_EMBIFI_REJECTED":
        setStatus("rejected");
        break;
      case "CO_CUSTOMER_CREATION":
        setStatus("success-co");
        break;
      case "LOAN_UPDATE":
        setStatus("success");
        break;
      case "NBFC_ASSIGN_PENDING":
        setStatus("review");
        break;
      case "LOADING":
        setStatus("loading");
        break;
      default:
        setStatus("failed");
    }
  }, [journeyData?.stage]);

  const { getApplicationDetails, getStage } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id, true, setLoading);
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/draft-applications");
    };
  });

  return (
    <>
      <div className="response-wrapper w-100">
        {!loading && (
          <>
            {status === "review" && <UnderReview />}
            {status === "success" && (
              <Congrats
                origin={"customer"}
                application_id={application_id}
                action={() => {
                  navigate("/create-loan/loan-creation/" + application_id);
                }}
              />
            )}
            {status === "success-co" && (
              <Congrats
                origin={"customer"}
                coNeeded={true}
                action={() => {
                  navigate("/create-loan/co-customer/" + application_id);
                }}
              />
            )}
            {status === "rejected" && <Rejected origin={"customer"} />}
            {status === "failed" && <Failed />}
          </>
        )}
      </div>
      {/* {status === "loading" && <LoadingOver />} */}
    </>
  );
};

export default CustomerStatus;
