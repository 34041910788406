import SingleTick from "../../assets/single-tick.svg";
import DoubleTick from "../../assets/double-tick.svg";
import RejectedIcon from "../../assets/rejected-tick.svg";

export const DocStatusMap = {
  null: "",
  uploaded: SingleTick,
  register_success: DoubleTick,
  register_failed: RejectedIcon,
};

export const speedMap = {
  EMAPL09052300000464: 472,
  EMAPL11042300000400: 784,
  EMAPL12042300000405: 784,
  EMAPL20042300000445: 912,
  EMAPL20042300000446: 952,
  EMAPL12042300000406: 984,
  EMAPL20042300000452: 1040,
  EMAPL20042300000448: 1040,
  EMAPL20042300000449: 1072,
  EMAPL20042300000442: 1112,
  EMAPL20042300000443: 1120,
  EMAPL20042300000444: 1176,
  EMAPL12042300000407: 1192,
  EMAPL12042300000408: 1240,
  EMAPL20042300000450: 1248,
  EMAPL12042300000410: 1312,
  EMAPL12042300000409: 1320,
  EMAPL20042300000451: 1336,
};
