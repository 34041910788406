import {
  BottomNavigation,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import MenuAppBar from "../../../../components/Appbar";
import {
  isBlank,
  isEmailValid,
  isMobValid,
} from "../../../../validations/fieldValidations";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../../../components/Select";
import { Col, Container, Row } from "react-bootstrap";
import {
  isVehicleCost,
  maxLoanAmountVani,
} from "../../../../validations/docValidations";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import { updateLoanData } from "../../../../api/agent";
import moment from "moment";
import useApplication from "../../../../hooks/useApplication";
import { l } from "../../../../util/languageConvertor";
import ErrorMessage from "../../../../components/V3/ErrorMessage";
import useErrorMessage from "../../../../hooks/useErrorMessage";
import CustomInputAutocomplete from "../../../../components/Autocomplete/AutoComplete";



import MixPanelAudit from "../../../../util/MixPanelAudit";
import { UserContext } from "../../../../Contexts/UserContext";
import ApptourTxt from "../../../../constants/Apptours";
import AppTour from "../../AppTour/AppTour";
import ATLoanCreation1 from "../../AppTour/LoanCreation/ATLoanCreation1";
import ATLoanCreation2 from "../../AppTour/LoanCreation/ATLoanCreation2";



const LoanCreationVani = forwardRef((props, ref) => {
  const { application_id } = useParams();
  const batteryType = ["Lithium Ion", "Lead Acid"];

  const { user } = useContext(UserContext);
  const { bottomNav, langState  , globalLoaderState} = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const navigate = useNavigate();
  const selectRef = useRef();
  const [globalLoader, setGlobalLoader] = globalLoaderState;
  const refs = {
    ex_price: useRef(),
    battery_ah: useRef(),
  };

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { showMessage, clearMessage } = useErrorMessage();
  const [showappTour, setShowAppTour] = useState(true);
  const [apptourindex, SetApptourIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [isAccepted, setAccepted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showFinalCalculation, setFinalCalculation] = useState(false);
  const [maxLoan, setMaxLoan] = useState(0);
  const [proceed, setProceed] = useState(false);
  const [oemName, setOemName] = useState('');

  const [fieldErrors, setFieldErrors] = useState({
    model: "",
    total_cost: "",
    down_pay: "",
    ex_price: "",
  });

  const [processingCharge, setProcessingCharge] = useState({
    ammount: null,
    rate: null,
  });
  const [subvention, setSubvention] = useState({
    ammount: null,
    rate: null,
  });
  const [OEMDetails, setOEMDetails] = useState([]);

  function verify(value){
    return [undefined,null].includes(value)
  }

  let isValid = async () => {
    let valid = true;
    let errors = {
      ex_price:
        Number(journeyData?.vehicle_price_ex) >=
        Number(journeyData?.vehicle_price_on_road)
          ? "Ex-showroom price cannot be higher than onroad price"
          : "",
      battery_ah:
        Number(journeyData?.battery_ah) < 100 ||
        Number(journeyData?.battery_ah) > 200
          ? l("Battery Ah should be between 100-200", lang)
          : "",
    };

    setFieldErrors(errors);

    let first = false;
    for (let i in errors) {
      if (errors[i].length > 0) {
        if (!first) {
          first = true;
          refs[i]?.current?.focus();
          window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
        }
        valid = false;
      }
    }
    if (
      verify(journeyData?.processing_charge_rate) ||
      verify(journeyData?.processing_charge_amount) ||
      verify(journeyData?.down_payment) ||
      verify(journeyData?.interest_rate)
    ) {
      valid = false;
      window.scrollTo(0, 0);
      showMessage("Please contact our support team for OEM details");
    }

    return valid;
  };

  useEffect(() => {
    let val =
      (Number(journeyData?.loan_amount) *
        (Number(journeyData.interest_rate) / 100) *
        Number(journeyData?.tenure)) /
      12;

    // ////////////////////////

    let startDate = moment(new Date());
    let endDate = moment(new Date()).add(Number(journeyData?.tenure), "months");

    let ediDays = moment(endDate).diff(moment(startDate), "days");

    let numerator =
      Number(journeyData?.loan_amount) +
      Number(journeyData?.loan_amount) *
        (Number(journeyData?.interest_rate) / 100) *
        (Number(journeyData?.tenure) / 12);

    // console.log(numerator);

    let emi = (numerator / Number(journeyData?.tenure)).toFixed(2);
    let edi = (numerator / ediDays).toFixed(2);
    let down_payment =
      Number(journeyData.vehicle_price_on_road) -
      Number(journeyData?.loan_amount);
    let processing_charge_rate = processingCharge.rate
      ? processingCharge.rate
      : processingCharge.ammount
      ? (Number(processingCharge.ammount) / Number(journeyData?.loan_amount)) *
        100
      : processingCharge.rate;
    let processing_charge_amount = processingCharge.ammount
      ? processingCharge.ammount
      : processingCharge.rate
      ? (Number(processingCharge.rate) / 100) * Number(journeyData?.loan_amount)
      : processingCharge.ammount;
    let subvention_percentage = subvention.rate
      ? subvention.rate
      : subvention.ammount
      ? (Number(subvention.ammount) / Number(journeyData?.loan_amount)) * 100
      : subvention.rate;
    let subvention_amount = subvention.ammount
      ? subvention.ammount
      : subvention.rate
      ? (Number(subvention.rate) / 100) * Number(journeyData?.loan_amount)
      : subvention.ammount;

    setJourneyData((pre) => ({
      ...pre,
      down_payment,
      interest_amount: Math.ceil(val),
      emi_amount: Math.ceil(emi),
      edi_amount: Math.ceil(edi),
      processing_charge_rate,
      processing_charge_amount,
      subvention_percentage,
      subvention_amount,
      // tenure,
    }));
  }, [
    journeyData?.loan_amount,
    journeyData.vehicle_price_on_road,
    journeyData?.interest_rate,
    journeyData?.tenure,
    processingCharge,
    subvention,
  ]);

  useEffect(() => {
    if (journeyData?.tenure) {
      let tenure;
      if (
        Number(journeyData?.loan_amount) < 90000 &&
        Number(journeyData?.tenure) > 15
      ) {
        tenure = 15;
      } else {
        tenure = Number(journeyData?.tenure);
      }

      setJourneyData((pre) => ({
        ...pre,
        tenure,
      }));
    }
  }, [journeyData?.tenure, journeyData?.loan_amount]);

  const handleSubmit = async () => {
    if (!(await isValid())) {
      return;
    }
    MixPanelAudit.intercept('loan_detail_proceed_click', {
      application_id,
    });
    setLoading(true);
    let payload = {
      vehicle_model: journeyData?.vehicle_model,
      battery_brand: journeyData?.battery_brand,
      battery_capacity: journeyData?.battery_ah,
      vehicle_price_on_road: journeyData?.vehicle_price_on_road,
      vehicle_price_ex_showroom: journeyData?.vehicle_price_ex,
      loan_amount: journeyData?.loan_amount,
      down_payment: journeyData?.down_payment,
      interest_rate: journeyData?.interest_rate,
      tenure_type: "months",
      tenure_value: journeyData?.tenure,
      processing_charge_rate: journeyData?.processing_charge_rate,
      battery_type: journeyData?.battery_name,
      oem_id: journeyData?.oem_id,
      subvention_amount: journeyData?.subvention_amount,
      hold_back_amount: journeyData?.holdback_amount_perfile,
    };

    try {
      let { data } = await updateLoanData(payload, application_id);
      if (data?.status) {
        MixPanelAudit.intercept('loan_detail_proceed_status', {
          application_id,
          status: 'done',
        });
        navigate(`/create-loan/status/${application_id}`);
      }
    } catch (err) {
      MixPanelAudit.intercept('loan_detail_proceed_status', {
        application_id,
        status: err?.response?.data?.message,
      });
      showMessage(l(err?.response?.data?.message,lang))
      // enqueueSnackbar(l(err?.response?.data?.message,lang), { variant: "error" });
    }
    setLoading(false);
  };

  const { getApplicationDetails } = useApplication();

  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    const {
      oem_id,
      battery_name,
      vehicle_model,
      vehicle_price_on_road,
      vehicle_price_ex,
      battery_brand,
      battery_ah,
      loan_amount,
      down_payment,
      interest_rate,
      tenure,
      interest_amount,
      processing_charge_rate,
      // processing_charge_amount,
      emi_amount,
      // edi_amount,
      subvention_amount,
      holdback_amount_perfile,
    } = journeyData;

    if (
      oem_id !== "" &&
      oem_id !== null &&
      battery_name !== "" &&
      battery_name !== null &&
      vehicle_model !== "" &&
      vehicle_model !== null &&
      vehicle_price_on_road !== "" &&
      vehicle_price_ex !== "" &&
      battery_brand !== "" &&
      battery_brand !== null &&
      battery_ah !== "" &&
      battery_ah !== null &&
      loan_amount !== "" &&
      down_payment !== "" &&
      interest_rate !== "" &&
      tenure !== "" &&
      interest_amount !== "" &&
      processing_charge_rate !== "" &&
      // processing_charge_amount !== "" &&
      emi_amount !== "" &&
      // edi_amount !== ""
      subvention_amount !== "" &&
      holdback_amount_perfile !== ""
    ) {
      setProceed(true);
    } else {
      setProceed(false);
    }
    clearMessage();
  }, [journeyData]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/draft-applications");
    };
  });

  useEffect(() => {
    if (
      Array.isArray(journeyData?.OEMData) &&
      journeyData?.OEMData?.length === 1
    ) {
      setJourneyData({
        ...journeyData,
        oem_name: journeyData?.OEMData?.[0]?.oem_name,
        oem_id: journeyData?.OEMData?.[0]?.oem_id,
        interest_rate: journeyData?.OEMData?.[0]?.interest_rate,
        battery_type: journeyData?.OEMData?.[0]?.battery_info,
        holdback_amount_perfile:
          journeyData?.OEMData?.[0]?.hold_back_amount?.per_file,
        holdback_amount_total:
          journeyData?.OEMData?.[0]?.hold_back_amount?.total,
        loan_amount: 50000,
        tenure: 12,
      });
      setProcessingCharge({
        ammount: journeyData?.OEMData?.[0]?.processing_fees,
        rate: journeyData?.OEMData?.[0]?.processing_fees_percent,
      });
      setSubvention({
        ammount: journeyData?.OEMData?.[0]?.subvention?.amount,
        rate: journeyData?.OEMData?.[0]?.subvention?.percent,
      });
    }
  }, [journeyData?.OEMData]);

  useEffect(() => {
    const data = journeyData?.OEMData?.map((ele) => {
      return `${ele?.oem_id}-${ele?.oem_name}`;
    });
    setOEMDetails(data);
  }, [journeyData?.OEMData]);

  useEffect(() => {
    if (journeyData?.battery_name) {
      const MLA_found = journeyData?.battery_type?.filter((ele) => {
        if (ele.battery_type === journeyData?.battery_name) {
          return true;
        }
        return false;
      });
      setMaxLoan(
        MLA_found?.[0]?.max_loan_amount ? MLA_found[0]?.max_loan_amount : 110000
      );
    }
  }, [journeyData?.battery_name, journeyData?.battery_type]);

  return (
    <>
      <Box className="kyc-wrapper" sx={{ pb: 7, pt: 6 }} ref={ref}>
        <MenuAppBar home={false} bigText={"Loan Details"} noBack={true} draft={true} />
        <CssBaseline />
        <ErrorMessage />
        <div className="main-wrapper">
          {/* OEM DropDown */}
          <Grid container>
            <Grid item xs={12} sm={12}>
              <label className="form-label mt-2">
                {l("OEM Name", lang)} <span className="mand-star">*</span>
              </label>

              <CustomInputAutocomplete
                options={OEMDetails}
                text={l("Select a OEM Name", lang)}
                onChange={(val) => {
                  journeyData?.OEMData?.map((ele) => {
                    if (ele.oem_id === val) {
                      setJourneyData({
                        ...journeyData,
                        oem_name: ele?.oem_name,
                        oem_id: ele?.oem_id,
                        interest_rate: ele?.interest_rate,
                        battery_type: ele?.battery_info,
                        holdback_amount_perfile:
                          ele?.hold_back_amount?.per_file,
                        holdback_amount_total: ele?.hold_back_amount?.total,
                        loan_amount: 50000,
                        tenure: 12,
                      });
                      setProcessingCharge({
                        ammount: ele?.processing_fees,
                        rate: ele?.processing_fees_percent,
                      });
                      setSubvention({
                        ammount: ele?.subvention?.amount,
                        rate: ele?.subvention?.percent,
                      });
                      setOemName(`${ele?.oem_id}-${ele?.oem_name}`);
                    }
                  });
                }}
                currentValue={oemName}
              />
            </Grid>
          </Grid>

          {/* Vehicle Details */}
          <Grid container spacing={2} className="mt-2">
            <Grid item xs={12} sm={12}>
              <label
                className="form-label "
                style={{ fontSize: 20, fontWeight: "bold" }}
              >
                {l("Vehicle Details", lang)}
              </label>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label " style={{ fontSize: 13 }}>
                {l("Vehicle Model", lang)} <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                type="text"
                className="form-input w-100 px-3 input-blue"
                required
                placeholder={l("Vehicle Model", lang)}
                autoComplete="off"
                value={journeyData.vehicle_model}
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    vehicle_model: e.target.value,
                  });
                }}
                // onKeyUp={(e) =>
                //   setFieldErrors((st) => ({
                //     ...st,
                //     model: isBlank(e.target.value),
                //   }))
                // }
              />
              {/* <span className="error">{fieldErrors.model}</span> */}
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label mt-2" style={{ fontSize: 13 }}>
                {l("Battery Brand", lang)} <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                type="text"
                className="form-input w-100 px-3 input-blue"
                required
                placeholder={l("Battery Brand", lang)}
                autoComplete="off"
                value={journeyData.battery_brand}
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    battery_brand: e.target.value,
                  });
                }}
                // onKeyUp={(e) =>
                //   setFieldErrors((st) => ({
                //     ...st,
                //     model: isBlank(e.target.value),
                //   }))
                // }
              />
              {/* <span className="error">{fieldErrors.model}</span> */}
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label mt-2" style={{ fontSize: 13 }}>
                {l("Battery Type", lang)} <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <CustomInputAutocomplete
                options={batteryType}
                style={{
                  height: "30px !important",
                  padding: "15px !important",
                }}
                text={l("Battery Type", lang)}
                onChange={(val) => {
                  setJourneyData({
                    ...journeyData,
                    battery_name: val.toLowerCase().replace(/\s+/g, "_"),
                  });
                }}
                currentValue={journeyData?.battery_name
                  ?.split("_")
                  ?.map(
                    (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
                  )
                  ?.join(" ")}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label mt-2" style={{ fontSize: 13 }}>
                {l("Battery AH", lang)} <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.battery_ah}
                type="text"
                className="form-input w-100 px-3 input-blue"
                required
                placeholder={l("Battery AH", lang)}
                autoComplete="off"
                value={journeyData.battery_ah}
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value.length > 3) return false;
                  setFieldErrors({ ...fieldErrors, battery_ah: "" });
                  setJourneyData({
                    ...journeyData,
                    battery_ah: e.target.value,
                  });
                }}
                // onKeyUp={(e) =>
                //   setFieldErrors((st) => ({
                //     ...st,
                //     model: isBlank(e.target.value),
                //   }))
                // }
              />
              <span className="error">{fieldErrors?.battery_ah}</span>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label mt-2" style={{ fontSize: 13 }}>
                {l("Vehicle Price On Road", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                type="text"
                className="form-input w-100 input-blue"
                required
                placeholder={l("Vehicle Price On Road", lang)}
                inputMode="numeric"
                autoComplete="off"
                value={journeyData.vehicle_price_on_road}
                maxLength="7"
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  setJourneyData({
                    ...journeyData,
                    vehicle_price_on_road: e.target.value,
                  });
                }}
                // onBlur={(e) =>
                //   setFieldErrors((st) => ({
                //     ...st,
                //     total_cost: isVehicleCost(e.target.value),
                //   }))
                // }
              />
              <span className="error">{fieldErrors.total_cost}</span>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label mt-2" style={{ fontSize: 13 }}>
                {l("Vehicle Price Ex(showroom)", lang)}
                <span className="mand-star">*</span>
              </label>
            </Grid>
            <Grid item xs={6} sm={6}>
              <input
                ref={refs?.ex_price}
                type="text"
                className="form-input w-100 input-blue"
                required
                placeholder={l("Vehicle Price Ex(showroom)", lang)}
                inputMode="numeric"
                autoComplete="off"
                value={journeyData.vehicle_price_ex}
                maxLength="7"
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  setFieldErrors({ ...fieldErrors, ex_price: "" });
                  setJourneyData({
                    ...journeyData,
                    vehicle_price_ex: e.target.value,
                  });
                }}
                // onBlur={(e) =>
                //   setFieldErrors((st) => ({
                //     ...st,
                //     total_cost: isVehicleCost(e.target.value),
                //   }))
                // }
              />
              <span className="error">{fieldErrors?.ex_price}</span>
            </Grid>
          </Grid>

          {/* Loan Details */}
          {!(
            isVehicleCost(journeyData?.vehicle_price_on_road || 0) !== "" ||
            isVehicleCost(journeyData?.vehicle_price_ex || 0) !== "" ||
            maxLoan < 50000
          ) && (
            <Grid container spacing={2} className="mt-2">
              <Grid item xs={12} sm={12}>
                <label
                  className="form-label "
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  {l("Loan Details", lang)}
                </label>
              </Grid>

              <Grid item xs={12} sm={12} className="content-center">
                <Box sx={{ width: "95%" }}>
                  <Slider
                    aria-label="Restricted values"
                    defaultValue={maxLoan}
                    color={"secondary"}
                    value={Number(journeyData?.loan_amount)}
                    disabled={
                      isVehicleCost(journeyData.vehicle_price_on_road) !== "" ||
                      maxLoan < 50000
                    }
                    onChange={(e) => {
                      setJourneyData((st) => ({
                        ...st,
                        loan_amount: e.target.value,
                      }));
                    }}
                    min={
                      isVehicleCost(journeyData.vehicle_price_on_road) === "" &&
                      50000
                    }
                    max={maxLoan > 50000 && maxLoan}
                    step={maxLoan > 75000 ? 1000 : maxLoan - 50000}
                    marks={[
                      {
                        value: 50000,
                        label: "50000",
                      },
                      {
                        value: maxLoan,
                        label: maxLoan + "",
                      },
                    ]}
                  />
                </Box>
              </Grid>

              <Grid item xs={6} sm={6}>
                <label className="form-label mt-2" style={{ fontSize: 15 }}>
                  {l("Loan Amount", lang)} <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="number"
                  className="form-input w-100 input-blue"
                  disabled
                  placeholder={l("Loan Amount", lang)}
                  name="number"
                  autoComplete="off"
                  value={journeyData.loan_amount}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <label className="form-label mt-2" style={{ fontSize: 15 }}>
                  {l("Down Payment", lang)} <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 input-blue"
                  disabled
                  placeholder={l("Down Payment", lang)}
                  name="number"
                  autoComplete="off"
                  value={
                    journeyData?.down_payment
                      ? journeyData?.down_payment
                      : "N/A"
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <label className="form-label mt-2" style={{ fontSize: 15 }}>
                  {l("Interest Rate", lang)}{" "}
                  <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 input-blue"
                  disabled
                  placeholder={l("Interest Rate", lang)}
                  autoComplete="off"
                  value={
                    journeyData.interest_rate
                      ? journeyData.interest_rate + " %"
                      : "N/A"
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <label
                  className="form-label mt-2"
                  style={{ fontSize: 14, color: "blue !important" }}
                >
                  {l("Interest Amount", lang)}{" "}
                  <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 px-3 input-blue"
                  disabled
                  autoComplete="off"
                  value={
                    journeyData.interest_amount
                      ? journeyData.interest_amount
                      : "N/A"
                  }
                />
              </Grid>

              {/* <Grid item xs={12} sm={12} className="content-center">
                    <Box sx={{ width: "95%" }}>
                      <Slider
                        aria-label="Restricted values"
                        defaultValue={journeyData.interest_rate}
                        disabled={
                          isVehicleCost(journeyData.vehicle_price_on_road) !==
                            "" || maxLoan < 50000
                        }
                        min={15}
                        max={1  8}
                        step={0.5}
                        onChange={(e) => {  
                          setJourneyData((st) => ({
                            ...st,
                            interest_rate: e.target.value,
                          }));
                        }}
                        marks={marks}
                      />
                    </Box>
                  </Grid> */}

              {journeyData?.edi_amount > 0 && journeyData?.emi_amount > 0 && (
                <Grid item xs={12} sm={12} className="loan-collapse">
                  <Container
                    className="installment-row pt-3"
                    style={{ borderRadius: "5px" }}
                  >
                    <Row>
                      <Col className="font-blue d-flex justify-content-start">
                        {l("EMI Amount", lang)}
                      </Col>
                      <Col className="font-blue">₹{journeyData.emi_amount}</Col>
                    </Row>
                  </Container>

                  {/* <Container
                      className="installment-row pt-3"
                      style={{ borderRadius: "0 0 20px 0px" }}
                    >
                      <Row>
                        <Col className="font-blue d-flex justify-content-start">
                          {l("EDI Amount", lang)}
                        </Col>
                        <Col className="font-blue">
                          ₹{journeyData.edi_amount}
                        </Col>
                      </Row>
                    </Container> */}
                </Grid>
              )}

              <Grid item xs={12} sm={12} className="content-center">
                <Box sx={{ width: "90%" }}>
                  <Slider
                    aria-label="Restricted values"
                    color={"secondary"}
                    defaultValue={journeyData.tenure}
                    valueLabelDisplay="on"
                    disabled={
                      isVehicleCost(journeyData.vehicle_price_on_road) !== "" ||
                      maxLoan < 50000
                    }
                    min={12}
                    max={journeyData?.loan_amount > 90000 ? 18 : 15}
                    step={3}
                    onChange={(e) => {
                      setJourneyData((st) => ({
                        ...st,
                        tenure: e.target.value,
                      }));
                    }}
                    marks={
                      journeyData?.loan_amount > 90000
                        ? [
                            {
                              value: 12,
                              label: "12 Months",
                            },
                            {
                              value: 15,
                              label: "15 Months",
                            },
                            {
                              value: 18,
                              label: "18 Months",
                            },
                          ]
                        : [
                            {
                              value: 12,
                              label: "12 Months",
                            },
                            {
                              value: 15,
                              label: "15 Months",
                            },
                          ]
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={6} sm={6}>
                <label className="form-label mt-2" style={{ fontSize: 15 }}>
                  {l("Loan Tenure (In Months)", lang)}{" "}
                  <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 input-blue"
                  disabled
                  placeholder={l("Loan Tenure (In Months)", lang)}
                  autoComplete="off"
                  value={journeyData.tenure + " Months"}
                />
              </Grid>
            </Grid>
          )}

          {/* Commercials */}
          {!(
            isVehicleCost(journeyData?.vehicle_price_on_road || 0) !== "" ||
            isVehicleCost(journeyData?.vehicle_price_ex || 0) !== "" ||
            maxLoan < 50000
          ) && (
            <Grid container spacing={2} className="mt-2">
              <Grid item xs={12} sm={12}>
                <label
                  className="form-label "
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  {l("Commercials", lang)}
                </label>
              </Grid>

              <Grid item xs={6} sm={6}>
                <label
                  className="form-label mt-2"
                  style={{ fontSize: 14, color: "blue !important" }}
                >
                  {l("File Charge Rate", lang)} (%)
                  <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 px-3 input-blue"
                  autoComplete="off"
                  disabled
                  value={
                    journeyData?.processing_charge_rate
                      ? Number(journeyData?.processing_charge_rate)?.toFixed(
                          1
                        ) + "%"
                      : "N/A"
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <label
                  className="form-label mt-2"
                  style={{ fontSize: 14, color: "blue !important" }}
                >
                  {l("File Charge Amount", lang)}
                  <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 px-3 input-blue"
                  disabled
                  autoComplete="off"
                  value={
                    journeyData?.processing_charge_amount
                      ? Number(journeyData?.processing_charge_amount)?.toFixed(
                          2
                        )
                      : "N/A"
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <label
                  className="form-label mt-2"
                  style={{ fontSize: 14, color: "blue !important" }}
                >
                  {l("Hold Back", lang)}
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 px-3 input-blue"
                  disabled
                  autoComplete="off"
                  value={
                    journeyData?.holdback_amount_perfile
                      ? Number(journeyData?.holdback_amount_perfile)?.toFixed(2)
                      : "N/A"
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <label
                  className="form-label mt-2"
                  style={{ fontSize: 14, color: "blue !important" }}
                >
                  {l("Subvention ", lang)} (%)
                  <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  disabled
                  type="text"
                  className="form-input w-100 px-3 input-blue"
                  autoComplete="off"
                  value={
                    journeyData?.subvention_percentage
                      ? Number(journeyData?.subvention_percentage)?.toFixed(1) +
                        "%"
                      : "N/A"
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <label
                  className="form-label mt-2"
                  style={{ fontSize: 14, color: "blue !important" }}
                >
                  {l("Subvention Amount", lang)}
                  <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 px-3 input-blue"
                  disabled
                  autoComplete="off"
                  value={
                    journeyData?.subvention_amount
                      ? Number(journeyData?.subvention_amount)?.toFixed(2)
                      : "N/A"
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <label
                  className="form-label mt-2"
                  style={{ fontSize: 14, color: "blue !important" }}
                >
                  {l("GPS charges", lang)}
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 px-3 input-blue"
                  disabled
                  autoComplete="off"
                  value={
                    journeyData?.gps_charges
                      ? Number(journeyData?.gps_charges)?.toFixed(2)
                      : "N/A"
                  }
                />
              </Grid>
            </Grid>
          )}
        </div>

        <Paper className="paper-bottom" elevation={3} ref={bottomNav}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className={`btn-submit ${!proceed && "btn-submit-disabled"}`}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={!proceed || isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    {l("Loading", lang)}
                  </>
                ) : (
                  l("Proceed", lang)
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
      {!globalLoader && !user?.seen_app_tour?.LOAN_DETAILS_SCREEN && (
        <AppTour
          screenName={"LOAN_DETAILS_SCREEN"}
          texts={[ApptourTxt.RTO_Insurance, ApptourTxt.dealer_Profit]}
          index={apptourindex}
          setIndex={SetApptourIndex}
          show={showappTour}
          screenContent={[
            <ATLoanCreation1
              lang={lang}
            posStyle={{
              ATIbody: "loancreactionAT1",
              ATIpointer: "loancreationATP",
            }}
            />,
            <ATLoanCreation2
              lang={lang}
            posStyle={{
              ATIbody: "loancreactionAT2",
              ATIpointer: "loancreationATP",
            }}
            />,
          ]}
          setShow={setShowAppTour}
        />
      )}
    </>
  );
});

export default LoanCreationVani;
