import React from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import ATinfo from "../components/ATinfo";

const ATPanDetails1 = ({ lang, posStyle }) => {
  return (
    <div className="AT_Container">
      <ATinfo
        currentIndex={1}
        totleIndex={2}
        info={"Click only when your current address is same as Aadhar"}
        bodyStyle={posStyle?.ATIbody}
        pointerStyle={posStyle?.ATIpointer}
      />
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          disabled
          sx={{
            "& .MuiFormControlLabel-label.Mui-disabled":{
              color:"black"
            }
          }}
          control={<Checkbox />}
          label={"Same As Aadhaar Address"}
        />
      </Grid>
    </div>
  );
};

export default ATPanDetails1;
