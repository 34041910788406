import React from "react";
import { Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
// import Alert from "./../assets/numberalert.svg";
const NumberAlert = ({ show, setShow, type, connectedNumber }) => {
  return (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {/* <img src={Alert} alt="Number Alert" style={{
            width:"200px"
          }}/> */}

          {!type ? (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <h2>
                Please Enter Aadhaar linked mobile number whose last 3 digits
                are <br /> (
                <span
                  style={{
                    color: "rgba(174, 174, 174, 1)",
                  }}
                >
                  {connectedNumber}
                </span>
                )
              </h2>

              <p>OTP will be sent to the same number</p>
            </div>
          ) : (
            <div
              style={{
                textAlign: "left",
              }}
            >
              <h2>No mobile is linked with borrower’s Aadhaar number</h2>
              <p>
                Please ask borrower to link phone number with Aadhaar for future
                ease
              </p>
            </div>
          )}

          <div
            style={{
              backgroundColor: "rgba(87, 58, 122, 1)",
              padding: "8px 45px",
              borderRadius: "5px",
              color: "white",
              fontSize: "20px",
              curser: "pointer",
            }}
            onClick={() => {
              setShow(false);
            }}
          >
            Ok
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NumberAlert;
