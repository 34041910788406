import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import translateIco from "../assets/translateIco.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { GlobalContext } from "../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { updateLanguage } from "../api/agent";
import { useSnackbar } from "notistack";
import { Button, CircularProgress } from "@mui/material";
import { l } from "../util/languageConvertor";
import MixPanelAudit from "../util/MixPanelAudit";
import { UserContext } from "../Contexts/UserContext";

export default function ChangeLanguageMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const open = Boolean(anchorEl);

  const { langState } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const [lang, setLang] = langState;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLang = async (selected) => {
    setLoading(selected);
    try {
      setLang(selected);
      let { data } = await updateLanguage({ language: selected });
      if (data?.status) {
        enqueueSnackbar(l("Language Updated Successfully", selected), {
          variant: "success",
        });
        MixPanelAudit.intercept("Language Change", {
          language: selected,
          distinct_id: user?.mobile_number[0],
          agent_name: user?.name,
        });
        navigate("/home");
      }
    } catch (error) {
      navigate(
        error?.response?.data?.message ||
          "Something went wrong. You can change language Later"
      );
      navigate("/home");
    }
    setLoading(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleClick}
          color="inherit"
          className="change-lang-btn-mob"
        >
          <img src={translateIco} width="30px" />
        </IconButton>
        <Button
          variant="outlined"
          className="change-lang-btn"
          onClick={handleClick}
        >
          <img src={translateIco} width="30px" />
          {l("Change language", lang)}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            borderRadius: "15px",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => handleChangeLang("english")}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "170px",
              alignItems: "center",
              color: "#573A7A",
            }}
          >
            English{" "}
            <span
              style={{
                color: lang === "english" ? "#573A7A" : "#AEAEAE",
              }}
            >
              {loading === "english" ? (
                <CircularProgress
                  color="secondary"
                  size={25}
                  sx={{ mr: 0.5 }}
                />
              ) : (
                <CheckCircleIcon sx={{ fontSize: 30 }} />
              )}
            </span>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleChangeLang("hindi")}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "170px",
              alignItems: "center",
              color: "#573A7A",
            }}
          >
            हिंदी{" "}
            <span
              style={{
                color: lang === "hindi" ? "#573A7A" : "#AEAEAE",
              }}
            >
              {loading === "hindi" ? (
                <CircularProgress
                  color="secondary"
                  size={25}
                  sx={{ mr: 0.5 }}
                />
              ) : (
                <CheckCircleIcon sx={{ fontSize: 30 }} />
              )}
            </span>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleChangeLang("hinglish")}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "170px",
              alignItems: "center",
              color: "#573A7A",
            }}
          >
            Hinglish{" "}
            <span
              style={{
                color: lang === "hinglish" ? "#573A7A" : "#AEAEAE",
              }}
            >
              {loading === "hinglish" ? (
                <CircularProgress
                  color="secondary"
                  size={25}
                  sx={{ mr: 0.5 }}
                />
              ) : (
                <CheckCircleIcon sx={{ fontSize: 30 }} />
              )}
            </span>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
}
