import { Button, Card, CardActions, CardContent, Grid } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import draft_icon from '../../assets/draft_app_icon.svg';
import all_app_icon from '../../assets/all_app_icon.svg';
import post_disburse_icon from '../../assets/post_disburse_icon.svg';
import amount_credit_icon from '../../assets/amount_credit_icon.svg';
import collections_icon from '../../assets/collections_icon.svg';
import inventory_icon from '../../assets/inventory_icon.svg';
import gps_icon from '../../assets/gps_icon.svg';
import { l } from "../../util/languageConvertor";
import { GlobalContext } from "../../Contexts/GlobalContext";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';


const MenuScreen = () => {
  const navigate = useNavigate();

  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const buttonsData = [
    // { label: "Draft Applications", sub_label: "Continue Pending Applications", icon: draft_icon, navigateTo: "/draft-applications" },
    { label: "All Applications", sub_label: "View All Applications", icon: all_app_icon, navigateTo: "/all-applications" },
    { label: "Post Disbursement", sub_label: "Submit RC and courier documents", icon: post_disburse_icon, navigateTo: "/post-disbursal-documents" },
    { label: "Amount Credited", sub_label: "Amount received from disbursals", icon: amount_credit_icon, navigateTo: "/amount-credited" },
    { label: "Collections", sub_label: "Amount collected from customers", icon: collections_icon, navigateTo: "/collection" },
    // { label: "Inventory", sub_label: "Keep track of your vehicle inventory", icon: inventory_icon, navigateTo: "" },
    { label: "GPS", sub_label: "Vehicle activity tracker", icon: gps_icon, navigateTo: "/gps" },
    { label: "Inventory", sub_label: "Vehicle inventory", icon: inventory_icon, navigateTo: "/inventory" },
  ];

  return (
    <>
      <div className="content-center-new">
        <Grid container spacing={2} className="p-3 response-wrapper">
          <Grid item xs={12} sm={12} className="mt-4">
            {
              buttonsData.map((edx, index) => {
                return (
                  <button
                    className="w-100 home-new-btn mt-3"
                    variant="outlined"
                    key={index}
                    onClick={() => {
                      navigate(edx.navigateTo);
                    }}
                  >
                    <div className="home-btn-icon-container">
                      <img src={edx.icon} />
                    </div>
                    <div className="home-btn-label-container">
                      <p className="home-btn-label m-0">{l(edx.label, lang)}</p>
                      <p className="home-btn-sub-label m-0">{l(edx.sub_label, lang)}</p>
                    </div>
                  </button>
                );
              })
            }
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MenuScreen;
