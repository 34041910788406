import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import "./AppTour.scss";
import mute from "../../../assets/mute.svg";
import unmute from "../../../assets/unmute.svg";
import useSpeak from "../../../hooks/useSpeak";
import { seenAppTours } from "../../../api/agent";

const AppTour = ({
  screenName,
  texts,
  screenContent,
  show,
  setShow,
  index,
  setIndex,
}) => {
  const {
    isSupported,
    speak,
    isSpeaking,
    setIsSpeaking,
    handlePlay,
    handlePause,
  } = useSpeak();

  useEffect(() => {
    const handleonload = (e) => {
      localStorage.setItem("AT_isReload", true);
    };

    window.addEventListener("beforeunload", handleonload);

    return () => {
      window.removeEventListener("beforeunload", handleonload);
    };
  }, []);

  const onEnds = async () => {
    try {
      await seenAppTours(screenName);
    } catch (err) {
      console.log("AppTour :-", err);
    }
  };

  useEffect(() => {
    const synthesis = window.speechSynthesis;
    console.log("isSpeaking :-", isSpeaking);
    speak(texts[index], () => {
      if (index > screenContent.length - 1) {
        setShow(false);
        onEnds();
      }
      setIsSpeaking(false);
    });

    return () => {
      synthesis.cancel();
    };
  }, [index, isSpeaking]);

  useEffect(() => {
    if (index > screenContent.length - 1) {
      setShow(false);
      setIsSpeaking(false);
      onEnds();
    }
  }, [index]);

  return (
    <Dialog
      sx={{
        "& .css-ypiqx9-MuiDialogContent-root": {
          padding: "0px",

        },
      }}
      open={show}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: 0,
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      }}
      onLoad={() => {
        const storedValue = localStorage.getItem("AT_isReload");
        if (storedValue === "true") {
          setIsSpeaking(false);
          localStorage.removeItem("AT_isReload");
        }
      }}
      fullScreen={true}
    >
      <DialogContent>
        <div
          className="AT_body"
          style={{
            marginBottom: 0,
          }}
        >
          <div
            className="AT_section"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <div></div>

            {screenContent[index]}

            <div className="AT_controler">
              <img
                src={isSpeaking ? mute : unmute}
                onClick={() => {
                  console.log("isSpeaking", isSpeaking);
                  if (isSpeaking) {
                    handlePause();
                  } else {
                    handlePlay();
                  }
                }}
              />
              <div
                className="text"
                onClick={() => {
                  setIndex((prev) => prev + 1);
                  setIsSpeaking(true);
                }}
              >
                {" "}
                Next &gt;
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AppTour;
