import React from "react";
import reward from "../assets/reward.svg";
import fingerIco from "../assets/finger.svg";

export default function RewardFloating({ isOpen, onClick, action, showInfo }) {
  if (!isOpen) return null;

  return (
    <>
      {showInfo && (
        <>
          <div
            style={{
              width: "100%",
              position: "fixed",
              top: 0,
              right: 0,
              height: "100vh",
              background: "rgba(0,0,0,0.5)",
              zIndex: "5000",
            }}
            onClick={action}
          ></div>

          <div
            className="info-text"
            style={{
              position: "fixed",
              right: "60px",
              top: "18em",
              transition: "all .2s",
              cursor: "default",
              zIndex: "6000",
            }}
          >
            <p style={{ color: "#FCEA2B" }}>
              Complete User Profiling to earn 50 e-Coins
            </p>
            <p className="text-center info-hand">
              <img src={fingerIco} />
            </p>
          </div>
        </>
      )}
      <div
        style={{
          position: "fixed",
          right: "0",
          top: "15em",
          opacity: !isOpen ? "0" : "1",
          transition: "all .2s",
          visibility: !isOpen ? "hidden" : "visible",
          cursor: "pointer",
          zIndex: "6000",
        }}
        onClick={onClick}
      >
        <img src={reward} />
      </div>
    </>
  );
}
