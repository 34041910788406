import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import { Button, CssBaseline, Dialog, Grid } from "@mui/material";
import {
  Chip,
  CircularProgress,
  MenuItem,
  Skeleton,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { GlobalContext } from "../../Contexts/GlobalContext";
import SearchIcon from "@mui/icons-material/Search";
import emptyIco from "../../assets/empty.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getDraftApplication,
  getAllApplicationWithFilter,
  fetchQr,
  physicalMandate,
  uploadDocument,
} from "../../api/agent";
import useApplication from "../../hooks/useApplication";
import FileUploadComp from "../component/FileUpload";
import file_upload from "../../assets/file_upload_icon.svg";
import file_upload_2 from "../../assets/file_upload_icon_2.svg";
import file_upload_3 from "../../assets/file_upload_icon_3.svg";
import { getBase64 } from "../../util/convertFileBase";
import { l } from "../../util/languageConvertor";
import ReplayIcon from "@mui/icons-material/Replay";
import { useSnackbar } from "notistack";
import { LoanContext } from "../../Contexts/LoanJourneyContext";
import { baseURL, environment } from "../../config";
import { DocStatusMap } from "../constants/Generic";


const qrDataInitial = {
  open: false,
  qr_code: "",
};

export default function PostDisbursalDocuments() {
  const location = useLocation();
  const ref = useRef(null);
  const selectRef = useRef(null);

  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [time, setTime] = useState("");
  const [timeFilterValue, setTimeFilterValue] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [letSearch, setLetSearch] = useState("");
  const [isSearching, setSearching] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");
  const [files, setFiles] = useState({ file_1: "", file_2: "", file_3: "" });
  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const [show, setShow] = useState(false);
  const [info, setInfo] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isUploaded, setIsUploaded] = useState(false);
  const [status, setStatus] = useState({
    enach: "pending",
    upi: "pending",
  });
  const [isDownloaded, setDownloaded] = useState(false);
  const [qrData, setQrData] = useState(qrDataInitial);
  const [isQrLoading, setQrLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fileCompStatus, setFileCompStatus] = useState({ fileComp_1: '', fileComp_2: '', fileComp_3: '' });

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const fileInput = useRef();
  const [application_id, setApplication_id] = useState('');


  const navigate = useNavigate();

  const filter_options = [
    { count: 100, label: "All", value: "all", icon: "" },
    { count: 10, label: "NACH", value: "nach", icon: file_upload },
    { count: 25, label: "RC", value: "rc", icon: file_upload_2 },
    { count: 25, label: "COURIER", value: "courier", icon: file_upload_3 },
  ];

  const { showPermissionState, applicationState } = useContext(GlobalContext);

  const handleDownload = async (key, fileName) => {
    // console.log('data- ', key, fileName)
    await fetch(`${baseURL[environment]}/common/view?key=${key}`, {
      method: "get",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        application: "AGENT-ERICK-V2",
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement("a");
        aElement.setAttribute("download", fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        // aElement.setAttribute('href', href);
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      });
  };

  const { getApplicationDetails, getStage, getRealStatus } = useApplication();

  // useEffect(() => {
  //   getApplicationDetails(application_id);
  // }, []);

  //   useEffect(() => {
  //   setStatus({
  //     ...status,
  //     enach: getRealStatus(journeyData?.enach_status, "enach"),
  //     upi: getRealStatus(journeyData?.upi_status, "upi"),
  //   });
  // }, [journeyData?.enach_status, journeyData?.upi_status]);

  const getStatusReal = () => {
    setStatus({
      ...status,
      enach: getRealStatus(journeyData?.enach_status, "enach"),
      upi: getRealStatus(journeyData?.upi_status, "upi"),
    });

    // console.log('---get real status ', getRealStatus(journeyData?.enach_status, "enach"))
  }

  useEffect(() => {
    // console.log(location?.state?.filter);
    if (location?.state?.filter) {
      setActiveFilter(location?.state?.filter);
    }
  }, [location]);

  useEffect(() => {
    window.onpopstate = () => {
      if (location?.state?.origin) {
        navigate(location?.state?.origin);
      } else {
        navigate("/home/menu");
      }
    };
  });


  const fetchAllApplicationWithFilter = async () => {
    let { data } = await getAllApplicationWithFilter("disbursed");
    console.log("post disbursement docs api res: ", data);
    setApplications(data?.data);
  };

  const CardComp = ({ heading, subtext, Buttons }) => {
    return (
      <div className="payment-card">
        <div className="upload-card-v3" style={{ height: "160px" }}>
          <p className="heading mb-0">{heading}</p>
          <p className="desc-text" style={{ color: "#6A6A6A" }}>
            {subtext}
          </p>
          <div className="button-holder">{Buttons}</div>
        </div>
      </div>
    );
  };

  const uploadNach = async (file) => {
    // setLoading(name);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name: "nach_form",
              file: await getBase64(file),
            },
          ],
        },
        application_id,
        `?override=true`
      );
      getApplicationDetails(application_id, true);
    } catch (error) { }
    // setLoading("");
  };

  const handleQr = async () => {
    setQrLoading(true);
    try {
      let { data } = await fetchQr({
        application_id,
      });
      setQrData({
        open: true,
        qr_image:
          data?.insta_collect?.virtual_account
            ?.upi_qrcode_remote_file_location ||
          data?.data?.virtual_account?.upi_qrcode_remote_file_location,
      });
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "error",
      });
    }
    setQrLoading(false);
  };

  const StatusChip = ({ text, color }) => {
    return (
      <span
        style={{
          background: color,
          color: "white",
          padding: "5px 20px",
          borderRadius: 20,
        }}
      >
        {text}
      </span>
    );
  };

  const handlePhysicalMandate = async () => {
    setLoading(true);
    // console.log(`physical_nach/${journeyData?.application_id}`);
    try {
      // console.log(journeyData?.physical_nach_status);
      if (journeyData?.physical_nach_status) {
        // console.log(`physical_nach/${journeyData?.application_id}`);
        await handleDownload(
          `nach_form_unsigned/${journeyData?.application_id}`,
          `nach_form_${journeyData?.application_id}.pdf`
        );
        setDownloaded(true);
      } else {
        // console.log("Hereee");
        let { data } = await physicalMandate(journeyData?.application_id);
        await handleDownload(
          `nach_form_unsigned/${journeyData?.application_id}`,
          `nach_form_${journeyData?.application_id}.pdf`
        );
        setJourneyData({ ...journeyData, physical_nach_status: true });
        setDownloaded(true);
      }
      // MixPanelAudit.intercept("Physical Mandate Downloaded", {
      //   application_id,
      // });
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllApplicationWithFilter();
  }, []);

  // useEffect(() => {
  //   console.log("time filter : ", time);
  // }, [time]);

  const handleTimeFilter = (e) => {
    const { value } = e.target;
    setTime(value);

    if (value === "today") {
      let currentDate = moment().format("DD/MM/YYYY");
      setTimeFilterValue(currentDate);
    } else if (value === "current_week") {
      let startDate = moment().startOf("week").format("DD/MM/YYYY");
      let endDate = moment().endOf("week").format("DD/MM/YYYY");
      setTimeFilterValue([startDate, endDate]);
    } else if (value === "current_month") {
      let startDate = moment().startOf("month").format("DD/MM/YYYY");
      let endDate = moment().endOf("month").format("DD/MM/YYYY");
      console.log([startDate, endDate]);
      setTimeFilterValue([startDate, endDate]);
    }
  };

  useEffect(() => {
    getStatusReal();
    // console.log(journeyData);
  }, [journeyData])

  return (
    <div style={{ backgroundColor: "#F9F8FF" }} className="content-center">

      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="modal-body">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <CardComp
                  heading={l("E-Nach", lang)}
                  subtext={l("Debit card/ Net Banking", lang)}
                  Buttons={
                    <>
                      {["pending", "unsuccessfull"].includes(status?.enach) && (
                        <Button
                          className="btn"
                          onClick={() => {
                            navigate(
                              `/create-loan/payment-method/enach/${journeyData?.application_id}`
                            );
                          }}
                        >
                          {status?.enach === "unsuccessfull"
                            ? l("Try Again", lang)
                            : l("Register", lang)}
                        </Button>
                      )}
                      {["successfull"].includes(status?.enach) && (
                        <StatusChip text={l("Success", lang)} color="#4caf50" />
                      )}
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <CardComp
                  heading={l("Physical Nach", lang)}
                  subtext={l("Debit card/ Net Banking", lang)}
                  Buttons={
                    isUploaded ? (
                      <>
                        <ReplayIcon
                          className="me-2 pointer"
                          onClick={() => {
                            setIsUploaded(false);
                            setDownloaded(true);
                          }}
                        />
                        <StatusChip text={l("Uploaded", lang)} color="#4caf50" />
                      </>
                    ) : (
                      <>
                        <Button
                          className="btn"
                          disabled={isLoading}
                          onClick={() => {
                            handlePhysicalMandate();
                          }}
                        >
                          {!isLoading
                            ? isDownloaded
                              ? l("Download Again", lang)
                              : l("Download", lang)
                            : l("Loading", lang)}
                        </Button>
                        {isDownloaded && (
                          <>
                            <input
                              ref={fileInput}
                              type="file"
                              class="custom-file-input"
                              accept="image/*"
                              onChange={async (e) => {
                                if (
                                  !e.target.files[0]?.type.match(/image.*/)
                                ) {
                                  enqueueSnackbar("Supports Image files only", { variant: "error" });
                                  return false;
                                }
                                uploadNach(e.target.files[0]);
                              }}
                              hidden
                            />
                            <Button
                              className="btn ms-3"
                              onClick={() => {
                                fileInput.current.click();
                              }}
                            >
                              {l("Upload", lang)}
                            </Button>
                          </>
                        )}
                      </>
                    )
                  }
                />
              </Grid>
            </Grid>
          </div>
        </Modal.Body>
      </Modal>

      <Box sx={{ pb: 7 }} ref={ref} className="response-wrapper">
        <Grid
          style={{
            boxShadow: "none",
            border: "none",
            position: "sticky",
            top: 0,
            backgroundColor: "#F9F8FF",
            zIndex: 100,
          }}
          container
          spacing={2}
          className="p-3 pt-0"
        >
          <Grid item xs={12} sm={12}>
            <div className="page-head">
              <ArrowBackIcon
                sx={{ color: "#573A7A" }}
                className="pointer"
                onClick={() => {
                  // navigate(location?.state?.origin);
                  if (location?.state?.origin) {
                    navigate(location?.state?.origin);
                  } else {
                    navigate("/home/menu");
                  }
                }}
              />{" "}
              {l("Post Disbursal Documents", lang)}
              <Grid
                sx={{ display: "flex", justifyContent: "space-around" }}
                item
                xs={12}
                sm={12}
              >
                <Grid item xs={12} sm={12} className="search-spacing">
                  <div style={{ position: "relative" }}>
                    <SearchIcon
                      className="search-ico"
                      style={{ color: "gray" }}
                      onClick={() => { }}
                    />

                    <input
                      type={"text"}
                      placeholder="Search Application"
                      className="form-input input-blue input-blue-search w-100"
                      value={searchTxt}
                      onChange={(e) => {
                        setSearchTxt(e.target.value);
                      }}
                      style={{
                        fontSize: "14px",
                      }}
                    />
                  </div>
                </Grid>
                {/* <Grid item xs={3} sm={3}>
                  <FormControl size="small" item xs={4} fullWidth>
                    <InputLabel sx={{
                      color: "#573A7A",
                    }} id="demo-simple-select-label">Time</InputLabel>
                    <Select size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      sx={{
                        borderRadius: "10px"
                      }}
                      value={time}
                      label="Time"
                      onChange={handleTimeFilter}
                    >
                      {
                        TimeOptions.map((edx, index) => <MenuItem value={edx?.toLowerCase()?.replaceAll(' ', '_')} key={index}>{edx}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </div>
          </Grid>

          <Container
            className="load-collapse-row mb-0"
            style={{
              boxShadow: "none",
              border: "none",
            }}
          >
            {/* <Grid sx={{
              display: "flex",
              justifyContent: "space-between",
            }} item xs={12} sm={12}>
              {
                filter_options?.map((edx, index) => {
                  return (
                    <Grid key={index} item xs={3} className="filter-box" style={{ backgroundColor: activeFilter === edx.value ? '#573A7A' : '#fff' }} onClick={() => setActiveFilter(edx.value)}>
                      <img src={edx.icon} style={{ width: '2em' }} />
                      <div className="" style={{ paddingLeft: '0.7em', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <p className="mb-0 p-0 fc-count" style={{ color: activeFilter === edx.value ? '#fff' : '#000' }}>{edx.count}</p>
                        <p className="mb-0 p-0 fc-count" style={{ color: activeFilter === edx.value ? '#fff' : '#000' }}>{edx.label}</p>
                      </div>
                    </Grid>
                  )
                })
              }
            </Grid> */}
          </Container>
        </Grid>

        <Grid item xs={12} sm={12} className="mt-5">
          {isSearching && (
            <p className="w-100 text-center mt-2 mb-2">
              <CircularProgress size={25} />
            </p>
          )}

          {!isLoading ? (
            <>
              <Container>
                <div className="card-container">
                  <div
                    style={{ width: "63%", overflow: "hidden" }}
                    className="card-left"
                  >
                  </div>
                  <div
                    style={{ width: "37%", padding: "0.5em 0" }}
                    className="card-right"
                  >
                    <div className="d-flex">
                      <div className="d-flex justify-content-between w-100">
                        <span style={{ color: '#573a7a', fontSize: '0.9em' }}>Nach</span>
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <span style={{ color: '#573a7a', fontSize: '0.9em' }}>&nbsp;&nbsp;&nbsp;RC</span>
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <span style={{ color: '#573a7a', fontSize: '0.9em' }}>&nbsp;&nbsp;Courier</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>

              {applications?.length !== 0 &&
                applications
                  ?.filter((edx) => {
                    let search = edx?.customerData?.is_corporate ? edx?.customerData?.corporate_pan_details?.name : edx?.customerData?.pan_details?.name
                    if (search?.toLowerCase()?.includes(searchTxt?.toLowerCase())) {
                      return edx
                    }
                  })
                  ?.map((val, index) => {
                    return (
                      <Container
                        // className="load-collapse-row collapse-blue pointer"
                        className="load-collapse-row collapse-blue-new pointer"
                        key={index}
                      >
                        <div className="card-container">
                          <div
                            style={{ width: "63%", overflow: "hidden" }}
                            className="card-left"
                            onClick={() => {
                              navigate("/borrower-profile", {
                                state: {
                                  customer: val,
                                  origin: "/post-disbursal-documents",
                                },
                              });
                            }}
                          >
                            <p className="app-p text-left">
                              {val?.customerData?.pan_details?.name}
                              <br />
                              <span className="app-date">
                                Disbursal Date:{" "}
                                {val?.disbursed_date
                                  ? moment(val?.createdAt).format("DD/MM/YYYY")
                                  : "---"}
                              </span>
                            </p>
                          </div>
                          <div
                            style={{ width: "37%", padding: "0.5em 0" }}
                            className="card-right"
                          >
                            <div className="doc-upload-icons-container">
                              <div className="d-flex justify-content-between w-100">
                                <FileUploadComp
                                  seperate={true}
                                  icon={file_upload}
                                  badge={DocStatusMap[val?.physical_nach_data?.bank_details?.state]}
                                  onClick={() => { setShow(true); setApplication_id(val?.application_id); getApplicationDetails(val?.application_id, true, false, true) }}
                                />
                                <FileUploadComp
                                seperate={false}
                                  icon={file_upload_2}
                                  badge={DocStatusMap[val?.fileComp_2]}
                                  onChange={() => {
                                    let apps = [...applications];
                                    apps[index].fileComp_2='uploaded';
                                    setApplications(apps)
                                  }}
                                />
                                <FileUploadComp
                                seperate={false}
                                  icon={file_upload_3}
                                  badge={DocStatusMap[val?.fileComp_3]}
                                  onChange={() => {
                                    let apps = [...applications];
                                    apps[index].fileComp_3='uploaded';
                                    setApplications(apps)
                                  }}
                                />
                              </div>
                              {/* <p className="text-center mb-0 doc-status">
                                <span className="text-danger">Pending:</span>{" "}
                                RC; Courier
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </Container>
                    );
                  })}
              {applications.length === 0 && (
                <p className="text-center mt-5">
                  <img src={emptyIco} />
                  <p style={{ color: "gray" }}>{l("No Applications here", lang)}</p>
                </p>
              )}{" "}
            </>
          ) : (
            <>
              {["", "", ""].map((val) => {
                return (
                  <>
                    <Container className="load-collapse-row collapse-blue pointer">
                      <Row className="mt-2">
                        <Col className="font-blue">
                          <Skeleton width={"100%"} />
                        </Col>
                        <Col className="font-blue">
                          <Skeleton width={"100%"} />
                        </Col>
                        <Col className="font-blue">
                          <Skeleton width={"100%"} />
                        </Col>
                      </Row>
                    </Container>
                  </>
                );
              })}
            </>
          )}
        </Grid>
        {/* </div> */}
      </Box>
    </div>
  );
}
